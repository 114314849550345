export default {
  authority: 'https://identity.elastech.net',
  client_id: 'js',
  redirect_uri: 'http://localhost:5003/callback.html',
  response_type: 'id_token token',
  scope: 'openid profile api1',
  post_logout_redirect_uri: 'http://localhost:5003/login',
  apiUrl: 'https://router.elas.tech/api/',
  url: 'https://database.elas.tech/api/',
  awsUrl: 'https://pw003fm5nf.execute-api.eu-central-1.amazonaws.com/dev/',
  routerS3Url: 'https://s3.eu-central-1.amazonaws.com/bdnc-router-images/',
};

export const permissions = {
  SERVERS: {
    access: true,
    name: 'servers',
    displayName: 'Servers',
    code: 'svr',
  },
  MULTIVIEW: {
    access: true,
    name: 'multiview',
    displayName: 'Multiview',
    code: 'mvr',
  },
  MULTIVIEWER: {
    access: true,
    name: 'multiviewer',
    displayName: 'Multiviewer',
    code: 'mv2',
  },
  PROBES: {
    access: true,
    name: 'probes',
    displayName: 'Probes',
    link: 'https://probes.elas.tech',
    code: 'prb',
  },
  SCHEDULEDEFAULT: {
    access: true,
    name: 'schedule',
    displayName: 'Schedule',
    code: 'sch',
  },
  ADMIN: {
    access: true,
    name: 'admin',
    displayName: 'Client panel',
    code: 'adm',
  },
  ROUTER: {
    access: true,
    name: 'router',
    displayName: 'Router',
    code: 'rtr',
  },
  AUDIT: {
    access: true,
    name: 'audit',
    displayName: 'Audit',
    code: 'sys',
  },
  PERMISSIONSSIMPLE: {
    access: true,
    name: 'permissionssimple',
    displayName: 'Sys Admin Panel',
    code: 'sys',
  },
  REPORTS: {
    access: true,
    name: 'reports',
    displayName: 'Reports',
    code: 'sys',
  },
  LIVEOPS: {
    access: true,
    name: 'liveops',
    displayName: 'Live Ops',
    code: 'sys',
  },
  MGW: {
    access: true,
    name: 'mgw',
    displayName: 'MGW',
    code: 'sys',
  },
};

export const statusColors = {
  done: '#343c49',
  failed: 'red',
  status3: 'green',
};

export const scheduleConfig = {
  disableKeyEdit: false,
};

// monitoring config
export const monitoringConfig = {
  heartBeatOffline: 180,
  heartBeatOnline: 60,
};

export const scheduleEventPropertiesNames = {
  fixture: 'Fixture',
  description: 'Description',
  sport: 'Sport',
  property: 'Property',
  lineup: 'Lineup',
  start: 'Kickoff',
  end: 'End',
  input: '',
};
