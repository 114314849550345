<template>
  <transition name="list">
    <div class="bly-popover" id='dropdown'
      v-if="show"
      :style="styles"
      v-click-outside="closeEvent"
      ref="dropdown">
      <div class="menu">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ContextMenu',
  props: {
    type: String,
    show: Boolean,
    event: MouseEvent,
    width: {
      type: Number,
      default: 200,
    },
  },

  computed: {
    styles() {
      const browserHeight = document.body.clientHeight;
      const browserWidth = document.body.clientWidth;

      const rightSide = this.event.clientX + this.width;
      const bottomSide = this.event.clientY + this.width;
      const openRight = rightSide > browserWidth;
      const openTop = bottomSide > browserHeight;
      // const openUnderButton = this.event.target.className === 'bi bly-menu';

      let x = this.event.clientX;
      let y = this.event.clientY;
      let originX = 0;
      let originY = 0;

      if (openRight) {
        x = this.event.clientX - this.width;
        originX = 100;
      }

      if (openTop) {
        y = this.event.clientY - this.width;
        originY = 100;
      }

      this.isElementInViewport(document.getElementById('dropdown'));
      // x = rightSide > browserWidth ? this.event.clientX - this.width : this.event.clientX;
      // y = this.event.clientY;

      return {
        top: this.show && this.event ? `${y}px` : '-1400px',
        left: this.show && this.event ? `${x}px` : '-1400px',
        width: `${this.width}px`,
        transformOrigin: `${originX}% ${originY}%`,
      };
    },
  },

  data() {
    return {};
  },

  created() {
    document.addEventListener('contextmenu', this.getMousePosition);
  },

  methods: {
    closeEvent() {
      this.$emit('update:show', false);
    },

    getMousePosition() {
      // this.event = event;
    },

    isElementInViewport() {
      this.$nextTick(() => {
        const dropdownProperties = document.getElementById('dropdown').getBoundingClientRect();

        const browserHeight = document.body.clientHeight;
        const browserWidth = document.body.clientWidth;

        const rightSide = this.event.clientX + this.width;
        const openRight = rightSide > browserWidth;
        const openUnderButton = this.event.target.className === 'bi bly-menu';
      });
    },
  },

  directives: {
    'click-outside': {
      bind: (el, props) => {
        // eslint-disable-next-line
        el.clickCatcher = (e) => {
          if (props.modifiers.hidden) {
            // eslint-disable-next-line
            props.modifiers.hidden = false;
            return;
          }
          if (!el.contains(e.target) && typeof props.value === 'function') {
            props.value(props.arg);
          }
        };
        window.addEventListener('click', el.clickCatcher);
      },
      unbind: (el) => window.removeEventListener('click', el.clickCatcher),
    },
  },
};
</script>

<style scoped lang="scss" src="./ContextMenu.scss"></style>
