<template>
  <el-col :sm="span.sm" :md="span.md" :lg="span.lg">
    <div class="data-item">
      <div class="data-item-label">
        {{label}}
      </div>
      <div>
        <slot></slot>
      </div>
    </div>
  </el-col>
</template>

<script>
export default {
  name: 'PermissionFormItem',
  props: {
    label: String,
    span: {
      type: Object,
      default: () => ({ sm: 12, md: 12, lg: 8 }),
    },
  },

  computed: {
    classes() {
      return `${this.type} ${this.size}`;
    },
  },

  data() {
    return {
    };
  },
};
</script>

<style scoped lang="scss" src="./PermissionFormItem.scss"></style>
