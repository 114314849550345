import generate from 'string-to-color';
import ColorHash from 'color-hash';

class ColorGenerator {
  constructor() {
    this.generate = generate;
    this.colorHash = new ColorHash({
      hue: [
        {
          min: 100,
          max: 190,
        },
        {
          min: 180,
          max: 210,
        },
        {
          min: 270,
          max: 285,
        },
      ],
      lightness: [0.55, 0.5, 0.75],
      saturation: [0.85, 0.65, 0.85],
    });
  }

  stringToColor(name) {
    return this.generate(name);
  }

  static toHex(intVal) {
    let hex = intVal.toString(16);
    if (hex.length === 1) {
      hex = `0${hex}`;
    }
    return hex;
  }

  static hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    } : null;
  }

  static hexFromInts(r, g, b) {
    return `#${this.toHex(r)}${this.toHex(g)}${this.toHex(b)}`;
  }

  generateNewColor(name) {
    return this.colorHash.hex(name);
  }

  static niceColor() {
    return '#fff';
  }
}

export default new ColorGenerator();
