<template>
  <div id="auth" class="auth-layout">
    <div class="col-1"></div>
    <div class="col-2 form">
      <el-form
        v-if="visibleStep === 1"
        :model="form"
        :rules="rules"
        ref="form"
        label-position="top">

        <h1 class="title">Login</h1>
        <p class="title-description">Enter your email and password to sign in</p>
        <el-form-item v-if="error">
          <el-alert :title="error" v-if="error" type="error" :closable="false"></el-alert>
        </el-form-item>

        <el-form-item prop="username" :label="'Email'">
          <el-input
            :autofocus="true"
            placeholder="john.doe@mail.com"
            v-model="form.username"
            @keyup.enter.native="login('form')">
          </el-input>
        </el-form-item>
        <el-form-item :label="'Password'"
          prop="password">
          <el-input placeholder="Password"
            type="password"

          show-password
            v-model="form.password"
            @keyup.enter.native="login('form')">
          </el-input>
        </el-form-item>
        <el-form-item>
          <router-link to="/password-reset">
            Forgot password
          </router-link>
          <router-link to="/register" v-if="false">
            Create account
          </router-link>
        </el-form-item>
        <el-button
          type="primary"
          @click="login('form')"
          :loading="isBtnLoading">Login
        </el-button>
      </el-form>
      <el-form
        v-if="visibleStep === 2"
        :model="mfaForm"
        :rules="mfaForm"
        ref="mfaForm"
        label-position="top">

        <h1 class="title">Confirm sign in</h1>
        <p class="title-description">Enter code from application</p>
        <el-form-item v-if="error">
          <el-alert :title="error" v-if="error" type="error" :closable="false"></el-alert>
        </el-form-item>

        <el-form-item prop="code" :label="'Enter verification code.'">
          <el-input
            :autofocus="true"
            placeholder="Code"
            v-model="mfaForm.code"
            @keyup.enter.native="verify('mfaForm')">
          </el-input>
        </el-form-item>
        <el-button
          type="primary"
          @click="verify('mfaForm')"
          :loading="isBtnLoading">{{btnText('Confirm')}}
        </el-button>
        <el-button
          type="default"
          @click="error = null; visibleStep = 1"
          >Cancel
        </el-button>
      </el-form>

      <el-form
        :model="newPasswordForm"
        :rules="newPasswordRules"
        @submit.native.prevent
        ref="newPasswordForm"
        label-width="120px"
        label-position="top"
        v-if="visibleStep === 3">

        <h1 class="title">Password change required</h1>
        <p class="title-description">New password is required</p>
        <el-form-item v-if="error">
          <el-alert :title="error" v-if="error" type="error" :closable="false"></el-alert>
        </el-form-item>

        <el-form-item prop="newPassword" label="Enter new password">
          <el-input
            placeholder="New Password"
            type="password"
            v-model="newPasswordForm.newPassword"
            @keyup.enter.native="completeNewPassword('newPasswordForm')">
          </el-input>
        </el-form-item>

        <el-button
          type="primary"
          @click="completeNewPassword('newPasswordForm')"
          :loading="isBtnLoading">{{btnText('Submit')}}
        </el-button>
      </el-form>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      visibleStep: 1,
      showConfirmSignin: false,
      signInConfig: {
        header: 'Welcome',
      },
      confirmConfig: {
        header: 'Confirm Sign In',
        user: {},
      },
      form: {
        username: '',
        password: '',
      },
      mfaForm: {
        code: '',
      },
      newPasswordForm: {
        newPassword: '',
        confirmPassword: '',
      },
      isBtnLoading: false,
      error: null,
      mfaRules: {
        code: [
          {
            required: true,
            message: 'Please enter code',
            trigger: 'blur',
          },
        ],
      },
      rules: {
        username: [
          {
            required: true,
            message: 'Please enter correct email address',
            trigger: 'blur',
            type: 'email',
          },
        ],
        password: [
          { required: true, message: 'Please input password', trigger: 'blur' },
          { min: 8, message: 'Password is too short', trigger: 'blur' },
        ],
      },
      newPasswordRules: {
        newPassword: [
          { required: true, message: 'Please enter new passwrod', trigger: 'blur' },
          { min: 6, message: 'Length should be atleast 6', trigger: 'blur' },
        ],
      },
      user: null,
      mfaType: 'SOFTWARE_TOKEN_MFA',
    };
  },

  computed: {
  },

  created() {
  },

  methods: {
    btnText(text) {
      if (this.isBtnLoading) return 'Loading...';
      return text;
    },

    async verify(formName) {
      const form = this.$refs[formName];
      const isFormValid = await form.validate();
      if (!isFormValid) {
        this.error = 'Form is invalid.';
        return;
      }
      try {
        this.isBtnLoading = true;
        await this.$Amplify.Auth.confirmSignIn(
          this.user,
          this.mfaForm.code,
          this.mfaType,
        );

        this.$router.push({ path: '/home' });
        this.isBtnLoading = false;
      } catch (error) {
        this.error = error;
        const isNotConfirmed = error.message === 'User is not confirmed.';
        if (isNotConfirmed) this.error = 'Confirm your email address. We have sent an email with a confirmation link to your email address. In order to complete the sign-up process, please click the confirmation link.';
        this.isBtnLoading = false;
      }
    },

    async completeNewPassword(formName) {
      this.error = null;
      const form = this.$refs[formName];
      const isFormValid = await form.validate();
      if (!isFormValid) {
        this.error = 'Form is invalid.';
        return;
      }

      try {
        this.isBtnLoading = true;
        const result = await this.$Amplify.Auth.completeNewPassword(
          this.user,
          this.newPasswordForm.newPassword,
        );
        this.$router.push({ path: '/home' });
        this.isBtnLoading = false;
      } catch (error) {
        this.error = error;

        const hasCode = !!error.code;
        if (hasCode) this.error = error.message;
        const isNotConfirmed = error === 'User is not confirmed.';
        if (isNotConfirmed) this.error = 'Confirm your email address. We have sent an email with a confirmation link to your email address. In order to complete the sign-up process, please click the confirmation link.';
        this.isBtnLoading = false;
      }
    },

    async login(formName) {
      const form = this.$refs[formName];
      const isFormValid = await form.validate();
      this.form.username = this.form.username.toLowerCase();
      if (!isFormValid) {
        this.error = 'Form is invalid.';
        return;
      }

      try {
        this.isBtnLoading = true;
        const result = await this.$Amplify.Auth.signIn(this.form.username, this.form.password);
        console.log('CHALLENGE NAME: ', result);
        const needVerify = result.challengeName === 'SOFTWARE_TOKEN_MFA'
        || result.challengeName === 'SMS_MFA';

        const needNewPassword = result.challengeName === 'NEW_PASSWORD_REQUIRED';

        console.log('CHALLENGE NAME: ', result.challengeName, needNewPassword);

        if (needVerify) {
          this.mfaType = result.challengeName;
          this.user = result;
          this.isBtnLoading = false;
          this.visibleStep = 2;
          return;
        }

        if (needNewPassword) {
          this.mfaType = result.challengeName;
          this.user = result;
          this.isBtnLoading = false;
          this.visibleStep = 3;
          return;
        }

        this.$router.push({ path: '/home' });
        this.isBtnLoading = false;
      } catch (error) {
        this.error = error.message;
        console.log('ERROR', error);
        const isNotConfirmed = error.message === 'User is not confirmed.';
        if (isNotConfirmed) {
          this.visibleStep = 2;
          this.error = 'Confirm your email address. We have sent an email with a confirmation link to your email address. In order to complete the sign-up process, please click the confirmation link.';
        }
        this.isBtnLoading = false;
      }
    },

    validatePass(rule, value, callback) {
      if (value === '') callback(new Error('Please enter the password again'));
      if (value !== this.newPasswordForm.newPassword) callback(new Error('Passwords don\'t match!'));

      callback();
    },
  },
};
</script>
