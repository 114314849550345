import { API, graphqlOperation } from 'aws-amplify';
import Vue from 'vue';
import * as qlqueries from '../graphql/queries';

const state = {
  name: 'UserAccount',
  cognito: {},
  userSession: {},
  userGroups: {},
  isAdmin: false,
  isSysAdmin: false,
  isSystem: false,
  isDevAdmin: false,
  isActiveUser: false,
  userId: '',

  listClients: {},
  clients: {},
  client: {},
  accounts: {},
  account: {},
  clientAccounts: {},
  userFeatures: [],
  userPermissions: {},
  aclCurrent: {},
  loading: false,
};

const getters = {
};

const mutations = {
  setCognitoUser: (state, payload) => {
    Vue.set(state, 'cognito', payload);
    const userSession = payload.signInUserSession;
    const tokenPayload = userSession.accessToken.payload;
    const idTokenPayload = userSession.idToken.payload;

    // console.log('sdasdasd', tokenPayload);
    Vue.set(state, 'userId', payload.username);
    Vue.set(state, 'userSession', userSession);
    Vue.set(state, 'userGroups', tokenPayload['cognito:groups']);
    Vue.set(state, 'userFeatures', idTokenPayload.features);
  },

  setPermissions: (state, payload) => {
    Vue.set(state, 'userPermissions', payload);
    state.isAdmin = state.userPermissions.isSysAdmin || state.userPermissions.isDevAdmin;
    state.isDevAdmin = state.userPermissions.isDevAdmin;
    state.isSysAdmin = state.userPermissions.isSysAdmin;
  },

  setClient: (state, payload) => {
    Vue.set(state, 'client', payload);
    Vue.set(state, 'clientAccounts', payload.accounts.items);
    const account = state.clientAccounts.filter((item) => {
      const isDefault = item.code === state.client.code;
      return isDefault ? item : null;
    })[0];
    Vue.set(state, 'account', account);

    state.aclCurrent = {};

    if (state.isAdmin) {
      state.aclCurrent.admin = true;
      return;
    }

    const { code } = state.client;
    const isClientAdmin = !!state.userPermissions[code]
      && state.userPermissions[code].accounts[code]
      && state.userPermissions[code].accounts[code].permissions.indexOf('ca') > -1;
    if (isClientAdmin) {
      state.aclCurrent.clientAdmin = true;
      return;
    }
    state.userPermissions[state.client.code].accounts[state.account.code].permissions.forEach(
      (item) => {
        state.aclCurrent[item] = true;
      },
    );
  },

  setClientAccount: (state, payload) => {
    Vue.set(state, 'client', payload.item.client);
    Vue.set(state, 'clientAccounts', payload.item.client.accounts.items);
    Vue.set(state, 'account', payload.item.account);

    localStorage.setItem(`ETUser${payload.item.client.code}AccountCode`, payload.item.account.code);
    localStorage.setItem('ETUserClientCode', payload.item.client.code);

    state.aclCurrent = {};
    if (state.isAdmin) {
      state.aclCurrent.admin = true;
      return;
    }
    const { code } = state.client;
    const isClientAdmin = !!state.userPermissions[code]
      && state.userPermissions[code].accounts[code]
      && state.userPermissions[code].accounts[code].permissions.indexOf('ca') > -1;
    // Check if event operator
    console.log('setClientAccount => ', isClientAdmin);
    const isEventOperator = !!state.userPermissions[code]
      && state.userPermissions[code].accounts[code]
      && state.userPermissions[code].accounts[code].permissions.indexOf('ec') > -1;
    if (isClientAdmin) {
      state.aclCurrent.clientAdmin = true;
      return;
    }
    if (isEventOperator) {
      state.aclCurrent.eventCoordinator = true;
      state.aclCurrent.ec = true;
    }
    if (!state.userPermissions[code].accounts[state.account.code]) return;
    state.userPermissions[code].accounts[state.account.code].permissions.forEach(
      (item) => {
        state.aclCurrent[item] = true;
      },
    );
  },

  setAccountById: (state, payload) => {
    const account = state.client.accounts.items.filter((item) => {
      const isActive = item.id === payload;
      return isActive ? item : null;
    })[0];
    if (!account) return;
    Vue.set(state, 'account', account);
  },

  clearAccount: (state) => {
    Vue.set(state, 'account', {
      name: '',
    });
  },

  setAccountList: (state, payload) => {
    Vue.set(state, 'listAccounts', payload);
    const accounts = {};
    payload.items.forEach((item) => {
      accounts[item.id] = item;
    });

    Vue.set(state, 'accounts', accounts);
  },

  setClientList: (state, payload) => {
    Vue.set(state, 'listClients', payload);
    const savedClientCode = localStorage.getItem('ETUserClientCode');

    const clients = {};
    let [mainClient] = payload.items;
    console.log('%cCLIENT LIST ERROR', 'color:lime', mainClient);
    mainClient = mainClient || { code: '', accounts: { items: [] } };
    payload.items.forEach((item) => {
      clients[item.id] = item;
    });

    const defaultClientCode = savedClientCode || mainClient.code;
    mainClient = clients[defaultClientCode] ? clients[defaultClientCode] : mainClient;
    Vue.set(state, 'clients', clients);

    Vue.set(state, 'client', mainClient);
    Vue.set(state, 'clientAccounts', mainClient.accounts.items);

    let defaultAccount = null;
    const savedAccountCode = mainClient ? localStorage.getItem(`ETUser${mainClient.code}AccountCode`) : null;
    const defaultAccountCode = savedAccountCode || (mainClient ? mainClient.code : null);

    state.client.accounts.items.forEach((account) => {
      const isSelected = account.code === defaultAccountCode;
      if (isSelected) defaultAccount = account;
    });
    const mainAccount = defaultAccount || state.client.accounts.items[0];
    if (mainAccount) Vue.set(state, 'account', mainAccount);
    if (state.isAdmin) {
      state.aclCurrent.admin = true;
      return;
    }
    const { code } = state.client;
    const isClientAdmin = !!state.userPermissions[code]
      && state.userPermissions[code].accounts[code]
      && state.userPermissions[code].accounts[code].permissions.indexOf('ca') > -1;
    // Check if event operator
    const isEventOperator = !!state.userPermissions[code]
      && state.userPermissions[code].accounts[code]
      && state.userPermissions[code].accounts[code].permissions.indexOf('ec') > -1;
    if (isClientAdmin) {
      state.aclCurrent.clientAdmin = true;
      return;
    }
    if (isEventOperator) {
      state.aclCurrent.eventCoordinator = true;
      state.aclCurrent.ec = true;
    }
    if (!state.userPermissions[code] || !state.userPermissions[code].accounts[state.account.code]) return;

    state.userPermissions[code].accounts[state.account.code].permissions.forEach(
      (item) => {
        state.aclCurrent[item] = true;
      },
    );
  },
};

const actions = {
  async fetchClientList({ commit }) {
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listClients, {
          limit: 1500,
        }),
      );
      const { data } = result;
      commit('setClientList', data.listClients);
      return data.listClients;
    } catch (error) {
      const hasData = !!error.data && !!error.data.listClients;
      if (hasData) {
        const { data } = error;
        commit('setClientList', data.listClients);
        console.debug('Oops something went wrong. Can\'t get some of the client info', data.eventsByStartTime);
        const errorTypes = [...new Set(error.errors.map(el => el.errorType))];
        const isUnauthorizedOnly = errorTypes.length === 1 && errorTypes.indexOf('Unauthorized') !== -1;
        if (!isUnauthorizedOnly) {
          // this._vm.$message.error(
          //   { message: 'Oops something went wrong.  },
          // );
        }
        return { error: true, message: error.errors };
      }
      debugger;
      console.log('%cCLIENT LIST ERROR', 'color:lime', error);
      this._vm.$message.error({ message: 'Oops, can\'t get client list.' });
      return { error: true, message: error.errors };
    }
  },

  async fetchClientDetails({ commit }, payload) {
    const result = await API.graphql(
      graphqlOperation(qlqueries.getClient, {
        id: payload,
      }),
    );
    const { data } = result;
    const isFailure = !!result.errors;
    if (isFailure) return { error: true, message: result.errors };
    commit('setClient', data.getClient);
    return data.getClient;
  },

  async fetchAccountDetails({ commit }, payload) {
    commit('setLoading', true);
    try {
      commit('setAccount', { name: 'loading...' });
      const result = await API.graphql(
        graphqlOperation(qlqueries.getAccount, {
          id: payload,
        }),
      );
      const { data } = result;
      commit('setAccount', data.getAccount);
      return data.getAccount;
    } catch (error) {
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async fetchUserList({ commit }) {
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listUsers),
      );
      const { data } = result;
      commit('setUserList', data.listUsers);
      return data.listUsers;
    } catch (error) {
      return { error: true, message: error.errors };
    }
  },

  async fetchAccountList({ commit }) {
    const result = await API.graphql(
      graphqlOperation(qlqueries.listAccounts),
    );
    const { data } = result;
    const isFailure = !!result.errors;
    if (isFailure) return { error: true, message: result.errors };
    commit('setAccountList', data.listAccounts);
    return data.listAccounts.items;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
