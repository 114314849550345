import Vue from 'vue';
import MultiviewCinegyService from '../services/MultiviewCinegy';

const state = {
  name: 'MultiviewCinegy',
  configuration: null,
};

const getters = {
};

const mutations = {
  setConfiguration: (state, payload) => {
    Vue.set(state, 'configuration', payload);
  },
  setSourceConfigurations: (state, payload) => {
    Vue.set(state, 'sourceConfigurations', payload);
  },
  setSourceConfigurationsObj: (state, payload) => {
    Vue.set(state, 'sourceConfigurationsObj', payload);
  },
  setSourceConfigOptions: (state, payload) => {
    Vue.set(state, 'selectedSourceConfig', payload);
  },
  setSelectedSourceConfig: (state, payload) => {
    Vue.set(state, 'selectedSourceConfig', payload);
    Vue.set(state, 'selectedSourceConfigObj', state.sourceConfigurationsObj[payload]);
    // localStorage.setItem('source', payload);
    // set('source', payload);
  },
};

const actions = {
  async fetchConfiguration({ commit }) {
    const result = await MultiviewCinegyService.getConfig();
    const { data } = result;
    const isSuccess = result.status === 200;
    console.log('fetchConfiguration result', result);
    if (!isSuccess) return { error: true, message: data.error };

    commit('setConfiguration', data);

    return result.data.data;
  },
  async changeLayout(state, { multiviewerId, newLayout }) {
    try {
      const result = await MultiviewCinegyService.multiviewerAction({
        id: multiviewerId, command: 'set_layout', nr: newLayout, player: '',
      });
      console.log('result', result);
    } catch (error) {
      this._vm.$message.error(
        { message: error },
      );
    }
  },
  async changePlayer(state, { multiviewerId, newPlayer }) {
    console.log('result changePlayer', multiviewerId, newPlayer);
    try {
      const result = await MultiviewCinegyService.multiviewerAction({
        id: multiviewerId, command: 'set_player', nr: newPlayer, player: '',
      });
      console.log('result', result);
    } catch (error) {
      this._vm.$message.error(
        { message: error },
      );
    }
  },
  async changeAudioChannel(state, { multiviewerId, player, newAudioChannel }) {
    console.log('result changeAudioChannel', multiviewerId, player);
    try {
      const result = await MultiviewCinegyService.multiviewerAction({
        id: multiviewerId, command: 'set_audio_channel', nr: newAudioChannel, player,
      });
      console.log('result', result);
    } catch (error) {
      this._vm.$message.error(
        { message: error },
      );
    }
  },
  async restart(state, { multiviewerId }) {
    try {
      const result = await MultiviewCinegyService.multiviewerAction({
        id: multiviewerId, command: 'restart', nr: '', player: '',
      });
      console.log('result', result);
    } catch (error) {
      this._vm.$message.error(
        { message: error },
      );
    }
  },
  /*
  async fetchConfiguration({ commit }, id) {
    const result = await
    commit('setCurrentFolder', result.data.folder);
    return result.data.folder;
  },
  */
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
