import Vue from 'vue';
import elementUi from 'element-ui';
import VueHotkey from 'v-hotkey';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import Amplify, * as AmplifyModules from 'aws-amplify';
import { AmplifyPlugin } from 'aws-amplify-vue';
import VueQuillEditor from 'vue-quill-editor';
import VueFilterDateFormat from 'vue-filter-date-format';
import Vuebar from 'vuebar';
// import VBar from 'v-bar';
import Affix from 'vue-affix';
import checkView from 'vue-check-view';
import VueCountdown from '@chenfengyuan/vue-countdown';
import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Sortable from 'vue-sortable';
import draggable from 'vuedraggable';
// import { Datetime } from 'vue-datetime';
import {
  DatetimePicker, Popup, Picker, Locale as localeVant,
} from 'vant';

import enUS from 'vant/lib/locale/lang/en-US';

import App from './App.vue';
import router from './router';
import store from './store';
import './filters';
import awsExports from './aws-exports';
import './components';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.bubble.css';
import './index.css';

library.add(faExchangeAlt);
dom.watch();
Vue.component('font-awesome-icon', FontAwesomeIcon);

locale.use(lang);
localeVant.use('en-US', enUS);
Vue.config.productionTip = false;

Vue.use(elementUi);
Vue.use(Affix);
Vue.use(checkView);
Vue.use(VueHotkey);
Vue.use(Sortable);
Vue.use(draggable);
Vue.use(AmplifyPlugin, AmplifyModules);
Vue.use(VueFilterDateFormat);
Vue.use(Vuebar);
// Vue.use(VBar);
Vue.use(VueQuillEditor);
Vue.use(DatetimePicker);
Vue.use(Popup);
Vue.use(Picker);
// Vue.component('datetime', Datetime);
Vue.component(VueCountdown.name, VueCountdown);
console.log('process.env', process.env);
Amplify.configure(awsExports[process.env.VUE_APP_MODE]);
store.state.awsExports = awsExports[process.env.VUE_APP_MODE];
console.log('=>', process.env, awsExports[process.env.VUE_APP_MODE]);

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

store.$app = app;
