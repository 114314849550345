<template>
  <div id="resources-view" tabindex="0">
   <div class="bg-gray-50 rounded-md border-dashed p-2"  style="min-height: 90px">
     <dl class="grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-8" v-show="tooltipData.fixture">
      <div class="sm:col-span-2">
        <dt class="text-xs uppercase font-medium text-gray-500">
          Fixture
        </dt>
        <dd class="text-sm text-gray-900">
          {{tooltipData.fixture}}
        </dd>
      </div>
      <div class="sm:col-span-1">
        <dt class="text-xs uppercase font-medium text-gray-500">
          Service start time
        </dt>
        <dd class=" text-sm text-gray-900">
          {{moment.utc(tooltipData.serviceStartTime).format('YYYY/MM/DD HH:mm')}}
        </dd>
      </div>
      <div class="sm:col-span-1">
        <dt class="text-xs uppercase font-medium text-gray-500">
          Line-up
        </dt>
        <dd class=" text-sm text-gray-900">
          {{moment.utc(tooltipData.lineupTime).format('YYYY/MM/DD HH:mm')}}
        </dd>
      </div>
      <div class="sm:col-span-1">
        <dt class="text-xs uppercase font-medium text-gray-500">
          Kick-off
        </dt>
        <dd class=" text-sm text-gray-900">
          {{moment.utc(tooltipData.startTime).format('YYYY/MM/DD HH:mm')}}
        </dd>
      </div>
      <div class="sm:col-span-1">
        <dt class="text-xs uppercase font-medium text-gray-500">
          End time
        </dt>
        <dd class=" text-sm text-gray-900">
          {{moment.utc(tooltipData.endTime).format('YYYY/MM/DD HH:mm')}}
        </dd>
      </div>
      <div class="sm:col-span-1">
        <dt class="text-xs uppercase font-medium text-gray-500">
          Input
        </dt>
        <dd class=" text-sm text-gray-900 break-words">
          {{tooltipData.inputName}} 
          <span class="text-red-500" v-show="overlappingSelectedInputNumber">
            <i class="far fa-exclamation-circle"></i>
            {{overlappingSelectedInputNumber}}
          </span>
        </dd>
      </div>
      <div class="sm:col-span-1 pl-1">
        <dt class="text-xs uppercase font-medium text-gray-500">
          Customer/Account
        </dt>
        <dd class=" text-sm text-gray-900" v-if="tooltipData && tooltipData.account">
          {{tooltipData.customerName}} / {{tooltipData.account.name}}
        </dd>
      </div>
    </dl>
    <dt class="text-base text-blue-500" v-if="!tooltipData.fixture">
      <i class="far fa-info-circle"></i> Click the event to see details
    </dt>
   </div>
   <div id="resourceChart"></div>
  </div>
</template>

<script>
import * as d3 from 'd3';
import moment from 'moment';
// import * as tl from '../d3-timeline';

export default {
  name: 'ResourceView2',
  props: ['dataset', 'range', 'overlappingInputs'],
  data() {
    return {
      moment,
      chart: null,
      main: null,
      mini: null,
      chartContainer: null,
      xScale: null,
      xScale2: null,
      yScale: null,
      yScale2: null,
      domain: null,
      xAxis: null,
      xAxisTop: null,
      xAxisTopEl: null,
      yAxis: null,
      brush:null,
      chartOptions: {
        width: 1000,
        height: 600,
        margin: {
          top: 2,
          right: 2,
          bottom: 2,
          left: 2,
        },
        ctrWidth: 0,
        ctrHeight: 0,
      },
      inputs: [],
      mainItems: [],
      mainItemsText: [],
      tooltipData: {},
      
      // new timeline
      events: [],
      statuses: [
        ['COMPLETED', '#FF6400'],
        ['CONFIRMED', '#90EE90'],
        ['CLIENT_CONFIRMED', '#003CFF'],
        ['RECEIVED', '#FFC400'],
        ['LIVE', '#FF6400'],
        ['PENDING', '#90EE90'],
        ['CANCELLED', '#003CFF'],
        ['LATE_CANCELLED', '#FFC400'],
        ['POSTPONED', '#FF6400'],
        ['FAILED', '#FFC400'],
        ['AWAITING_SIGNAL', '#6cdf6c']
      ],
      width: null,
      height: null,

      minuteInMs: 60000,
      hourInMs: this.minuteInMs * 60,
      dayInMs: this.hourInMs * 24,
      weekInMs: this.dayInMs * 7,

      rows: null,
      groups: null,
      zoombrush: null,
      rowsHeight: null,
      chartHeight: null,
      evenDaysWidth: null,
      groupped: 1,

      // new init later
      svgBack: null,
      svgTop: null,
      todaySvg: null,
      wrapper: null,
      svg: null,
      todaySvg: null,
      svgBottom: null,
      x: null,
      xBrush: null,
      start: null,
      end: null,
      evenDays: null,
      categories: null,
      gGooey: null,
      defs: null,
      filter: null,
      chart: null,
      g: null,
      todayCircle: null,
      todayLine: null,
      cellBack: null,
      cell: null,
      text: null,
      axisTopDay: null,
      axisTopDate: null,
      axisTopTime: null,
      axisBottomDate: null,
      brush: null,
      zoom: null,
      gb: null,
      sMax: null,
      sDay: null,
      indent: null,

      axisTopHeight: 46,
      marginTop: 62,
      cellStrokeWidth: 6,
      cellHeight: 52,
      cellWidthMin: this.cellHeight * 2,
      cellPaddingTop: 29,
      cellPaddingLeft: 10,
      gapY: 16,
      gapX: 16,
      cellColor: null,
      cellTextColor: 'white',
      cellRadius: 6,
      time: null,
      brushHeight: 49,
      sm: null,

      selector: '#resourceChart',
      eventsQuantity: 100,
    };
  },
  computed: {
    overlapingInputsOverallNumber() {
      return this.dataset.filter((event) => {
        return event.overlappingInputs.length > 0;
      })
      .map(event => event.overlappingInputs.length)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    },
    overlappingSelectedInputNumber() {
      if (!this.tooltipData.id) return 0;

      return this.tooltipData.overlappingInputs.length;
    },
    processedRange() {
      return {
        start: moment.utc(this.range.start).add(1, 'days').format(),
        end: moment.utc(this.range.end).subtract(1, 'days').endOf('day').format(),
      };
    },
  },

  watch: {
    dataset(val, old) {
      console.log('asdasdasdadaqweqxasdas', val, old);
      if (val && !this.chart)  {
        // this.init(val);
        // this.display({selection: [this.xScale(new Date(this.processedRange.start)), this.xScale(new Date(this.processedRange.end))] })
      }
    },
  },

  created() {
    // d3.timeline = tl.timeline;
  },

  mounted() {
    console.log('Resource Mount', this.dataset);
    if (this.dataset && !this.chart)  {
      const chartHolder = document.querySelector(this.selector);
      this.width = chartHolder.clientWidth;
      this.height = chartHolder.clientHeight;

      // this.init(this.dataset);
      setTimeout(() => {
        this.initTimeline2(this.dataset);
      }, 1000);
      // this.display({selection: [this.xScale(new Date(this.processedRange.start)), this.xScale(new Date(this.processedRange.end))] })
    }
  },

  methods: {
    initTimeline2(dataset) {
      const minuteInMs = 60000
      const hourInMs = minuteInMs * 60
      const dayInMs = hourInMs * 24
      const weekInMs = dayInMs * 7
      this.minuteInMs = 60000;
      this.hourInMs = this.minuteInMs * 60;
      this.dayInMs = this.hourInMs * 24;
      this.weekInMs = this.dayInMs * 7;

      let events = []
      const eventsQuantity = 100
      const statuses = [
        ['COMPLETED', '#0033ff'],
        ['CONFIRMED', 'lightgreen'],
        ['CLIENT_CONFIRMED', '#ff9000'],
        ['RECEIVED', '#EA3CF7'],
        ['LIVE', '#0f0'],
        ['PENDING', '#FFFE54'],
        ['CANCELLED', '#999999'],
        ['LATE_CANCELLED', '#8b0000'],
        ['POSTPONED', '#999999'],
        ['FAILED', '#f00'],
        ['AWAITING_SIGNAL', '#6cdf6c']
      ]


      // init random data
      this.events = dataset.map((event) => {
        const startTime = moment.utc(event.serviceStartTime).diff(moment.utc(event.startTime));

        const newEvent = {
          ...event,
          serviceStartTime: moment.utc(event.serviceStartTime).toDate(),
          serviceEndTime: moment.utc(event.serviceEndTime).toDate(),
          startTime: moment.utc(event.startTime).toDate(),
          endTime: moment.utc(event.endTime).toDate(),
          description: event.description,
          category: event.inputName,
          status: event.status,
          title: event.fixture,
          groupId: event.gorupId
        };
        return newEvent;
      });
      console.log('events', this.events);
      const selector = '#resourceChart';
      this.events = this.events.sort((a, b) =>
        a.serviceStartTime - b.serviceStartTime || a.serviceEndTime - b.serviceEndTime
      )
      this.categories = [...new Set(this.events.map(i => i.inputName))].sort((a, b) => d3.ascending(a, b))

      let width = document.documentElement.clientWidth
      let height = document.documentElement.clientHeight

      const axisTopHeight = 46
      const marginTop = 62
      const cellStrokeWidth = 6
      this.cellHeight = 32
      const cellWidthMin = this.cellHeight * 2
      const cellPaddingTop = 29
      const cellPaddingLeft = 10
      this.gapY = 4
      const gapX = 16
      const cellColor = (d) => statuses[statuses.map(i => i[0]).indexOf(d.status)][1]
      const cellTextColor = 'white'
      const cellRadius = 6
      const time = d3.timeFormat('%H:%M')
      const brushHeight = 49
      const sm = width * dayInMs / 320
      let rows, groups, zoombrush, rowsHeight, chartHeight, evenDaysWidth
      let groupped = 1
      let indent = this.gapY

      this.svgBack = d3
        .select(selector)
        .append('svg')
        .attr('class', 'svgBack')

      this.svgTop = d3.select(selector)
        .append('svg')
        .attr('width', '100%')
        .attr('height', axisTopHeight)

      const wrapper = d3.select(selector)
        .append('div')
        .attr('class', 'wrapper')
        .style('overflow-y', 'auto')

      this.svg = wrapper.append('svg')
        .attr('width', '100%')

      const todaySvg = d3.select(selector)
        .append('svg')
        .attr('class', 'todaySvg')

      const svgBottom = d3.select(selector)
        .append('svg')
        .attr('width', '100%')
        .attr('height', brushHeight)
        .attr('class', 'svgBottom')

console.log('events[0].serviceStartTime', moment.utc(this.range.start).startOf('day').toString(), d3.min(this.events.map(i => i.serviceStartTime)));
      const dmn = [
        // d3.min(this.events.map(i => i.serviceStartTime)),
        moment.utc(this.range.start).toDate(),
        moment.utc(this.range.end).toDate(),
        // this.range.start,
        // this.range.end
        d3.max(this.events.map(i => i.serviceEndTime))
      ]

      this.x = d3
        .scaleTime()
        .domain(dmn)
        .range([0, width])

      this.xBrush = d3
        .scaleTime()
        .domain([
          this.x.invert(-this.gapY),
          this.x.invert(width + this.gapY)
        ])
        .range([0, width])

      const start = this.xBrush.domain()[0]// d3.timeDay.offset(this.xBrush.domain()[0], -1)
      const end = this.xBrush.domain()[1]
      const evenDays = d3.timeDay.range(start, end, 2)

      this.svgBack.selectAll('rect')
        .data(evenDays)
        .join('rect')
        .attr('class', 'evenDays')

      const gGooey = svgBottom
        .append("g")
        .style("filter", 'url(#gooey)')

      const defs = svgBottom.append('defs')
      const filter = defs.append('filter').attr('id','gooey')

      filter.append('feGaussianBlur')
        .attr('in','SourceGraphic')
        .attr('stdDeviation','10')
        .attr('result','blur')

      filter.append('feColorMatrix')
        .attr('in','blur')
        .attr('mode','matrix')
        .attr('values','1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 75 -10')
        .attr('result','gooey')

      filter.append('feBlend')
        .attr('in','SourceGraphic')
        .attr('in2','gooey')
        .attr('operator','over')

      gGooey.append('rect')
        .style('fill', 'none')
        .attr('width', width)
        .attr('height', brushHeight)

      gGooey
        .selectAll('circle')
        .data(this.events)
        .join('circle')
        .attr('cx', d => this.xBrush(d.startTime))
        .attr('cy', 17)
        .attr('r', d => {
          // this.xBrush(d.startTime)
          if (!d.overlappingInputs) return 3;
          console.log('....', d);
          const size = d.overlappingInputs.length > 0 ? 5: 3;
          return size;
        })
        .style('fill', d => {
          // this.xBrush(d.startTime)
          console.log('....', d);
          if (!d.overlappingInputs) return '#ff6400';
          const color = d.overlappingInputs.length > 0 ? 'red' : '#000';
          return color;
        })


      this.chart = this.svg.append('g')
        // .attr('transform', 'translate(0, -13)') // move events group up

      this.g = this.chart
        .selectAll('g')
        .data(this.events)
        .join('g')
        .attr('class', d => d.overlappingInputs.length > 0 ? 'overlapping' : '')
        .on('mouseenter', e => {
          console.log('tooltip', this.tooltipData = e.target.__data__);
          this.tooltipData = e.target.__data__
        })
        .on('click', function() {
          const cb = d3.select(this).select('.cellBack')
          console.log('clicked',cb, this)
          if (cb.classed('selected')) {
            cb.classed('selected', false)
          } else {
            d3.selectAll('.selected').classed('selected', false)
            cb.classed('selected', true)
          }
        })

      this.todayCircle = todaySvg.append('circle')
        .attr('cy', 4)
        .attr('r', 4)
        .style('fill', '#FF6600')
        .attr('stroke', 'white')

      this.todayLine = this.svg.append('line')
        .attr('y1', 0)
        .attr('y2', '100%')
        .attr('vector-effect', 'non-scaling-stroke')
        .attr('shape-rendering', 'crispEdges')
        .attr('stroke', '#FF6600')

      this.cellBack = this.g
        .append('rect')
        .attr('width', d => this.cellWidth(d))
        .attr('height', this.cellHeight)
        .attr('rx', cellRadius)
        .attr('ry', cellRadius)
        .attr('class', d => d.status === 'LIVE' ? 'cellBack live' : 'cellBack')
        .style('fill', d => d3.interpolate('white', cellColor(d))(0.4))

      this.cell = this.g
        .append('rect')
        .attr('height', this.cellHeight)
        .attr('rx', cellRadius)
        .attr('ry', cellRadius)
        .attr('class', 'cell')
        .style('fill', d => cellColor(d))

      const text = this.g
        .append('foreignObject')
        .attr('height', this.cellHeight)
        .attr('class', 'text')

      text.append('xhtml:div')
        .attr('class', 'category')
        .html(d => {
          if (d.groupId) {
            const htmlCode = `
              <svg width='18' height='11'>
                <rect y='3' width='1' height='6' rx='1' ry='1' fill='white'/>
                <rect x='3' y='1' width='10' height='10' rx='1' ry='1' fill='white'/>
              </svg>
              <a href='#'>${d.category}</a>
            `
            return htmlCode
          } else {
            return `<a href='#'>${d.category}</a>`
          }
        })

      text.selectAll('a')
        .on('click', () => {
          this.groupped = 1
          this.events = this.events.sort((a, b) => d3.ascending(a.category, b.category))
          console.log('categories', this.categories)
          groups = this.categories.map(i => [i, [-Infinity]])
          text.selectAll('.category').remove()
          this.cellHeight = 37
          this.g.selectAll('rect')
            .attr('height', this.cellHeight)
          this.y()
          this.chart.selectAll('g')
            .transition()
            .attr('transform', d => `translate(${this.x(d.serviceStartTime)}, ${d.y})`)
          groups.forEach((d, i) => {
            console.log(d[0].match(/\w+/g).join(''))
            const lineIndex = groups.slice(0, i + 1).map(j => j[1]).flat().length
            const lineY = (this.gapY + 1) * (i + 1) + (this.cellHeight + this.gapY) * lineIndex
            this.svg.insert('line', 'g')
              .transition()
              .attr('y1', lineY)
              .attr('y2', lineY)
              .attr('x1', 0)
              .attr('x2', '100%')
              .attr('shape-rendering', 'crispEdges')
              .attr('stroke', '#C3C3C3')
              .attr('class', d[0].match(/\w+/g).join(''))
            const textIndex = groups.slice(0, i).map(j => j[1]).flat().length
            const textY = (this.gapY + 1) * (i + 1) + (this.cellHeight + this.gapY) * textIndex + 22
            this.svg.append('text')
              .transition()
              .attr('x', 10)
              .attr('y', textY)
              .attr('style', 'font-size: 13px; color: #707070; text-transform: uppercase')
              .attr('class', d[0].match(/\w+/g).join('') + 'label')
              .text(d[0])
          })
        })

      text.append('xhtml:div')
        .html(d => `<b>${time(d.startTime)} − ${time(d.endTime)}</b> ${d.title}`)

      text.append('xhtml:div')
        .html(d => d.description)
        .attr('style', 'font-size: 11px; top: -7px; position: relative')


      this.axisTopDay = d3
        .axisTop(this.x)
        .ticks(d3.timeDay)
        .tickSize(0)
        .tickFormat(d3.timeFormat('%A'))

      this.axisTopDate = d3
        .axisTop(this.x)
        .ticks(d3.timeDay)
        .tickSize(0)
        .tickFormat(d3.timeFormat('%d/%m/%Y'))

      this.axisTopTime = d3
        .axisTop(this.x)
        .tickSize(2)
        .tickPadding(3)
        .tickFormat(time)
        .ticks(Math.round(width / 120))

      this.axisBottomDate = d3
        .axisTop(this.xBrush)
        .ticks(d3.timeDay)
        .tickSize(5)
        .tickPadding(4)
        .tickFormat(d3.timeFormat('%d/%m/%Y'))

      this.svgTop
        .append('g')
        .attr('transform', `translate(${0}, ${21})`)
        .attr('class', 'axisTopDay')
        .call(this.axisTopDay)

      this.svgTop
        .append('g')
        .attr('transform', `translate(${0}, ${32})`)
        .attr('class', 'axisTopDate')
        .call(this.axisTopDate)

      this.svgTop
        .append('g')
        .attr('transform', `translate(${0}, ${45})`)
        .attr('class', 'axisTopTime')
        .call(this.axisTopTime)

      svgBottom.append('line')
        .attr('y1', 1)
        .attr('y2', 1)
        .attr('x1', 0)
        .attr('x2', '100%')
        .attr('vector-effect', 'non-scaling-stroke')
        .attr('shape-rendering', 'crispEdges')
        .attr('stroke', '#C6C6C6')

      svgBottom
        .append("g")
        .attr('transform', `translate(${0}, ${brushHeight})`)
        .attr('class', 'axisBottomDate')
        .call(this.axisBottomDate)
        .select('.domain')
        .remove()

      this.brush = d3
        .brushX()
        .extent([[0, 1], [width - 98, brushHeight]])
        .on('brush', this.brushed)

      this.zoom = d3.zoom()
        .scaleExtent([1, (dmn[1] - dmn[0]) / hourInMs])
        .translateExtent([[0, 0], [width, height]])
        .extent([[0, 0], [width, height]])
        .filter((event) => event.altKey || event.type === 'dblclick' || event.type === 'touchmove' || event.type === 'mousemove' || event.type === 'mousedown' || event.type === 'mouseup' )
        .on("zoom", this.zoomed)

      let sMax = [this.xBrush(this.xBrush.invert(width) - sm), width]
      let sDay = [0, width]

      this.gb = svgBottom
        .append('g')
        .attr('class', 'brush')
        .call(this.brush)
        .call(this.brush.move, sDay)

      this.svg.call(this.zoom);
      // on("wheel.zoom", null);


      // group by category
      this.groupped = 1
      this.events = this.events.sort((a, b) => d3.ascending(a.category, b.category))
      console.log('categories', this.categories)
      groups = this.categories.map(i => [i, [-Infinity]])
      text.selectAll('.category').remove()
      // this.cellHeight = 37
      this.g.selectAll('rect')
        .attr('height', this.cellHeight)
      this.y()
      this.chart.selectAll('g')
        .transition()
        .attr('transform', d => `translate(${this.x(d.serviceStartTime)}, ${d.y})`)

      const step = this.cellHeight + this.gapY // event Y position
      this.groups.forEach((d, i) => {
        console.log(d[0].match(/\w+/g).join(''))
        const lineIndex = this.groups.slice(0, i + 1).map(j => j[1]).flat().length
        const lineY = (this.gapY + 1) * (i + 1) + step * lineIndex
        this.svg.insert('line', 'g')
          .transition()
          .attr('y1', lineY)
          .attr('y2', lineY)
          .attr('x1', 0)
          .attr('x2', '100%')
          .attr('shape-rendering', 'crispEdges')
          .attr('stroke', '#C3C3C3')
          .attr('class', d[0].match(/\w+/g).join(''))
        const textIndex = this.groups.slice(0, i).map(j => j[1]).flat().length
        const textY = (this.gapY + 1) * (i + 1) + step * textIndex + 20
        this.svg.append('text')
          .transition()
          .attr('x', 10)
          .attr('y', textY)
          .attr('style', 'font-size: 13px; color: #707070; text-transform: uppercase')
          .attr('class', d[0].match(/\w+/g).join('') + 'label')
          .text(d[0])
      })
    },

    brushed(e) {
      if (this.zoombrush) return
      this.zoombrush = 1
      const s = e.selection
      if (this.xBrush.invert(s[1]) - this.xBrush.invert(s[0]) < this.hourInMs) {
        d3.select(this).call(this.brush.move, this.x.domain().map(i => this.xBrush(i)))
      } else {
        this.x.domain(s.map(this.xBrush.invert, this.xBrush))
        this.y();
        // this.xBrush.domain([
        //   d3.timeMillisecond.offset(dmn[0], -(x.invert(indent) - x.invert(0))),
        //   x.invert(d3.max(rows) + this.gapY)
        // ])
        // svgBottom.select('.axisBottomDate').call(axisBottomDate)
        this.svgTop.select('.axisTopDay').call(this.axisTopDay)
        this.svgTop.select('.axisTopDate').call(this.axisTopDate)
        this.svgTop.select('.axisTopTime').call(this.axisTopTime)
        this.chart.selectAll('g')
          .attr('transform', d => `translate(${this.x(d.serviceStartTime)}, ${d.y})`)
        this.g.selectAll('.cellBack')
          .attr('width', d => this.cellWidth(d))
        this.g.selectAll('.cell')
          .attr('width', d => this.cellWidthFront(d))
          .attr('x', d => this.x(d.startTime) - this.x(d.serviceStartTime))
        this.g.selectAll('foreignObject')
          .attr('x', d => this.x(d.startTime) - this.x(d.serviceStartTime))
          .attr('width', d => this.cellWidthFront(d))
        this.todayLine.attr('x1', this.x(new Date())).attr('x2', this.x(new Date()))
        this.todayCircle.attr('cx', this.x(new Date()))
        // console.log('evenDaysWidth', this.dayInMs, this.x(this.dayInMs))
        this.evenDaysWidth = this.x(this.dayInMs) - this.x(0)
        this.svgBack.selectAll('rect').attr('x', d => this.x(d)).attr('width', this.evenDaysWidth)
        this.svg.call(
          this.zoom.transform,
          d3.zoomIdentity.scale(this.width / (s[1] - s[0])).translate(-s[0], 0)
        )
      }
      this.zoombrush = 0
    },

    zoomed(e) {
      if (this.zoombrush) return
      this.zoombrush = 1
      const t = e.transform
      this.x.domain(t.rescaleX(this.xBrush).domain())
      this.y()
      this.svgTop.select('.axisTopDay').call(this.axisTopDay)
      this.svgTop.select('.axisTopDate').call(this.axisTopDate)
      this.svgTop.select('.axisTopTime').call(this.axisTopTime)
      this.chart.selectAll('g')
        .attr('transform', d => `translate(${this.x(d.serviceStartTime)}, ${d.y})`)
      this.g.selectAll('.cellBack')
        .attr('width', d => this.cellWidth(d))
      this.g.selectAll('.cell')
        .attr('width', d => this.cellWidthFront(d))
        .attr('x', d => this.x(d.startTime) - this.x(d.serviceStartTime))
      this.g.selectAll('foreignObject')
        .attr('x', d => this.x(d.startTime) - this.x(d.serviceStartTime))
        .attr('width', d => this.cellWidthFront(d))
      this.todayLine.attr('x1', this.x(new Date())).attr('x2', this.x(new Date()))
      this.todayCircle.attr('cx', this.x(new Date()))
      this.evenDaysWidth = this.x(this.dayInMs) - this.x(0)
      console.log('evenDaysWidth', this.dayInMs, this.x(this.dayInMs))
      this.svgBack.selectAll('rect').attr('x', d => this.x(d)).attr('width', this.evenDaysWidth)
      this.gb.call(this.brush.move, this.xBrush.range().map(t.invertX, t))
      this.zoombrush = 0
    },
    cellWidth(d) {
      return d3.max([
        (this.x(d.startTime) - this.x(d.serviceStartTime)) + this.cellWidthMin + (this.x(d.serviceEndTime) - this.x(d.endTime)),
        this.x(d.serviceEndTime) - this.x(d.serviceStartTime)
      ])
    },

    cellWidthFront(d) {
      return d3.max([
        this.cellWidthMin,
        this.x(d.endTime) - this.x(d.startTime)
      ])
    },

    y() {
      this.rows = [-Infinity]
      this.groups = this.categories.map(i => [i, [-Infinity]])
      const start = (d) => this.x(d.serviceStartTime)
      const step = this.cellHeight + this.gapY // event Y position
      const h = document.documentElement.clientHeight - this.axisTopHeight - this.brushHeight
      console.log('step 1', step, h);
      if (!this.groupped) {
        this.events.forEach(d => {
          const findIndex = this.rows.findIndex(i => i < start(d))
          if (findIndex >= 0) {
            this.rows[findIndex] = start(d) + this.cellWidth(d) + this.gapX
            d.y = this.gapY + step * findIndex
          } else {
            this.rows.push(start(d) + this.cellWidth(d) + this.gapX)
            d.y = this.gapY + step * (this.rows.length - 1)
          }
        })
        this.rowsHeight = this.gapY + step * this.rows.length
      } else {
        this.events.forEach(d => {
          let group = this.groups[this.categories.indexOf(d.category)][1]
          const index = this.categories.indexOf(d.category)
          const indx = this.groups.slice(0, index).map(i => i[1]).flat().length
          const findIndex = group.findIndex(i => i < start(d))
          if (findIndex >= 0) {
            group[findIndex] = start(d) + this.cellWidth(d) + this.gapX
            d.y = this.gapY * (index + 1) + step * (indx + findIndex) + index
          } else {
            group.push(start(d) + this.cellWidth(d) + this.gapX)
            d.y = this.gapY * (index + 1) + step * (indx + group.length - 1) + index
            console.log('step 2 d.y', step, h, this.gapY, (indx + group.length - 1), index, indx);
          }
        })
        this.groups.forEach((d, i) => {
          const lineIndex = this.groups.slice(0, i + 1).map(j => j[1]).flat().length
          const lineY = (this.gapY + 1) * (i + 1) + step * lineIndex
          
            console.log('step 2 lineY', lineIndex, lineY, (this.gapY + 1) * (i + 1));
          this.svg.select('.' + d[0].match(/\w+/g).join(''))
            .attr('y1', lineY)
            .attr('y2', lineY)
          const textIndex = this.groups.slice(0, i).map(j => j[1]).flat().length
          const textY = (this.gapY + 1) * (i + 1) + step * textIndex + 20
          this.svg.select('.' + d[0].match(/\w+/g).join('') + 'label')
            .attr('y', textY)
          this.rowsHeight = lineY - 1
        })
      }
      this.chartHeight = this.rowsHeight < h ? '100%' : this.rowsHeight
      this.svg.attr('height', this.chartHeight)
    },
  },
};
</script>
<style lang="scss" scope>
div#resources-view {
    width: calc(101vw - 98px);
    height: calc(100vh - 148px);
}
div#resourceChart {
    width: calc(101vw - 51px);
    height: calc(100vh - 189px);
    margin-left: -24px;
}


.axisTopDay *, .axisLeft * {
  font-size: 13px;
  color: #707070;
}

.axisTopDate *, .axisTopTime *, .axisBottomDate * {
  font-size: 10px;
}

.axisTopDay *, .axisTopDate * {
  text-anchor: start;
  stroke: none;
}

.axisLeft * {
  text-transform: uppercase;
}

#resourceChart, svg {
  display: block;
}

#resourceChart {
  // width: 100vw;
}

.live {
  paint-order: stroke;
  stroke: black;
  stroke-linejoin: round;
  stroke-width: 6px;
}

.selected {
  paint-order: stroke;
  stroke: #FF6400;
  stroke-linejoin: round;
  stroke-width: 6px;
}
.overlapping .cellBack {
  paint-order: stroke;
  stroke: red;
  stroke-linejoin: round;
  stroke-width: 6px;
}

.selection {
  fill: #F4670C;
  stroke: #F4670C;
}

.text {
  padding: 0px 10px;
}

.text * {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: white;
}

a {
  text-decoration: none;
  text-transform: uppercase;
}

b {
  font-weight: bold;
}

.category {
  display: flex;
  font-size: 11px;
}

.categoryLabel {
  text-transform: uppercase;
}

.evenDays {
  fill: #F3F2F5;
  fill-opacity: 0.4;
  height: 100%;
}

.svgBack {
  position: absolute;
  z-index: -9;
  width: calc(101vw - 51px);
  height: calc(100vh - 149px);
}

.svgBottom {
  background-color: white;
}

.todaySvg {
  position: absolute;
  top: 41px;
  left: 0px;
  width: 100vw;
  height: 8px;
}

.wrapper {
  // height: calc(100vh - 95px);
  // width: 100vw;
  height: calc(100% - 95px);
  width: 100%;
}
// #timeline3 {
//   svg {
//     min-height: 300px;
//   }
// }
// .brush .selection {
//     stroke: gray;
//     fill: dodgerblue;
//     fill-opacity: .365;
//     max-height: 100%;
// }
// .mini .overlay {
//   background: #efefef;
// }
// .xaxis {
//   color: #999;
// }

// .resourceItem {
//   // opacity: 0.5;
//   text {
//     // color: #fff;
//     font-size: 11px;
//   }
//   &.overlapping {
//     filter: contrast(1.5);
//     opacity: .6;
//   }
// }
</style>
