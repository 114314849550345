import Vue from 'vue';
import moment from 'moment';

Vue.filter('defaultValue', (value, defaultValue) => {
  const defaultString = defaultValue || '----';
  if (!value) return defaultString;
  return value;
});

Vue.filter('uppercase', (value) => value.charAt(0).toUpperCase() + value.slice(1));

Vue.filter('currencyDisplay', {
  read: (val) => `$${val.toFixed(2)}`,

  write: (val) => {
    const number = +val.replace(/[^\d.]/g, '');
    return Number.isNaN(number) ? 0 : parseFloat(number.toFixed(2));
  },
});

Vue.filter('truncate ', (value, length) => {
  const maxLength = length || 15;
  if (!value || typeof value !== 'string') return '';
  if (value.length <= maxLength) return value;
  return `${value.substring(0, maxLength)}...`;
});

Vue.filter('date', (value, format) => {
  if (!value) return '---';

  const dateFormat = format || 'HH:mm:ss';

  return moment(value).format(dateFormat);
});

Vue.filter('dateUtc', (value, format) => {
  if (!value) return '---';

  const dateFormat = format || 'HH:mm';

  return moment.utc(value).format(dateFormat);
});

Vue.filter('stripTags', (value) => {
  if (!value) return '---';
  return value.replace(/(<([^>]+)>)/ig, ' ');
});

Vue.filter('formatDate', (value, format) => {
  if (!value) return '---';
  return moment.utc(value).format(format);
});

Vue.filter('playbackName', (value) => {
  if (!value && value !== 0) return '';

  if (value.startsWith('Playback')) return value.split('.').slice(0, -1).join('.').toUpperCase();

  return '';
});

Vue.filter('removeSpaces', (value, disable) => {
  if (!value && value !== 0) return 'x';
  if (disable) return value;
  return value.replace(/ /g, '');
});

Vue.filter('formatSize', (value) => {
  let fileSizeInBytes = value;
  if (!value && value !== 0) return null;

  let i = -1;
  const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    fileSizeInBytes /= 1024;
    i += 1;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
});

Vue.filter('prettyBytes', (bytes, decimals, kib) => {
  const newKib = kib || false;
  if (bytes === 0) return '0 Bytes';
  if (Number.isNaN(parseFloat(bytes)) && !Number.isFinite(bytes)) return 'Not an number';
  const k = newKib ? 1024 : 1000;
  const dm = decimals || 2;
  const sizes = newKib ? ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    : ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
});

Vue.filter('timeAgo', (value) => {
  const seconds = Math.floor((new Date() - value) / 1000);
  let interval = Math.floor(seconds / 31536000);

  if (interval > 1) return `${interval} years`;

  interval = Math.floor(seconds / 2592000);
  if (interval > 1) return `${interval} months`;

  interval = Math.floor(seconds / 86400);
  if (interval > 1) return `${interval} days`;

  interval = Math.floor(seconds / 3600);
  if (interval > 1) return `${interval} hours`;

  interval = Math.floor(seconds / 60);
  if (interval > 1) return `${interval} minutes`;

  return `${Math.floor(seconds)} seconds`;
});

Vue.filter('pastDate', (value) => {
  const newDate = new Date(new Date() - value);
  return newDate;
});

Vue.filter('addZero', (value) => (value < 10 ? `0${value}` : value));

Vue.filter('elapsedTime', (value) => {
  const seconds = Math.floor((new Date() - value) / 1000);
  let interval = Math.floor(seconds / 31536000);

  if (interval > 1) return `${interval} years`;

  interval = Math.floor(seconds / 2592000);
  if (interval > 1) return `${interval} months`;

  interval = Math.floor(seconds / 86400);
  if (interval > 1) return `${interval} days`;

  interval = Math.floor(seconds / 3600);
  if (interval > 1) return `${interval} hours`;

  interval = Math.floor(seconds / 60);
  if (interval > 1) return `${interval} minutes`;

  return `${Math.floor(seconds)} seconds`;
});
