import { API, graphqlOperation } from 'aws-amplify';
import Vue from 'vue';
import * as qlqueries from '../graphql/queries';
import * as qlmutations from '../graphql/mutations';

const state = {
  name: 'Stream',
  logs: {},
  interfaces: [],
  servers: [],
  loading: false,
};

const getters = {
};

const mutations = {
  setList: (state, payload) => {
    Vue.set(state, payload.property, payload.data.items);
  },

  setLoading: (state, payload) => {
    Vue.set(state, 'loading', payload);
  },
};

const actions = {
  async listMGWInterfaces({ commit }) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listMGWInterfaces,
          {
            limit: 1500,
          }),
      );
      const { data } = result;
      commit('setList', { data: data.listMGWInterfaces, property: 'interfaces' });
      return data.listMGWInterfaces;
    } catch (error) {
      const hasData = !!error.data && !!error.data.listMGWInterfaces;
      if (hasData) {
        const { data } = error;
        commit('setList', { data: data.listMGWInterfaces, property: 'interfaces' });
        this._vm.$message.error(
          { message: 'Oops something went wrong. Can\'t get some of the interfaces' },
        );
        return { error: true, message: error.errors };
      }
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t add interfaces' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async listMGWServers({ commit }) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listMGWServers,
          {
            limit: 1500,
          }),
      );
      const { data } = result;
      commit('setList', { data: data.listMGWServers, property: 'servers' });
      return data.listMGWServers;
    } catch (error) {
      const hasData = !!error.data && !!error.data.listMGWServers;
      if (hasData) {
        const { data } = error;
        commit('setList', { data: data.listMGWServers, property: 'servers' });
        this._vm.$message.error(
          { message: 'Oops something went wrong. Can\'t get some of the servers' },
        );
        return { error: true, message: error.errors };
      }
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t add servers' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async startMGWInterface({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.startMGWInterface,
          { interfaceId: payload },
        ),
      );
      const { data } = result;
      // commit('update', { data: data.startMGWInterface, property: 'source' });
      this._vm.$message({ message: 'Interface started' });
      return data.startMGWInterface;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async stopMGWInterface({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.stopMGWInterface,
          { interfaceId: payload },
        ),
      );
      const { data } = result;
      // commit('update', { data: data.stopMGWInterface, property: 'source' });
      this._vm.$message({ message: 'Interface stopped' });
      return data.stopMGWInterface;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async updateStreamDestination({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.updateStreamDestination,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('update', { data: data.updateStreamDestination, property: 'destination' });
      this._vm.$message({ message: 'Item updated' });
      return data.updateStreamDestination;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
