import axios from 'axios';
import config from '../config';

class MultiviewCinegyService {
  constructor(options) {
    this.config = config;
    this.options = options;
    this.url = config.url;
    this.apiUrl = config.apiUrl;
  }

  async getConfig() {
    console.log('CinegyMultiviewers');
    return axios.get(`${this.url}cinegymultiviewer`);
  }

  async getMultiviewers() {
    console.log('getMultiviewers');
    return axios.get(`${this.url}cinegymultiviewer`);
  }

  async changeLayout() {
    console.log('getMultiviewers');
    return axios.get(`${this.url}cinegymultiviewer`);
  }

  async multiviewerAction(command) {
    console.log('action');
    return axios.post(`${this.url}cinegymultiviewer`, command);
  }
}

export default new MultiviewCinegyService();
