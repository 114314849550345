import axios from 'axios';
import config from '../config';

class ServersService {
  constructor(options) {
    this.config = config;
    this.options = options;
    this.url = config.url;
    this.apiUrl = config.apiUrl;
  }

  async getServersStatus() {
    return axios.get(`${this.url}serverstatus`);
  }

  async sendCommand(command) {
    return axios.post(`${this.url}servercommand`, command);
  }

  async restartApplication(command) {
    return axios.post(`${this.url}WowzaStreamTarget`, command);
  }
}

export default new ServersService();
