<template>
  <div class="bly-tooltip" :class="classes">
    <slot></slot>
    <span class="invisible bly-tooltip-text bly-tooltip-top text-xs">{{text}}</span>
  </div>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    text: String,
  },

  computed: {
    classes() {
      return '';
    },
  },

  data() {
    return {
    };
  },
};
</script>

<style>
/* Tooltip container */
.bly-tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.bly-tooltip .bly-tooltip-text {
  visibility: hidden;
  max-width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 8px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.bly-tooltip:hover .bly-tooltip-text {
  visibility: visible;
}
.bly-tooltip-top {
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
}
</style>
