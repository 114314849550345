import { API, graphqlOperation } from 'aws-amplify';
import Vue from 'vue';
import * as qlqueries from '../graphql/queries';
import * as qlmutations from '../graphql/mutations';
// import * as qlsubscriptions from '../graphql/subscriptions';

const state = {
  name: 'Client',
  listClients: {},
  clients: [],
  listAppSettings: {},
  listFeatures: {},
  listClaims: {},
  listUsers: {},
  features: {},
  claims: {},
  appSettings: {},
  users: {},

  client: {},
  clientAccounts: {},
  account: {},

  clientId: null,
  loading: false,
};

const getters = {
};

const mutations = {
  setClient: (state, payload) => {
    Vue.set(state, 'client', payload);
    Vue.set(state, 'clientAccounts', payload.accounts.items);
  },

  setAccount: (state, payload) => {
    Vue.set(state, 'account', payload);
  },

  setClientList: (state, payload) => {
    Vue.set(state, 'listClients', payload);

    const clients = {};
    payload.items.forEach((item) => {
      clients[item.id] = item;
    });

    Vue.set(state, 'clients', clients);
  },

  setUserList: (state, payload) => {
    Vue.set(state, 'listUsers', payload);

    const users = {};
    payload.items.forEach((item) => {
      users[item.id] = item;
    });

    Vue.set(state, 'users', users);
  },

  setCurrentClientId: (state, payload) => {
    Vue.set(state, 'clientId', payload);
  },

  setAppSettings: (state, payload) => {
    Vue.set(state, 'listAppSettings', payload);
    console.log('listAppSettings', payload);
  },

  setFeatures: (state, payload) => {
    Vue.set(state, 'listFeatures', payload);
    console.log('listFeatures', payload);

    const features = {};
    payload.items.forEach((item) => {
      features[item.id] = item;
    });

    Vue.set(state, 'features', features);
  },

  setClaims: (state, payload) => {
    Vue.set(state, 'listClaims', payload);
    console.log('listClaims', payload);

    const claims = {};
    payload.items.forEach((item) => {
      claims[item.id] = item;
    });

    Vue.set(state, 'claims', claims);
  },

  setLoading: (state, payload) => {
    Vue.set(state, 'loading', payload);
  },

  appendClient: (state, payload) => {
    state.clients[payload.id] = payload;
  },

  addUser: (state, payload) => {
    state.users[payload.id] = payload;
  },

  addClaim: (state, payload) => {
    state.claims[payload.id] = payload;
  },

  addFeature: (state, payload) => {
    state.features[payload.id] = payload;
  },

  updateUser: (state, payload) => {
    state.users[payload.id] = payload;
  },

  updateClaim: (state, payload) => {
    state.claims[payload.id] = payload;
  },

  updateFeature: (state, payload) => {
    state.features[payload.id] = payload;
  },

  deleteClient: (state, id) => {
    Vue.delete(state.clients, id);
  },

  deleteUser: (state, id) => {
    Vue.delete(state.users, id);
  },

  deleteClaim: (state, id) => {
    Vue.delete(state.claims, id);
  },

  deleteFeature: (state, id) => {
    Vue.delete(state.features, id);
  },
};

const actions = {
  async fetchClientDetails({ commit }, payload) {
    const result = await API.graphql(
      graphqlOperation(qlqueries.getClient, {
        id: payload,
      }),
    );
    const { data } = result;
    const isFailure = !!result.errors;
    if (isFailure) return { error: true, message: result.errors };
    commit('setClient', data.getClient);

    return data.getClient;
  },

  async fetchClientList({ commit }) {
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listClients, {
          limit: 1500,
        }),
      );
      const { data } = result;
      commit('setClientList', data.listClients);
      return data.listClients;
    } catch (error) {
      const hasData = !!error.data && !!error.data.listClients;
      if (hasData) {
        const { data } = error;
        commit('setClientList', data.listClients);
        console.debug('Oops something went wrong. Can\'t get some of the booking info', data.eventsByStartTime);
        const errorTypes = [...new Set(error.errors.map((el) => el.errorType))];
        const isUnauthorizedOnly = errorTypes.length === 1 && errorTypes.indexOf('Unauthorized') !== -1;
        if (!isUnauthorizedOnly) {
          // this._vm.$message.error(
          //   { message: 'Oops something went wrong.  },
          // );
        }
        return { error: true, message: error.errors };
      }
      this._vm.$message.error({ message: 'Oops, can\'t get client list.' });
      return { error: true, message: error.errors };
    }
  },

  async fetchClientUsers({ commit }) {
    const result = await API.graphql(
      graphqlOperation(qlqueries.listUsers),
    );
    const { data } = result;
    const isFailure = !!result.errors;
    if (isFailure) return { error: true, message: result.errors };
    commit('setUserList', data.listUsers);

    return data.listUsers;
  },

  async fetchAppSettings({ commit }) {
    const result = await API.graphql(
      graphqlOperation(qlqueries.listAppSettings),
    );
    const { data } = result;
    const isFailure = !!result.errors;
    if (isFailure) return { error: true, message: result.errors };
    commit('setAppSettings', data.listAppSettings);

    return data.listAppSettings;
  },

  async fetchFeatureList({ commit }) {
    const result = await API.graphql(
      graphqlOperation(qlqueries.listFeatures),
    );
    const { data } = result;
    const isFailure = !!result.errors;
    if (isFailure) return { error: true, message: result.errors };
    commit('setFeatures', data.listFeatures);

    return data.listFeatures;
  },

  async fetchClaimList({ commit }) {
    const result = await API.graphql(
      graphqlOperation(qlqueries.listClaims),
    );
    const { data } = result;
    const isFailure = !!result.errors;
    if (isFailure) return { error: true, message: result.errors };
    commit('setClaims', data.listClaims);

    return data.listClaims;
  },

  async createClient({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createClient,
          { input: payload },
        ),
      );
      commit('setLoading', false);
      const { data } = result;
      return data.createClient;
    } catch (error) {
      commit('setLoading', false);
      return { error: true, message: error.errors[0] };
    }
  },

  async updateClient({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.updateClient,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('updateClient', data.updateClient);
      return data.updateClient;
    } catch (error) {
      return { error: true, message: error.errors };
    }
  },

  async deleteClient({ commit }, payload) {
    const result = await API.graphql(
      graphqlOperation(
        qlmutations.deleteClient,
        { input: { id: payload } },
      ),
    );
    const isFailure = !!result.errors;
    commit('setLoading', false);
    if (isFailure) return { error: true, message: result.errors };
    const { data } = result;
    commit('deleteClient', payload);
    return data.deleteClient;
  },

  async createClaim({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createClaim,
          { input: payload },
        ),
      );

      const { data } = result;
      commit('addClaim', data.createClaim);
      return data.createClaim;
    } catch (error) {
      commit('setLoading', false);
      return { error: true, message: error.errors[0] };
    }
  },

  async createFeature({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createFeature,
          { input: payload },
        ),
      );
      commit('setLoading', false);
      const { data } = result;
      return data.createFeature;
    } catch (error) {
      commit('setLoading', false);
      return { error: true, message: error.errors[0] };
    }
  },

  async deleteFeature({ commit }, payload) {
    const result = await API.graphql(
      graphqlOperation(
        qlmutations.deleteFeature,
        { input: { id: payload } },
      ),
    );
    const isFailure = !!result.errors;
    commit('setLoading', false);
    if (isFailure) return { error: true, message: result.errors };
    const { data } = result;
    commit('deleteFeature', payload);
    return data.deleteFeature;
  },

  async deleteClaim({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteClaim,
          { input: { id: payload } },
        ),
      );
      const { data } = result;
      commit('deleteClaim', payload);
      return data.deleteClaim;
    } catch (error) {
      return { error: true, message: error.errors };
    }
  },

  async updateClaim({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.updateClaim,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('updateClaim', data.updateClaim);
      return data.updateClaim;
    } catch (error) {
      return { error: true, message: error.errors };
    }
  },

  async updateFeature({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.updateFeature,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('updateFeature', data.updateFeature);
      return data.updateFeature;
    } catch (error) {
      return { error: true, message: error.errors };
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
