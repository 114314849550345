/* eslint-disable */
// this is an auto generated file. This will be overwritten
export const deleteClient = `mutation DeleteClient($input: DeleteClientInput!) {
  deleteClient(input: $input) {
    id
  }
}
`;

export const createClient = `mutation CreateClient($input: CreateClientInput!) {
  createClient(input: $input) {
    id
    code
    name
    description
    logo
    website
    email
    address
    phoneno
    accounts {
      items {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        lastUpdated
        version
      }
      nextToken
    }
    features {
      items {
        id
        code
        name
        description
      }
      nextToken
    }
    claimsCanAccess
    claimsCanEdit
    version
  }
}
`;

export const createAccount = `mutation CreateAccount($input: CreateAccountInput!) {
  createAccount(input: $input) {
    id
    code
    name
    description
    logo
    website
    email
    address
    phoneno
    claims {
      items {
        id
        code
        name
        description
      }
      nextToken
    }
    features {
      items {
        id
        code
        name
        description
      }
      nextToken
    }
    claimsCanAccess
    claimsCanEdit
    lastUpdated
    version
  }
}
`;
export const updateAccount = `mutation UpdateAccount($input: UpdateAccountInput!) {
  updateAccount(input: $input) {
    id
    code
    name
    description
    logo
    website
    email
    address
    phoneno
    claims {
      items {
        id
        code
        name
        description
      }
      nextToken
    }
    features {
      items {
        id
        code
        name
        description
      }
      nextToken
    }
    claimsCanAccess
    claimsCanEdit
    lastUpdated
  }
}
`;
export const deleteAccount = `mutation DeleteAccount($input: DeleteAccountInput!) {
  deleteAccount(input: $input) {
    id
    code
    name
    description
    logo
    website
    email
    address
    phoneno
    claims {
      items {
        id
        code
        name
        description
      }
      nextToken
    }
    features {
      items {
        id
        code
        name
        description
      }
      nextToken
    }
    claimsCanAccess
    claimsCanEdit
    lastUpdated
    version
  }
}
`;