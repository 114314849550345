import { Auth } from 'aws-amplify';
import axios from 'axios';

import config from '../config';

class AuthenticationService {
  constructor(options) {
    this.options = options;

    this.config = config;

    this.user = null;
    this.token = null;
    this.userEmail = '';
    this.userGroups = [];
    this.userPermissions = {};
    this.userFeatures = null;
  }

  setUser(cognitoUser) {
    this.user = cognitoUser;
    this.userEmail = cognitoUser.attributes.email;
    const session = cognitoUser.signInUserSession;
    this.token = session.accessToken.jwtToken;
    axios.defaults.headers.common.Authorization = `Bearer ${this.token}`;
    const hasGroups = !!session.accessToken.payload['cognito:groups'];
    this.userGroups = hasGroups ? session.accessToken.payload['cognito:groups'] : [];
    this.userFeatures = session.idToken.payload.features;
    this.processGroups();
  }

  setUserSession(cognitoUserSession) {
    this.token = cognitoUserSession.accessToken.jwtToken;
    axios.defaults.headers.common.Authorization = `Bearer ${this.token}`;
    const hasGroups = !!cognitoUserSession.accessToken.payload['cognito:groups'];
    this.userGroups = hasGroups ? cognitoUserSession.accessToken.payload['cognito:groups'] : [];
    this.userFeatures = cognitoUserSession.idToken.payload.features;
    this.processGroups();
  }

  processGroups() {
    this.userPermissions = {};
    const perms = {};
    this.userGroups.forEach((group) => {
      const isSysAdmin = group === 'SysAdmin';
      const isDevAdmin = group === 'DevAdmin';
      const isUser = group === 'User';
      const isSystem = group === 'SYSTEM';

      if (isSysAdmin) {
        this.userPermissions.isSysAdmin = isSysAdmin;
        perms.isSysAdmin = true;
        return;
      }
      if (isDevAdmin) {
        this.userPermissions.isDevAdmin = isDevAdmin;
        perms.isDevAdmin = true;
        return;
      }
      if (isUser) {
        this.userPermissions.isUser = isUser;
        perms.isUser = true;
        return;
      }
      if (isSystem) {
        this.userPermissions.isSystem = isSystem;
        perms.isSystem = true;
        return;
      }

      const permissions = group.split('.');
      const groupLvl = permissions.length; // group has no claims
      const isAccountPermission = groupLvl === 3;
      const isClientPermission = groupLvl === 2;
      // const isFeaturePermission = groupLvl === 4;

      const [p, a, c] = permissions;
      if (!perms[c]) perms[c] = {};
      if (!perms[c][a]) perms[c][a] = {};
      perms[c][a][p] = true;

      const client = permissions[groupLvl - 1];
      const doesClientObjectExist = !!this.userPermissions[client];

      if (!doesClientObjectExist) this.userPermissions[client] = { permissions: [], accounts: {} };
      if (isClientPermission) {
        this.userPermissions[client].permissions.push(permissions[groupLvl - 2]);
      }
      if (isAccountPermission) {
        const code = permissions[groupLvl - 2];
        const doesAccountObjectExist = !!this.userPermissions[client].accounts[code];

        if (!doesAccountObjectExist) {
          this.userPermissions[client].accounts[code] = {
            permissions: [], features: {},
          };
        }
        this.userPermissions[client].accounts[code].permissions.push(permissions[groupLvl - 3]);
      }
      //  this.userPermissions[client].accounts[permissions[groupLvl - 2]]
    });
    this.userPermissions.all = perms;
  }

  setToken(token) {
    this.token = token;
    axios.defaults.headers.common.Authorization = `Bearer ${this.token}`;
  }

  checkSession() {
    Auth.currentSession()
      .then((data) => {
        this.setToken(data.accessToken.jwtToken);
      })
      .catch((err) => err);
  }

  isAunthicated() {
    return !!this.user;
  }
}

export default new AuthenticationService();
