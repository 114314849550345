import { API, graphqlOperation } from 'aws-amplify';
import Vue from 'vue';
import * as qlmutations from '../graphql/mutations';
import * as qlqueries from '../graphql/queries';

const state = {
  name: 'AppSettings',
  settings: {},
  loading: false,
};

const getters = {
};

const mutations = {
  setSettings: (state, payload) => {
    payload.forEach((element) => {
      let { value } = element;
      if (element.id === 'symbolRate') {
        value = value.split(',')
          .map(Number)
          .sort((a, b) => {
            if (a < b) { return -1; }
            if (a > b) { return 1; }
            return 0;
          });
      }
      Vue.set(state.settings, element.key, value);
    });
  },

  setLoading: (state, payload) => {
    Vue.set(state, 'loading', payload);
  },
};

const actions = {
  async listAppSettings({ commit }) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listAppSettings,
          {
            limit: 1500,
          }),
      );
      const { items } = result.data.listAppSettings;
      commit('setSettings', items);

      commit('setLoading', false);
      return items;
    } catch (error) {
      commit('setLoading', false);
      return { error: true, message: error.errors };
    }
  },
  async updateAppSetting({ commit }, payload) {
    try {
      await API.graphql(
        graphqlOperation(
          qlmutations.updateAppSetting,
          { input: payload },
        ),
      );
      const result = await API.graphql(
        graphqlOperation(qlqueries.listAppSettings,
          {
            limit: 1500,
          }),
      );
      const { items } = result.data.listAppSettings;
      commit('setSettings', items);
      return items;
    } catch (error) {
      return { error: true, message: error.errors };
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
