import { API, graphqlOperation } from 'aws-amplify';
import Vue from 'vue';
import * as qlqueries from '../graphql/queries';
// import * as qlmutations from '../graphql/mutations';
// import * as qlsubscriptions from '../graphql/subscriptions';

const state = {
  name: 'App',
  listEvents: [],
  events: [],

  viewsSettings: {
  },
  config: {

  },
  awsExports: {},
};

const getters = {
};

const mutations = {
};

const actions = {
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
