import { API, graphqlOperation } from 'aws-amplify';
import Vue from 'vue';
import * as qlqueries from '../graphql/queries';
import * as qlmutations from '../graphql/mutations';

const state = {
  name: 'Input',
  logs: {},
  list: [],
  pictureFormats: [],
  loading: false,
};

const getters = {
};

const mutations = {
  setList: (state, payload) => {
    Vue.set(state, 'list', payload);
  },
  setPictureFormatList: (state, payload) => {
    Vue.set(state, 'pictureFormats', payload);
  },

  setLoading: (state, payload) => {
    Vue.set(state, 'loading', payload);
  },
};

const actions = {
  async fetchPictureFormats({ commit }) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listPictureFormats,
          {
            limit: 1500,
          }),
      );
      const { data } = result;
      commit('setPictureFormatList', data.listPictureFormats.items);
      return data.listPictureFormats;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t add inputs' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
