import axios from 'axios';
import config from '../config';

class MultiviewService {
  constructor(options) {
    this.config = config;
    this.options = options;
    this.url = config.url;
    this.apiUrl = config.apiUrl;
  }

  async getConfig() {
    console.log('OTTMultiviewers');
    return axios.get(`${this.url}OTTMultiviewers`);
  }
}

export default new MultiviewService();
