import Vue from 'vue';
import Vuex from 'vuex';

import servers from './store/Servers';
import router from './store/Router';
import multiview from './store/Multiview';
import multiviewCinegy from './store/MultiviewCinegy';
import userAccount from './store/UserAccount';
import admin from './store/Admin';
import client from './store/Client';
import schedule from './store/Schedule';
import audit from './store/Audit';
import permissions from './store/Permissions';
import stream from './store/Stream';
import app from './store/App';
import appSettings from './store/AppSettings';
import input from './store/Input';
import mgw from './store/Mgw';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {

  },
  actions: {

  },
  modules: {
    servers,
    multiview,
    userAccount,
    admin,
    client,
    schedule,
    app,
    appSettings,
    multiviewCinegy,
    router,
    permissions,
    audit,
    stream,
    input,
    mgw,
  },
});
