<template>
  <div class="bly-status-tag" :class="classes">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    type: String,
    stroke: Boolean,
    strokeColor: String,
    size: String,
  },

  computed: {
    classes() {
      return `${this.type} ${this.size}`;
    },
  },

  data() {
    return {
    };
  },
};
</script>

<style scoped lang="scss" src="./StatusTag.scss"></style>
