<template>
  <div id="router" class="router">
    <div></div>

    <div class="container-with-sidebar">
      <div class="sidebar"></div>
      <div>
        <splitpanes class="default-theme"
          :push-other-panes="false" :horizontal="isMobileOnly ? false : true">
          <pane min-size="20" max-size="80">
            <div class="pane-wrapper">
              <div class="pane-title blue-light">
                <i class="bi bly-output"></i>
                Sources
                <span class="loading" v-show="loadings.sources">
                  <i class="bi bly-loading red bi-is-spinning"></i>
                </span>
                <div class="title-actions">
                  <el-select
                    size="small"
                    placeholder="Select group"
                    clearable
                    @change="sourceGroupChanged"
                    v-model="selectedSourceGroup" value-key="id">
                    <el-option
                      v-for="group in sourceGroups"
                      :key="group.id"
                      :label="group.name"
                      :value="group">
                    </el-option>
                  </el-select>
                  <el-divider direction="vertical" v-if="!isMobileOnly"></el-divider>
                  <div class="search" v-if="!isMobileOnly">
                    <el-input
                      placeholder="Search sources..."
                      size="small"
                      clearable
                      @change="searchSourceChanged"
                      v-model="searchSource">
                      <i slot="suffix" class="el-input__icon el-icon-search"></i>
                    </el-input>
                  </div>
                </div>
              </div>
              <div v-bar>
                <div class="pane-bar-wrapper sources">
                  <div class="item-group">
                    <!--<el-divider content-position="left">{{key}}</el-divider>-->
                    <div class="item-wrapper"
                      v-for="(source, index) in filteredSources"
                      :key="source.id">
                      <div class="item"
                        @click="routSource(source, selectedDestination)"
                        :class="{
                          active: selectedSource === source,
                          connected: activeDestinationSource.id === source.id,
                          disabled: !activeDestinationSource
                            && (activeDestinationSource.originRouterId
                            !== source.originRouterId),
                          'show-take': showTakeId === source.id,
                          'show-loading': loadings.source === source.id,
                        }">
                        <div class="changing-route">
                          <i class="bi bly-loading red bi-is-spinning"></i>
                        </div>
                        <div class="take-action"
                          @click="sendRouteCommand(source, selectedDestination)">
                          TAKE
                        </div>
                        <div class="item-avatar"
                          :title="source.originRouterId">
                          <el-avatar shape="square" size="medium"
                          :style="{ background: originRouterColor(source.originRouterId),}"
                          >{{index + 1}}</el-avatar>
                        </div>
                        <div>
                          <div class="item-name">
                            {{source.name}}
                          </div>
                          <div class="item-desc">
                            <div v-if="source.routedDestinations.items">
                              <div
                                class="not-connected"
                                v-if="source.routedDestinations.items.length === 0">
                                not routed
                              </div>
                            </div>
                            <div v-if="source.routedDestinations.items.length > 0">
                              <el-tooltip>
                                <div slot="content">
                                  <div
                                    class="routed"
                                    v-for="d in source.routedDestinations.items"
                                    :key="d.id"
                                    >{{d.name}}</div>
                                </div>
                                <div>
                                  <span class="routed">
                                    {{source.routedDestinations.items[0].name}}
                                  </span>
                                  <span
                                    class="routed"
                                    v-show="source.routedDestinations.items.length > 0"
                                    >... ({{source.routedDestinations.items.length}})
                                  </span>
                                </div>
                              </el-tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </pane>
          <pane :min-size="paneMinimalSize" :size="paneMinimalSize" class="pane-dest">
            <div class="pane-wrapper">
              <div class="pane-title green">
                <i class="bi bly-input"></i>
                Destinations
                <span class="loading" v-show="loadings.destinations">
                  <i class="bi bly-loading red bi-is-spinning"></i>
                </span>
                <div class="title-actions">
                  <el-select
                    size="small"
                    placeholder="Select group"
                    clearable
                    @change="destGroupChanged"
                    v-model="selectedDestGroup" value-key="id">
                    <el-option
                      v-for="group in destinationGroups"
                      :key="group.id"
                      :label="group.name"
                      :value="group">
                    </el-option>
                  </el-select>
                  <el-divider direction="vertical" v-if="!isMobileOnly"></el-divider>
                  <div class="search" v-if="!isMobileOnly">
                    <el-input
                      placeholder="Search destination..."
                      size="small"
                      clearable
                      @change="searchDestinationChanged"
                      v-model="searchDestination">
                      <i slot="suffix" class="el-input__icon el-icon-search"></i>
                    </el-input>
                  </div>
                </div>
              </div>
              <div v-bar>
                <div class="pane-bar-wrapper destinations">
                  <div class="item-group">
                    <!--<el-divider content-position="left">{{key}}</el-divider>-->
                    <div class="item-wrapper"
                      v-for="(dest, index) in filteredDestinations"
                      :key="dest.id">
                      <div class="item destination"
                        @click="toggleDest(dest)"
                        :class="{
                          active: selectedDestinationId === dest.id,
                          connected: activeSourceDestinations.indexOf(dest.id) !== -1,
                          disabled: !activeSourceDestinations
                            && (activeSourceDestinations.originRouterId
                            !== dest.originRouterId),
                        }"
                        >
                        <div class="item-avatar"
                          :title="dest.originRouterId"
                          >
                          <el-avatar shape="square" size="medium"
                          :style="{ background: originRouterColor(dest.originRouterId),}">{{index + 1}}</el-avatar>
                        </div>
                        <div>
                          <div class="item-name">
                            {{dest.name}}
                          </div>
                          <div class="item-desc">
                            <span v-if="dest.routedSource">{{dest.routedSource.name}}</span>
                            <span class="text-red-500 font-bold" v-if="!dest.routedSource">ERROR</span>
                          </div>
                        </div>
                        <div class="item-actions"
                          v-show="false" v-if="isAdmin || acl.ro">
                          <el-tooltip
                            v-if="selectedSource
                              && activeSourceDestinations.indexOf(dest.id) === -1">
                            <div slot="content">
                              Route <strong class="blue-light">{{selectedSource.name}}</strong> here
                            </div>
                            <i class="bi bly-route icon-btn blue-light"
                              @click="sendRouteCommand(selectedSource, dest)"></i>
                          </el-tooltip>
                          <el-tooltip
                            v-show="false"
                            v-if="selectedSource
                              && activeSourceDestinations.indexOf(dest.id) !== -1">
                            <div slot="content">
                              Unassign <strong class="blue-light"> {{selectedSource.name}}</strong>
                            </div>
                            <i class="bi bly-close icon-btn red"
                              @click="sendRouteCommand(selectedSource, dest)"></i>
                          </el-tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </pane>
        </splitpanes>
      </div>
    </div>
    <!-- -------------------------------------------------------------------------------------- -->
    <!-- DILOGS      -------------------------------------------------------------------------- -->
    <!-- -------------------------------------------------------------------------------------- -->

    <!-- -------------------------------------------------------------------------------------- -->
    <!-- NAV OPTIONS -------------------------------------------------------------------------- -->
    <!-- -------------------------------------------------------------------------------------- -->
    <div class="config mv-config">
      <!--<el-checkbox v-model="useTake"
        border
        label="Use take">
      </el-checkbox>
      -->
      <el-tooltip content="Help" placement="right">
        <el-popover
          placement="top-start"
          width="300"
          trigger="hover">
          <el-alert :closable="false">
            Current state refreshes every 15 seconds
          </el-alert>
          <h3><strong>Keybindings</strong></h3>
          <div>
            <div>
              Hold <el-tag size="small">SHIFT</el-tag> to disable scrolling.
            </div>
          </div>
          <i slot="reference" class="bi bly-question icon-btn"></i>
        </el-popover>
      </el-tooltip>
      <!--
      <el-select v-model="seletedRouterGroup" value-key="id" v-if="!isMobileOnly">
        <el-option
          v-for="group in routerGroups.items"
          :key="group.id"
          :label="group.name"
          :value="group">
        </el-option>
      </el-select>
      -->
    </div>
  </div>
</template>

<script>
// import Vue from 'vue';
import { mapState, mapActions, mapMutations } from 'vuex';
import { isMobileOnly } from 'mobile-device-detect';
import _ from 'lodash';
import { Splitpanes, Pane } from 'splitpanes';
import ColorGenerator from '@/services/ColorGenerator';
import 'splitpanes/dist/splitpanes.css';

export default {
  name: 'router',
  components: { Splitpanes, Pane },
  data() {
    return {
      isMobileOnly,
      activeSource: null,
      loadingInfo: null,
      loadingRoute: null,
      hoveredDest: null,
      hoveredSource: null,
      hoverDest: null,
      useTake: true,
      showTakeId: null,

      seletedRouterGroup: null,
      selectedDestGroup: null,
      selectedSourceGroup: null,

      // routing
      selectedSource: null,
      selectedDestination: null,

      // dialogs
      createRouterDialog: false,
      createRouterSourceDialog: false,
      createRouterDestinationDialog: false,
      createRouterGroupDialog: false,

      // forms
      routerForm: {},
      routerGroupForm: {},
      routerSourceForm: {},
      routerDestinationForm: {},

      keys: {},
      searchDestination: '',
      searchSource: '',
    };
  },

  created() {
    this.init();
    this.initInterval();
    window.addEventListener('keyup', this.keyUp);
    window.addEventListener('keydown', this.keyDown);
  },

  beforeDestroy() {
    window.clearInterval(this.interval);
    this.interval = null;
    window.removeEventListener('keyup', this.keyUp);
    window.removeEventListener('keydown', this.keyDown);
  },

  computed: {
    ...mapState({
      aclEx: (state) => state.userAccount.userPermissions,
      acl: (state) => state.userAccount.aclCurrent,
      isAdmin: (state) => state.userAccount.isAdmin,
      client: (state) => state.userAccount.client,
      account: (state) => state.userAccount.account,
      clients: (state) => state.userAccount.clients,
      siteList: (state) => state.router.siteList,
      selection: (state) => state.router.selectedSite,
      loadings: (state) => state.router.loadings,

      routers: (state) => state.router.routers,
      routerSources: (state) => state.router.routerSources,
      routerDestinations: (state) => state.router.routerDestinations,
      routerGroups: (state) => state.router.routerGroups,

      streamInformation: (state) => state.router.streamInformation,
    }),

    paneMinimalSize() {
      return this.isMobileOnly ? 50 : 25;
    },

    activeSourceDestinations() {
      if (this.hoveredSource) {
        return this.hoveredSource.routedDestinations.items.map((dest) => dest.id);
      }
      if (this.selectedSource) {
        return this.selectedSource.routedDestinations.items.map((dest) => dest.id);
      }
      return [];
    },

    activeDestinationSource() {
      if (this.hoveredDest) {
        return this.hoveredDest.routedSource;
      }
      if (this.selectedDestination) {
        return this.selectedDestination.routedSource;
      }
      return [];
    },

    filteredSources() {
      const filterBySearchString = this.searchSource.length > 2;
      if (filterBySearchString) {
        const seachString = this.searchSource.toLowerCase();
        return this.routerSources
          .filter((item) => item.name.toLowerCase().indexOf(seachString) !== -1)
          .sort((a, b) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
          });
      }
      if (!this.selectedSourceGroup) {
        return this.routerSources
          .slice(0).sort((a, b) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
          });
      }
      return this.routerSources
        .filter((item) => {
          const groupIds = item.routerGroups.items.map((group) => group.routerGroup.id);
          return groupIds.indexOf(this.selectedSourceGroup.id) !== -1;
        })
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
    },

    filteredDestinations() {
      const filterBySearchString = this.searchDestination.length > 2;
      if (filterBySearchString) {
        const seachString = this.searchDestination.toLowerCase();
        return this.routerDestinations
          .filter((item) => item.name.toLowerCase().indexOf(seachString) !== -1)
          .sort((a, b) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
          });
      }
      if (!this.selectedDestGroup) {
        return this.routerDestinations
          .slice(0).sort((a, b) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
          });
      }
      return this.routerDestinations
        .filter((item) => {
          const groupIds = item.routerGroups.items.map((group) => group.routerGroup.id);
          return groupIds.indexOf(this.selectedDestGroup.id) !== -1;
        })
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
    },

    sourceGroups() {
      if (!this.routerGroups) return [];
      return this.routerGroups.items.filter((item) => item.sources.items.length > 0)
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
    },

    destinationGroups() {
      if (!this.routerGroups) return [];
      return this.routerGroups.items.filter((item) => item.destinations.items.length > 0)
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
    },

    groupedSources() {
      const mappedSources = this.routerSources.map((item) => ({
        source: item,
        groups: item.routerGroups.items.map((group) => group.routerGroup.name),
      }));
      return _.groupBy(mappedSources, (item) => item.groups);
    },

    groupedDestinations() {
      const mappedDest = this.routerDestinations.map((item) => ({
        dest: item,
        groups: item.routerGroups.items.map((group) => group.routerGroup.name),
      }));
      return _.groupBy(mappedDest, (item) => item.groups);
    },

    selectedDestinationId() {
      return this.selectedDestination ? this.selectedDestination.id : null;
    },

    accountId() {
      return this.account.code;
    },

    clientId() {
      return this.client.code;
    },

    claimsCanEdit() {
      return [
        `ro.${this.accountId}.${this.clientId}`,
      ];
    },

    claimsCanAccess() {
      return [
        `rv.${this.accountId}.${this.clientId}`,
        `ro.${this.accountId}.${this.clientId}`,
      ];
    },

    originRouterColor() {
      return (id) => ColorGenerator.generateNewColor(id);
    },
  },

  watch: {
    seletedRouterGroup() {
      this.selectedSource = null;
      this.selectedDestination = null;
    },
  },

  methods: {
    ...mapActions({
      fetchRouterGroups: 'router/fetchRouterGroups',
      fetchRouterDestinations: 'router/fetchRouterDestinations',
      fetchRouterSources: 'router/fetchRouterSources',
      fetchRouters: 'router/fetchRouters',

      fetchRouter: 'router/fetchRouter',
      fetchRouterDestination: 'router/fetchRouterDestination',
      fetchRouterGroup: 'router/fetchRouterGroup',
      fetchRouterSource: 'router/fetchRouterSource',

      deleteRouter: 'router/deleteRouter',
      deleteRouterDestination: 'router/deleteRouterDestination',
      deleteRouterGroup: 'router/deleteRouterGroup',
      deleteRouterSource: 'router/deleteRouterSource',

      updateRouter: 'router/updateRouter',
      updateRouterDestination: 'router/updateRouterDestination',
      updateRouterGroup: 'router/updateRouterGroup',
      updateRouterSource: 'router/updateRouterSource',

      createRouter: 'router/createRouter',
      createRouterDestination: 'router/createRouterDestination',
      createRouterGroup: 'router/createRouterGroup',
      createRouterSource: 'router/createRouterSource',

      changeRoute: 'router/changeRoute',
    }),
    ...mapMutations({
      setSelectedSite: 'router/setSelectedSite',
    }),

    keyDown(e) {
      this.keys[e.key] = true;
    },

    keyUp(e) {
      this.keys[e.key] = false;
    },

    showTake(source) {
      this.showTakeId = source.id;
    },

    toggleSource(source) {
      if (this.selectedSource === source) {
        this.selectedSource = null;
        return;
      }
      this.selectedSource = source;
      // this.selectedDestination = null;
      if (this.keys.Shift) return;

      this.$forceUpdate();
      setTimeout(() => {
        document.querySelector('.destinations').scroll({
          top: document.querySelector('.destinations .connected').offsetTop - 10,
          behavior: 'smooth',
        });
      }, 100);
    },

    toggleDest(dest) {
      this.showTakeId = null;
      if (this.selectedDestinationId === dest.id) {
        this.selectedDestination = null;
        return;
      }
      this.selectedDestination = dest;
      this.selectedSource = null;
      this.$forceUpdate();

      if (this.keys.Shift) return;
      setTimeout(() => {
        document.querySelector('.sources').scroll({
          top: document.querySelector('.sources .connected').offsetTop - 10,
          behavior: 'smooth',
        });
      }, 100);
    },

    initInterval() {
      this.interval = setInterval(async () => {
        await this.fetchRouterDestinations();
        if (this.selectedDestination) {
          this.filteredDestinations.forEach((dest) => {
            if (dest.id !== this.selectedDestination.id) return;
            this.selectedDestination.routedSource = dest.routedSource;
          });
        }
        await this.fetchRouterSources();
        this.$forceUpdate();
      }, 15000);
    },

    async init() {
      this.searchDestination = localStorage.getItem('router_searchDestination') || '';
      this.searchSource = localStorage.getItem('router_searchSource') || '';

      this.selectedSourceGroup = JSON.parse(localStorage.getItem('router_selectedSourceGroup')) || null;
      this.selectedDestGroup = JSON.parse(localStorage.getItem('router_selectedDestGroup')) || null;
      try {
        await this.fetchRouters();
        await this.fetchRouterDestinations();
        await this.fetchRouterSources();
        await this.fetchRouterGroups();
      } catch (error) {
        this.$message({
          showClose: true,
          message: 'Oops, something went wrong.',
          type: 'error',
        });
      }
    },

    routSource(source, destination) {
      const hasPermission = this.isAdmin || this.acl.ro;
      if (!hasPermission) return;
      this.showTakeId = null;
      if (!destination) return;
      if (this.useTake) {
        this.showTakeId = source.id;
        return;
      }

      this.sendRouteCommand(source, destination);
    },

    async sendRouteCommand(source, dest) {
      const command = {
        clientId: this.clientId,
        accountId: this.accountId,
        sourceId: source.id,
        destinationIds: [dest.id],
        // eventId: ID
        claimsCanAccess: this.claimsCanAccess,
        claimsCanEdit: this.claimsCanEdit,
      };
      await this.changeRoute(command);
      this.showTakeId = null;
    },

    // OLD STUFF
    async deleteRouterPrompt(router) {
      const isConfirm = await this.showPrompt(`Are you sure you want to remove <b>${router.name}</b>?`);
      if (!isConfirm) return false;
      await this.deleteRouter(router);
      return null;
    },
    async deleteRouterSourcePrompt(router) {
      const isConfirm = await this.showPrompt(`Are you sure you want to remove <b>${router.name}</b>?`);
      if (!isConfirm) return false;
      await this.deleteRouterSource(router.id);
      return null;
    },
    async deleteRouterDestinationPrompt(router) {
      const isConfirm = await this.showPrompt(`Are you sure you want to remove <b>${router.name}</b>?`);
      if (!isConfirm) return false;
      await this.deleteRouterDestination(router.id);
      return null;
    },

    async showPrompt(confirmationMessage) {
      const confirmation = await this.$confirm(confirmationMessage, 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
        center: true,
        dangerouslyUseHTMLString: true,
      });
      return confirmation === 'confirm';
    },

    searchDestinationChanged() {
      localStorage.setItem('router_searchDestination', this.searchDestination);
    },
    searchSourceChanged() {
      localStorage.setItem('router_searchSource', this.searchSource);
    },
    sourceGroupChanged() {
      localStorage.setItem('router_selectedSourceGroup', JSON.stringify(this.selectedSourceGroup));
    },
    destGroupChanged() {
      localStorage.setItem('router_selectedDestGroup', JSON.stringify(this.selectedDestGroup));
    },
  },
};
</script>

<style lang="scss">
@import '../assets/var.scss';
.green {
  color: $green;
}
.green-dark {
  color: $green-dark;
}
.blue-light {
  color: $blue-light;
}
#router {
  &.router-vh {
    .container-with-sidebar {
      .item-wrapper {
        .item {
          grid-template-columns: 1fr;
          grid-template-rows: 91px auto;
          height: 137px;
          width: 150px;
          text-align: center;
          align-items: start;
          grid-gap: 0;
          .item-name {
            margin: 0 10px;
            font-size: 13px;
          }
          .item-avatar {
            padding: 0px;
            .el-avatar {
              margin: 0 auto;
              width: 120px;
              height: 70px;
              line-height: 75px;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  .el-divider {
    // background-color: transparent;
    .el-divider__text {
      position: absolute;
      background-color: $dark;
    }
  }
  .container-with-sidebar {
    display: grid;
    grid-template-columns: auto;
    .pane-wrapper {
      height: 100%;
      .vb {
        height: 100%;
      }
    }
    .pane-bar-wrapper {
      // height: calc(100vh - 75px) !important;
      padding-left: 2px;
      padding-top: 2px;
      padding-bottom: 50px;
    }
    .pane-title {
      font-size: 14px;
      text-transform: uppercase;
      margin: 10px 0px;
      font-weight: 500;
      letter-spacing: 0.3px;
      position: relative;
      .bi {
        position: relative;
        top: 1px;
        margin-right: 8px;
      }
      .title-actions {
        position: absolute;
        right: 20px;
        top: -7px;
        input {
          background: transparent;
          border-color: transparent;
          color: $color;
        }
        .search {
          display: inline-block;
        }
      }
    }
    .item-wrapper {
      display: inline-block;
      margin-bottom: 12px;
      margin-right:12px;
      .item-actions {
        position: absolute;
        right: 0;
        top: 0;
      }
      .item {
        display: grid;
        grid-template-columns: 44px auto;
        grid-gap: 8px;
        width: 252px;
        height: 52px;
        align-items: center;
        overflow: hidden;
        background: $dark-light;
        border-radius: 2px;
        position: relative;
        &.active, &:hover {
          outline: 2px solid $blue-light;
        }
        &.active {
          outline: 2px solid $color-primary !important;
          .item-name {
            color: $color-primary;
          }
          .el-avatar--square {
            background: $color-primary;
          }
        }
        .take-action, .changing-route {
          position: absolute;
          background: $red;
          top: 5px;
          bottom: 5px;
          grid-column: 1 / span 2;
          right: 5px;
          left: 5px;
          max-width: 300px !important;
          color: #fff;
          line-height: 45px;
          text-align: center;
          display: none;
        }
        .changing-route {
          background: rgba(36, 42, 51, 0.78);
        }
        &.show-take {
          .take-action {
            display: block;
          }
        }
        &.show-loading {
          .changing-route {
            display: block;
            z-index: 999;
          }
        }
        &.destination {
          &.active, &:hover {
            outline: 2px solid $green;
          }
          &.connected {
            outline: 2px solid $blue-light;
            .item-name {
              color: $color-primary;
            }
            .el-avatar--square {
              background: $color-primary;
            }
          }
        }
        &.connected-dest {
          outline: 2px solid $color-primary;
        }
        &.connected {
          outline: 2px solid $color-primary;
          .item-name {
            color: $color-primary;
          }
          .el-avatar--square {
            background: $color-primary;
          }
        }
        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
        .el-avatar--square {
          border-radius: 2px;
        }
        div {
          max-width: 200px;
          text-overflow: ellipsis;
          overflow: hidden;
          cursor: pointer;
        }
        &-avatar {
          align-content: center;
          display: grid;
          padding-left: 8px;
        }
        &-name {
          color: $color;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &-desc {
          font-size: 12px;
          margin-bottom: -2px;
          line-height: 17px;
          .routed {
            display: inline-block;
            margin-right: 10px;
            color: $blue-light;
          }
          .not-connected {
            color: $color-warning;
            opacity: 0;
            display: none;
          }
        }
        .item-actions {
          position: absolute;
          right: 0;
          top: 0;
          i {
            background: rgba(36, 42, 51, 0.71);
          }
        }
      }
    }
  }
}
.mobile {
  #router {
      overflow-x: scroll;
      margin: 10px 0px;
      .wrapper {
        padding: 10px 0;
      }
      .item {
        width: 150px !important;
        grid-template-columns: 0 auto !important;
        &-avatar {
          visibility: hidden !important;
        }
      }
      .default-theme.splitpanes .splitpanes__pane {
        padding: 0 0 0 20px;
      }
      .container-with-sidebar {
        .pane-title {
          .title-actions {
            right: 0;
            top: 0;
            position: relative;
            left: -16px;
          }
        }
      }
  }
}

.default-theme {
  &.splitpanes {
    height: calc(100vh - 60px);
    .splitpanes__pane {
      background: transparent;
      padding: 0 0 0 20px;
      &.pane-dest {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }
    .splitpanes__splitter {
      background-color: rgba(0, 0, 0, 0.3);
      border-left: 1px solid transparent;
      border-top: 1px solid transparent;
      &:before {
        margin-left: 0px;
        background-color: $icon;
      }
      &:after {
        background-color: $icon;
      }
    }
  }
}
</style>
