<template>
  <div class="page401">
    <div v-show="userState.isActiveUser">
      <h1 class="big">401</h1>
      <h2>Sorry you are not authorized</h2>
    </div>
    <div v-show="!userState.isActiveUser">
      <h1 class="big">
        <i class="bi bly-warning-2 amber"></i>
      </h1>
      <h1>Your account has not been activated.</h1>
      <router-link to="/login">Go to login page</router-link>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import { mapState } from 'vuex';

export default {
  name: 'home',
  computed: {
    ...mapState({
      userState: (state) => state.userAccount,
    }),
  },
  created() {
  },
};
</script>
<style lang="scss" scoped>
.page401 {
  text-align: center;
  .big {
    color: #fff;
    margin-bottom: 0px;
    padding-top: 10vh;
    font-size: 140px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }
  h2 {
    margin-top:0;
  }
}
</style>
