<template>
  <div id="auth" class="auth-layout">
    <div class="col-1"></div>
    <div class="col-2 form" v-show="visibleStep === 1">
      <el-form :model="form"
        :rules="rules"
        @submit.native.prevent
        ref="form"
        label-width="120px"
        label-position="top">

        <h1 class="title">Password Reset</h1>
        <p class="title-description">Enter your email address</p>
        <el-form-item v-if="error">
          <el-alert :title="error" v-if="error" type="error" :closable="false"></el-alert>
        </el-form-item>

        <el-form-item prop="username" label="Email address">
          <el-input
            :autofocus="true"
            placeholder="john.doe@mail.com"
            v-model="form.username"
            @keyup.enter.native="resetPasswordStepOne('form')">
          </el-input>
        </el-form-item>
        <el-button
          type="primary"
          @click="resetPasswordStepOne('form')"
          show-password
          :loading="isBtnLoading">{{btnText('Reset password')}}
        </el-button>
      </el-form>
    </div>
    <div class="col-2 form" v-show="visibleStep === 2">
      <el-form
        :model="newPasswordForm"
        :rules="newPasswordRules"
        @submit.native.prevent
        ref="newPasswordForm"
        label-width="120px"
        label-position="top">

        <h1 class="title">Change password</h1>
        <p class="title-description">Enter the code recived in the email and new password</p>

        <el-form-item v-if="error">
          <el-alert :title="error" v-if="error" type="error" :closable="false"></el-alert>
        </el-form-item>

        <el-form-item prop="code" label="Enter verification code">
          <el-input
            :autofocus="true"
            placeholder="Verification code"
            v-model="newPasswordForm.code"
            @keyup.enter.native="forgotPasswordSubmit('newPasswordForm')">
          </el-input>
        </el-form-item>

        <el-form-item prop="newPassword" label="Enter new password">
          <el-input
            placeholder="New Password"
            type="password"
            v-model="newPasswordForm.newPassword"
            @keyup.enter.native="forgotPasswordSubmit('newPasswordForm')">
          </el-input>
        </el-form-item>

        <el-form-item prop="confirmPassword" label="Confirm new password">
          <el-input
            placeholder="Confirm Password"
            type="password"
            v-model="newPasswordForm.confirmPassword"
            @keyup.enter.native="forgotPasswordSubmit('newPasswordForm')">
          </el-input>
        </el-form-item>

        <el-button
          type="primary"
          @click="forgotPasswordSubmit('newPasswordForm')"
          :loading="isBtnLoading">{{btnText('Change Password')}}
        </el-button>
      </el-form>
    </div>
    <div class="col-2" v-show="visibleStep === 3">
      <h1 class="title">Your password has been changed. You can now login.</h1>
      <el-button
        type="primary"
        @click="goToLogin('newPasswordForm')"
        :loading="isBtnLoading">{{btnText('Go to login page')}}
      </el-button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  data() {
    return {
      visibleStep: 1,
      form: {
        username: '',
      },
      newPasswordForm: {
        code: '',
        newPassword: '',
        confirmPassword: '',
      },
      isBtnLoading: false,
      error: null,
      rules: {
        username: [
          {
            required: true,
            message: 'Please enter correct email address',
            trigger: 'blur',
            type: 'email',
          },
        ],
      },
      newPasswordRules: {
        code: [
          { required: true, message: 'Please enter code', trigger: 'blur' },
        ],
        newPassword: [
          { required: true, message: 'Please enter new passwrod', trigger: 'blur' },
          { min: 6, message: 'Length should be atleast 6', trigger: 'blur' },
        ],
        confirmPassword: [
          { required: true, message: 'Please confirm new password', trigger: 'blur' },
          { min: 6, message: 'Length should be atleast 6', trigger: 'blur' },
        ],
      },
    };
  },

  created() {
  },

  methods: {
    btnText(text) {
      if (this.isBtnLoading) return 'Loading...';
      return text;
    },
    async resetPasswordStepOne(formName) {
      const form = this.$refs[formName];
      const isFormValid = await form.validate();

      if (!isFormValid) {
        this.error = 'Form is invalid.';
        return;
      }

      try {
        this.isBtnLoading = true;
        await this.$Amplify.Auth.forgotPassword(this.form.username);
        this.visibleStep = 2;
        this.isBtnLoading = false;
      } catch (error) {
        this.error = error.message;
        this.isBtnLoading = false;
      }
    },

    async forgotPasswordSubmit(formName) {
      const form = this.$refs[formName];
      const isFormValid = await form.validate();

      if (!isFormValid) {
        this.error = 'Form is invalid.';
        return;
      }

      try {
        this.isBtnLoading = true;
        this.form.username = this.form.username.toLowerCase();
        await this.$Amplify.Auth.forgotPasswordSubmit(
          this.form.username,
          this.newPasswordForm.code,
          this.newPasswordForm.newPassword,
        );
        this.visibleStep = 3;
        this.isBtnLoading = false;
      } catch (error) {
        this.error = error.message;
        this.isBtnLoading = false;
      }
    },

    validatePass(rule, value, callback) {
      if (value === '') callback(new Error('Please enter the password again'));
      if (value !== this.newPasswordForm.newPassword) callback(new Error('Passwords don\'t match!'));

      callback();
    },

    goToLogin() {
      this.$router.push({ path: 'login' });
    },
  },
};
</script>
