<template>
  <div id="auth" class="auth-layout">
    <div class="col-1"></div>
    <div class="col-2 form" v-show="visibleStep === 1" key="step1">
      <el-form :model="form"
        :rules="rules"
        @submit.native.prevent
        ref="form"
        label-position="top">

        <h1 class="title">Welcome</h1>
        <p class="title-description">Enter your email to get started</p>
        <el-form-item v-if="error">
          <el-alert :title="error" v-if="error" type="error" :closable="false"></el-alert>
        </el-form-item>
        <el-form-item v-if="!code">
          <el-alert :title="'Company code missing'" v-if="!code" type="error" :closable="false">
            Please contact your company for registration link
          </el-alert>
        </el-form-item>
        <el-form-item prop="username" label="Email">
          <el-input
            :autofocus="true"
             placeholder="john.doe@mail.com"
            v-model="form.username"
            @keyup.enter.native="registerStepOne('form')">
          </el-input>
        </el-form-item>
        <el-button
          type="primary"
          :disabled="!code"
          @click="registerStepOne('form')"
          :loading="isBtnLoading">Get started
        </el-button>
      </el-form>
    </div>
    <div class="col-2 form" v-show="visibleStep === 2" key="step2">
      <el-form
        :model="userData"
        :rules="userDataRules"
        @submit.native.prevent
        ref="userData"
        label-width="120px"
        label-position="top">

        <h1 class="title">Sign up</h1>
        <p class="title-description">Enter your details to get started</p>

        <el-form-item v-if="error">
          <el-alert :title="error" v-if="error" type="error" :closable="false"></el-alert>
        </el-form-item>
<!--
        <el-form-item prop="name" label="What is your name">
          <el-input
            :autofocus="true"
            placeholder="John Doe"
            v-model="userData.name"
            @keyup.enter.native="registerStepTwo('userData')">
          </el-input>
        </el-form-item>

        <el-form-item prop="company" label="What’s the name of your company?">
          <el-input
            placeholder="Company name"
            v-model="userData.company"
            @keyup.enter.native="registerStepTwo('userData')">
          </el-input>
        </el-form-item>
-->
        <el-form-item prop="password" label="Password">
          <el-input
            placeholder="Password"
            type="password"
            v-model="userData.password"
            @keyup.enter.native="registerStepTwo('userData')">
          </el-input>
        </el-form-item>

        <el-button
          type="primary"
          @click="registerStepTwo('userData')"
          :loading="isBtnLoading">{{btnCreateAccountText}}
        </el-button>
      </el-form>
    </div>
    <div class="col-2 form" v-show="visibleStep === 3">
      <h1 class="title">Verify your email</h1>
      <p class="lead">
        We have sent an email with a confirmation link to your email address. In
         order to complete the sign-up process, please click the confirmation link.
      </p>
      <el-button
        type="primary"
        @click="goToLogin">Go to login page
      </el-button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  data() {
    return {
      visibleStep: 1,
      form: {
        username: '',
      },
      userData: {
        name: '',
        password: '',
        company: '',
      },
      code: null,
      user: null,
      isBtnLoading: false,
      error: null,
      rules: {
        username: [
          {
            required: true,
            message: 'Please enter correct email address',
            trigger: 'blur',
            type: 'email',
          },
        ],
      },
      userDataRules: {
        name: [
          { required: true, message: 'Please enter your name', trigger: 'blur' },
        ],
        password: [
          { required: true, message: 'Please enter your passwrod', trigger: 'blur' },
          { min: 8, message: 'Password is too short', trigger: 'blur' },
        ],
        company: [
        ],
      },
    };
  },

  computed: {
    btnCreateAccountText() {
      if (this.isBtnLoading) return 'Loading...';
      return 'Create Account';
    },

  },

  created() {
  },

  mounted() {
    const hasCodes = this.$route.params.code.split(':').length === 2;
    if (!hasCodes) return;
    this.code = this.$route.params.code;
  },

  methods: {
    async registerStepOne(formName) {
      const form = this.$refs[formName];
      const isFormValid = await form.validate();

      if (!isFormValid) {
        this.error = 'Incorect email address.';
        return;
      }
      this.errot = null;
      this.visibleStep = 2;
    },

    async registerStepTwo(formName) {
      const form = this.$refs[formName];
      const isFormValid = await form.validate();

      if (!isFormValid) {
        this.error = 'Form is invalid.';
        return;
      }

      try {
        this.isBtnLoading = true;
        this.form.username = this.form.username.toLowerCase();
        const result = await this.$Amplify.Auth.signUp({
          username: this.form.username,
          password: this.userData.password,
          attributes: {
            email: this.form.username,
            'custom:client': this.code,
            // company: this.userData.company,
          },
        });

        const hasUser = !!result.user;

        if (!hasUser) {
          this.error = 'Something went wrong try again';
          this.isBtnLoading = false;
          return;
        }

        this.user = result.user;
        this.visibleStep = 3;
        this.isBtnLoading = false;
      } catch (error) {
        const hasErrorCode = !!error.code;
        this.isBtnLoading = false;
        if (hasErrorCode) {
          this.error = error.message;
          return;
        }
        this.error = error;
      }
    },

    validatePass(rule, value, callback) {
      if (value === '') callback(new Error('Please enter the password again'));
      if (value !== this.newPasswordForm.newPassword) callback(new Error('Passwords don\'t match!'));

      callback();
    },

    goToLogin() {
      this.$router.push({ path: '/login' });
    },
  },
};
</script>
