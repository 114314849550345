<template>
  <div
    id="main"
    class="main theme-mini"
    :class="{ mobile: isMobileOnly }"
  >
    <div id="nav">
      <router-link to="/home" tag="div" id="logo">
        <img
          src="../assets/et.png"
          alt=""
        >
      </router-link>
      <div id="right-nav">
        <!-- MOBILE PUSH MENU -->
        <span class="mobile-toggle"
          v-if="isMobileOnly"
          @click="mobileNav = true">
          <el-avatar size="small" shape="square"
            :style="{ background: avatarBackgroundColor, color: avatarTextColor}">
              {{initials}}
          </el-avatar>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-drawer
          custom-class="main-push-nav"
          size="300px"
          :title="userName"
          :visible.sync="mobileNav"
          :with-header="false">
          <div v-bar class="drawer-wrapper">
            <div>
              <el-dropdown-item disabled divided>
                Account(s)
              </el-dropdown-item>
              <el-dropdown-item
                @click.native="setClientAccount({item, accountId: item.account.id})"
                v-for="item in avaiableAccounts" :key="item.account.id + 'nav'"
                :disabled="item.account.id === account.id">
                <div class="dd-account-name">{{item.account.name}}
                  <el-tag
                    v-show="item.account.id === account.id"
                    size="mini" class="muted" type="info">active</el-tag>
                </div>
                <small class="dd-account-client">{{item.client.name}}</small>
              </el-dropdown-item>
              <el-dropdown-item icon="bi bly-user" @click.native="profile" divided>
                Edit profile
              </el-dropdown-item>
              <el-dropdown-item icon="bi bly-logout" @click.native="logout">
                Logout
              </el-dropdown-item>
            </div>
          </div>
        </el-drawer>
        <!-- DESKTOP MENU -->
        <el-popover
          placement="bottom"
          trigger="click">

          <!-- ACCOUNT LIST DESKTOP -->
          <div class="nav-user-info">
            <div class="nav-profile-name" @click="profile">{{userName}}</div>
            <div class="user-actions">
              <el-tooltip content="User settings">
                <i class="bi bly-edit icon-btn" @click="profile"></i>
              </el-tooltip>
              <el-tooltip content="Logout">
                <i class="bi bly-logout icon-btn " @click="logout"></i>
              </el-tooltip>

            </div>
          </div>
          <div v-bar class="dd-popup-wrapper">
            <div>
              <div v-for="clientItem in avaiableClients" :key="clientItem.client.id">
                <el-divider content-position="left">{{clientItem.client.name}}</el-divider>
                <div class="dd-client-account-list">
                  <div
                    class="dd-account-item"
                    :class="{ active: item.account.id === account.id }"
                    @click="setClientAccount({item, accountId: item.account.id}); mobileNav = false"
                    v-for="item in clientItem.accounts" :key="item.account.id + 'nav'"
                    :disabled="item.account.id === account.id">
                    <div class="dd-account-name">
                      {{item.account.name}}
                      <!-- <br>
                      <small>{{item.client.name}}</small>
                      -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END ACCOUNT LIST DESKTOP -->
          <span
            slot="reference"
            v-if="!isMobileOnly"
            class="el-dropdown-link">
            <div class="nav-profile">
              <div class="nav-profile-name">{{userName}}</div>
              <div class="nav-profile-account">{{accountName}}</div>
            </div>
            <el-avatar size="small" shape="square"
              :style="{ background: avatarBackgroundColor, color: avatarTextColor}">
                {{initials}}
            </el-avatar>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
        </el-popover>
        <div class="separator"></div>
      </div>
    </div>
    <div></div>
    <transition name="slide" mode="out-in" appear>
      <router-view />
    </transition>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { isMobile, isMobileOnly } from 'mobile-device-detect';
import AuthenticationService from '@/services/Authentication';
import ColorGenerator from '@/services/ColorGenerator';

export default {
  data() {
    return {
      interval: null,
      userName: '',
      mobileNav: false,
      isMobile,
      isMobileOnly,
    };
  },

  computed: {
    ...mapState({
      showDataFromAllAccounts: (state) => state.userAccount.showDataFromAllAccounts,
      isSysAdmin: (state) => state.userAccount.isSysAdmin,
      userSession: (state) => state.userAccount.userSession,
      userGroups: (state) => state.userAccount.userGroups,
      client: (state) => state.userAccount.client,
      listClients: (state) => state.userAccount.listClients,
      clients: (state) => state.userAccount.clients,
      account: (state) => state.userAccount.account,
      userFeatures: (state) => state.userAccount.userFeatures,
    }),
    initials() {
      if (!this.account.name) return '';
      return this.account.name.split(' ').map((n) => n[0]).join('');
    },
    accountName() {
      return this.account && this.account.name ? this.account.name : '';
    },

    avatarBackgroundColor() {
      return ColorGenerator.generateNewColor(this.accountName + this.account.code);
    },

    avatarTextColor() {
      return '#fff';
    },

    avaiableClients() {
      const clients = [];
      if (!this.listClients.items) return [];
      this.listClients.items.forEach((client) => {
        const accounts = [];
        client.accounts.items.forEach((account) => {
          const isDefault = account.code === client.code;
          accounts.push({
            client,
            account,
            isDefault,
          });
        });
        clients.push({
          client,
          accounts,
        });
      });

      return clients;
    },

    avaiableAccounts() {
      const accounts = [];
      if (!this.listClients.items) return [];
      this.listClients.items.forEach((client) => {
        client.accounts.items.forEach((account) => {
          const isDefault = account.code === client.code;
          accounts.push({
            account,
            client,
            isDefault,
          });
        });
      });

      return accounts;
    },
  },

  created() {
    const body = document.querySelector('body');
    body.className = this.isMobileOnly ? 'mobile' : 'desktop';
    this.interval = window.setInterval(this.checkSession, 60000);
    this.checkUser();
    this.fetchUserClientList();
    this.fetchUserAccountList();
    this.listAppSettings();
  },

  beforeDestroy() {
    window.clearInterval(this.interval);
  },

  methods: {
    ...mapActions({
      fetchUserClientList: 'userAccount/fetchClientList',
      fetchUserAccountList: 'userAccount/fetchAccountList',
      listAppSettings: 'appSettings/listAppSettings',
    }),

    ...mapMutations({
      setCognitoUser: 'userAccount/setCognitoUser',
      setPermissions: 'userAccount/setPermissions',
      setClient: 'userAccount/setClient',
      setClientAccount: 'userAccount/setClientAccount',
      setShowDataFromAllAccounts: 'userAccount/setShowDataFromAllAccounts',
    }),

    async logout() {
      this.mobileNav = false;
      await this.$Amplify.Auth.signOut();
      this.$router.push({ path: '/login' });
    },

    profile() {
      this.mobileNav = false;
      this.$router.push({ path: '/profile' });
    },

    async checkUser() {
      const user = await this.$Amplify.Auth.currentAuthenticatedUser();
      AuthenticationService.setUser(user);
      console.log('user => ', user.signInUserSession.accessToken.payload);
      this.setCognitoUser(user);
      this.setPermissions(AuthenticationService.userPermissions);
      this.userName = AuthenticationService.userEmail;
    },

    async checkSession() {
      await AuthenticationService.checkSession();
    },
  },
};
</script>
<style lang="scss">
@import "../assets/var.scss";
$nav-height: 50px;
#main {
  // display:grid;
  // grid-template-rows: 50px 1fr;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: $nav-height;
  margin: 0 -20px;
  overflow-x: hidden;
  min-height: 100vh;
  max-width: 100vw;
  #nav {
    z-index: 99;
    .bly-button {
      margin-top: 10px;
      margin-right: 24px;
    }
    .nav-profile {
      text-transform: none;
      line-height: 18px;
      vertical-align: middle;
      font-weight: bold;
      text-align: right;
      font-weight: 600;
      display: inline-block;
      margin-right: 11px;
      @extend %no-select;
      cursor: pointer;
      &-name {
        color:$color;
        font-size: 14px;
      }
      &-account {
        font-size: 12px;
      }
    }
  }
  #right-nav {
    .el-avatar {
      position: relative;
      top: 10px;
      cursor: pointer;
    }
    .separator {
      margin-right: 10px;
      margin-left: 10px;
      display: inline-block;
    }
  }
  #logo {
    cursor: pointer;
    img {
      max-width: 200px;
      max-height: 50px;
      margin-bottom: -16px;
    }
  }
}
@media screen and (max-width: 800px) and (orientation: portrait) {
  #main {
    #nav {

    }
  }
}
@media screen and (orientation: landscape) {
  #main {
    #nav {

    }
  }
}
.main-push-nav {
  .el-drawer__body {
    margin-top: -20px;
  }
  .el-dropdown-menu__item {
    .dd-account-name {
      font-size: 16px;
      color: #000;
    }
    .dd-account-client {
      font-size: 12px;
      margin-top: -18px;
      display: block;
    }

  }
}
.mobile {
  .main-push-nav {
    .el-dropdown-menu__item {
      .dd-account-name {
        font-size: 16px;
        color: #000;
      }
      .dd-account-client {
        font-size: 12px;
        margin-top: 0px;
        display: block;
      }

    }
  }
}
.drawer-wrapper {
  height: calc(100vh - 57px);
}
.dd-client-account-list {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: auto auto auto;
    .dd-account-item {
      //background: #f0f5ff;
      padding: 4px 8px;
      border-radius: 3px;
      color: #000;
      cursor: pointer;
      font-weight: 600;
      //border: 1px solid #cfdfff;
      &.active {
        background: #FEF5EB; //$color-primary;
        // border-color: #FEF5EB; //$color-primary;
        color:$color-primary;
      }
    }
    .el-divider {
      background-color: #dae5ff;
    }
  }
.el-popover {
  .el-divider__text.is-left {
    left: 0;
    padding-left: 0px;
  }
  .el-divider__text {
    color: #babdc7;
  }
  .el-divider--horizontal {
    display: block;
    height: 1px;
    width: 100%;
    margin: 24px 0 13px 0;
  }
  .el-divider {
    background-color: rgba(179, 189, 214, 0.48);
    position: relative;
  }
  .nav-user-info {
    background: rgb(241, 242, 247);
    border-radius: 4px;
    padding: 0 16px;
    font-weight: bold;
    position: relative;
    line-height: 49px;
    margin: -12px -12px 0 -12px;
    z-index: 22;
    .nav-profile-name {
      cursor: pointer;
      color:#000;
      font-weight: 600;
      font-size: 16px;
      &:hover {
        color:$color-primary;
      }
    }
    .user-actions {
      position: absolute;
      top: 2px;
      right: 6px;
    }
  }
}
</style>
