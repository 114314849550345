import { API, graphqlOperation } from 'aws-amplify';
import Vue from 'vue';
import * as qlqueries from '../graphql/queries';

const state = {
  name: 'Audit',
  logs: {},
  loading: false,
};

const getters = {
};

const mutations = {

  setLogs: (state, payload) => {
    Vue.set(state, 'logs', payload);
  },

  setLoading: (state, payload) => {
    Vue.set(state, 'loading', payload);
  },
};

const actions = {
  async listAutomationAudits({ commit }) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listAutomationAudits,
          {
            limit: 1500,
          }),
      );
      const { data } = result;
      commit('setLogs', data.listAutomationAudits);

      commit('setLoading', false);
      return data.listAutomationAudits;
    } catch (error) {
      commit('setLoading', false);
      return { error: true, message: error.errors };
    }
  },
  async filterAutomationAudits({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listAutomationAudits,
          {
            filter: payload,
            limit: 1500,
          }),
      );
      const { data } = result;
      commit('setLogs', data.listAutomationAudits);

      commit('setLoading', false);
      return data.listAutomationAudits;
    } catch (error) {
      commit('setLoading', false);
      return { error: true, message: error.errors };
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
