<template>
  <div id="home" class="home">
    <div class="welcome-screen grid md:grid-cols-3 gap-4 rin">
      <div class="tile px-5 md:py-8 py-5 text-center items-center align-middle flex justify-center"
        v-for="perm in accesibleViews"
        :key='perm.name'
        @click="goTo(perm)">
        <div>
          <img :src="require('../assets/' + perm.name + '.svg')" alt="" class="mx-auto mb-3">
          {{perm.displayName}}
        </div>
        <span class="is-link" v-if="perm.link">
          <i class="bi bly-small-down bi-rotate-45"></i>
        </span>
      </div>
      <div v-if="isSysAdmin" class="tile px-5 md:py-8 py-5 text-center items-center align-middle flex justify-center">
        <div>
          <div class="text-xs mb-2">Links</div>
          <div class="flex-row">
            <a class="block" target="_blank" href="https://docs.google.com/spreadsheets/d/1Q09L9YAvkKgBo98ATAagIkkR5U1PN0BEcNGO0CdiBtk/edit#gid=0">OPS Schedule</a>
            <a class="block" target="_blank" href="https://docs.google.com/document/d/19nwj4sOaKD_macsN9s9mX326-sJAfd2rwf1mBoswSNs/edit#heading=h.q07fohhg117">Operational Guide</a>
            <a class="block" target="_blank" href="https://docs.google.com/spreadsheets/d/1MspqI79HMleZQSL5Pjnjz6LG2F8e_FEWc12YzRQkrDo/edit?pli=1#gid=0">Inventory</a>
            <a class="block" target="_blank" href="https://docs.google.com/spreadsheets/d/1OaIHiqBflyqZ4gf6nwErypz9cAsdzYVygW38DGijHi8/edit#gid=0">Booking report</a>
            <a class="block" target="_blank" href="https://docs.google.com/spreadsheets/d/1vN-kq__KMEw2KjIIvcXUoH8c-Up5E_CgekDWfG220MU/edit?ts=5bcdde26&pli=1#gid=145127749">DAZN Sheet</a>
          </div>
        </div>
        <span class="is-link">
          <i class="bi bly-small-down bi-rotate-45"></i>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { permissions } from '../config';

export default {
  name: 'home',
  data() {
    return {
      source: null,
      showSupportMessage: false,
      permissions,
    };
  },

  computed: {
    ...mapState({
      client: (state) => state.userAccount.client,
      account: (state) => state.userAccount.account,
      userFeatures: (state) => state.userAccount.userFeatures,
      userPermissions: (state) => state.userAccount.userPermissions,
      isSysAdmin: (state) => state.userAccount.isSysAdmin,
    }),
    accesibleViews() {
      const filtered = [];
      Object.keys(this.permissions).forEach((key) => {
        const hasAccess = this.userFeatures.indexOf(this.permissions[key].code) !== -1;
        if (hasAccess || this.userPermissions.isSysAdmin) filtered.push(this.permissions[key]);
      });
      return filtered;
    },
  },

  methods: {
    ...mapActions({
      fetchClientList: 'app/fetchClientList',
      fetchAccountList: 'app/fetchAccountList',
    }),

    goTo(perm) {
      const isLink = !!perm.link;
      const hasParams = !!perm.params;

      if (isLink) {
        window.location.href = perm.link;
        return;
      }
      if (hasParams) {
        this.$router.push({ name: `${perm.name}`, params: perm.params });
        return;
      }
      this.$router.push({ path: `/${perm.name}` });
    },
  },
};
</script>

<style lang="scss">
  $hl-color: #CFDFFF;
  $grey: #66718D;
  #home {
    .home-logo {
      margin: 0 auto;
      padding: 100px;
      display: block;
    }

    .welcome-screen {
      display: grid;
      margin: 20px;
      padding-top: 0;
      .tile {
        position:relative;
        background: rgba($grey, 0.2);
        width: 100%;
        // height: 30vh;
        display: inline-block;
        text-transform: uppercase;
        border-radius: 3px;
        cursor: pointer;
        font-size: 16px;
        letter-spacing: 1px;
        font-weight: bold;
        display: grid;
        align-items: center;
        grid-auto-columns: auto;
        grid-auto-flow: column;
        .ma {
          font-size: 3em;
          color: $hl-color;
          margin-right: 10px;
          vertical-align: middle;
        }
        &:hover {
          background: rgba($grey, 0.4);
          color: #fff;
        }
        .is-link {
          position: absolute;
          bottom: 10px;
          right: 0px;
          line-height: 16px;
          .ma {
            font-size: 16px;
            color:$grey;
          }
        }
        img {
          width: 48px;
          height: 48px;
          vertical-align: middle;
          stroke: $hl-color;
        }
      }
    }
    text-align: center;
  }

</style>
