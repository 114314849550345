import Vue from 'vue';
import ServersService from '../services/Servers';
import { monitoringConfig } from '../config';

const state = {
  name: 'Servers',
  serverList: [],
  serversById: {},
  serverStatus: {},
  loading: false,
};

const getters = {
};

const mutations = {
  setServerList: (state, payload) => {
    Vue.set(state, 'serverList', payload);

    state.serverList.forEach((server) => {
      state.serversById[server.id] = server;
      const serverStatus = {
        status: 'amber',
      };
      const heartBeat = server.secondsSinceHeartbeat;
      const isOnline = heartBeat <= monitoringConfig.heartBeatOnline;
      const isOffline = heartBeat > monitoringConfig.heartBeatOffline;

      if (!isOffline) serverStatus.statusText = 'ONLINE';
      if (isOffline) {
        serverStatus.statusText = 'OFFLINE';
        serverStatus.status = 'red';
      }
      if (isOnline) serverStatus.status = 'green';

      //
      // Encoder status
      //
      const encoders = {
        grey: 0,
        amber: 0,
        red: 0,
        green: 0,
      };
      server.encoders.forEach((encoder) => {
        const encoderStatus = {
          input: 'amber',
          output: 'amber',
          statusMessage: encoder.videoFormat,
          status: 'green',
          encoder,
        };
        // inputoutput Status
        if (!isOffline) {
          encoderStatus.input = encoder.inputActive ? 'green' : 'grey';
          encoderStatus.output = encoder.outputStatus ? 'green' : 'grey';
        }
        // status
        if (encoder.status !== 'Encoding') {
          encoderStatus.status = 'red';
          encoderStatus.statusMessage = encoder.status;
        }
        // FormatStatus

        //  overall status
        encoderStatus.all = 'green';

        const hasGrey = encoderStatus.input === 'grey'
          || encoderStatus.output === 'grey'
          || encoderStatus.status === 'grey';

        const hasAmber = encoderStatus.input === 'amber'
          || encoderStatus.output === 'amber'
          || encoderStatus.status === 'amber';

        const hasRed = encoderStatus.input === 'red'
        || encoderStatus.output === 'red'
        || encoderStatus.status === 'red';

        if (hasGrey) encoderStatus.all = 'grey';
        if (hasAmber) encoderStatus.all = 'amber';
        if (hasRed) encoderStatus.all = 'red';

        // count encoder status
        encoders[encoderStatus.all] += 1;
        encoders[encoder.index] = encoderStatus;
      });
      const hasGrey = Object.values(encoders).some((el) => el.all === 'grey');
      const hasAmber = Object.values(encoders).some((el) => el.all === 'amber');
      const hasRed = Object.values(encoders).some((el) => el.all === 'red');

      if (hasGrey) encoders.all = 'grey';
      if (hasAmber) encoders.all = 'amber';
      if (hasRed) encoders.all = 'red';
      serverStatus.encoders = encoders;

      //
      // DECODERS STATUS
      //

      const decoders = {
        grey: 0,
        amber: 0,
        red: 0,
        green: 0,
      };
      server.decoders.forEach((decoder) => {
        const decoderStatus = {
          input: 'green',
          output: 'green',
          statusMessage: decoder.videoFormat,
          status: 'green',
          decoder,
        };
        // inputoutput Status
        decoderStatus.input = decoder.inputStatus ? 'green' : 'red';
        decoderStatus.output = decoder.outputStatus ? 'green' : 'red';
        // status
        decoderStatus.statusMessage = '1080p25';
        decoderStatus.statusTooltip = `
          Input codec:   <strong>${decoder.codec}</strong> <br>
          Input profile: <strong>${decoder.prifile}</strong><br>
          Resolution:    <strong>${decoder.resolution}</strong><br>
          Framerate:     <strong>${decoder.framerate}</strong><br>
        `;
        if (!decoder.inputStatus || !decoder.outputStatus) {
          decoderStatus.status = 'red';
        }
        // FormatStatus

        //  overall status
        decoderStatus.all = 'green';

        const decoderHasGrey = decoderStatus.input === 'grey'
          || decoderStatus.output === 'grey'
          || decoderStatus.status === 'grey';

        const decoderHasAmber = decoderStatus.input === 'amber'
          || decoderStatus.output === 'amber'
          || decoderStatus.status === 'amber';

        const decoderHasRed = decoderStatus.input === 'red'
        || decoderStatus.output === 'red'
        || decoderStatus.status === 'red';

        if (decoderHasGrey) decoderStatus.all = 'grey';
        if (decoderHasAmber) decoderStatus.all = 'amber';
        if (decoderHasRed) decoderStatus.all = 'red';

        // count encoder status
        decoders[decoderStatus.all] += 1;
        decoders[decoder.index] = decoderStatus;
      });
      const decoderHasGrey = Object.values(decoders).some((el) => el.all === 'grey');
      const decoderHasAmber = Object.values(decoders).some((el) => el.all === 'amber');
      const decoderHasRed = Object.values(decoders).some((el) => el.all === 'red');

      if (decoderHasGrey) decoders.all = 'grey';
      if (decoderHasAmber) decoders.all = 'amber';
      if (decoderHasRed) decoders.all = 'red';
      serverStatus.decoders = decoders;

      //
      // FILES STATUS
      //

      const files = {
        grey: 0,
        amber: 0,
        red: 0,
        green: 0,
      };
      server.wowzaStreamfiles.forEach((file, index) => {
        const encoder = encoders[file.encoderId];
        const doesEncoderExist = !!file.encoderId && encoder;

        files[index] = { all: 'green' };

        if (!doesEncoderExist) {
          files[index].all = 'red';
          files[files[index].all] += 1;

          return;
        }
        if (encoder.all === 'grey' && file.totalConnections === 0) files[index].all = 'grey';
        if (encoder.all === 'amber') files[index].all = 'amber';
        if (encoder.all === 'red') files[index].all = 'red';

        files[files[index].all] += 1;
      });

      const fileHasGrey = Object.values(files).some((el) => el.all === 'grey');
      const fileHasAmber = Object.values(files).some((el) => el.all === 'amber');
      const fileHasRed = Object.values(files).some((el) => el.all === 'red');

      if (fileHasGrey) files.all = 'grey';
      if (fileHasAmber) files.all = 'amber';
      if (fileHasRed) files.all = 'red';

      serverStatus.files = files;

      //
      // PLAYBACK STATUS
      //
      const playbacks = {
        grey: 0,
        amber: 0,
        red: 0,
        green: 0,
      };
      server.tsPlaybackStatuses.forEach((playback, index) => {
        playbacks[index] = { all: 'green' };

        if (!playback.running && !playback.file) playbacks[index].all = 'grey';
        if (playback.running && !playback.file) playbacks[index].all = 'red';

        playbacks[playbacks[index].all] += 1;
      });

      server.tsPlaybackStatuses = server.tsPlaybackStatuses.map((playback) => {
        const newPlayback = playback;
        newPlayback.savedStateLooped = (playback.savedStateLooped === 'true');

        newPlayback.ottOutputTargetList = server.wowzaStreamfiles.map((file) => {
          const newTarget = {
            serverId: server.id,
            application: file.application,
            name: file.name,
            index: file.index,
          };
          return newTarget;
        });

        playback.savedStateTargets.forEach((target, k) => {
          newPlayback.ottOutputTargetList.forEach((ott) => {
            if (target.application === ott.application) {
              newPlayback.savedStateTargets[k] = ott;
            }
          });
        });
        return newPlayback;
      });
      const playbackHasGrey = Object.values(playbacks).some((el) => el.all === 'grey');
      // const playbackHasAmber = Object.values(playbacks).some(el => el.all === 'amber');
      const playbackHasRed = Object.values(playbacks).some((el) => el.all === 'red');

      if (playbackHasGrey) playbacks.all = 'grey';
      // if (playbackHasAmber) playbacks.all = 'amber';
      if (playbackHasRed) playbacks.all = 'red';

      serverStatus.playbacks = playbacks;

      // set server status info
      serverStatus.all = 'green';
      const allHasGrey = Object.values(serverStatus).some((el) => el.all === 'grey');
      const allHasAmber = Object.values(serverStatus).some((el) => el.all === 'amber');
      const allHasRed = Object.values(serverStatus).some((el) => el.all === 'red');

      if (allHasGrey) serverStatus.all = 'grey';
      if (allHasAmber) serverStatus.all = 'amber';
      if (allHasRed) serverStatus.all = 'red';

      Vue.set(state.serverStatus, server.id, serverStatus);
    });
  },
  setLoading: (state, payload) => {
    Vue.set(state, 'loading', payload);
  },
};

const actions = {
  async fetchServerList({ commit }) {
    const result = await ServersService.getServersStatus();
    const { data } = result;
    // const data = servers;
    const isSuccess = data.error === '';
    if (!isSuccess) return { error: true, message: data.error };

    commit('setServerList', data.data);

    return result.data.data;
  },

  async sendCommand({ commit }, payload) {
    let response = null;
    try {
      const result = await ServersService.sendCommand(payload);
      const { data } = result;

      const isSuccess = data.error === '';

      if (!isSuccess) return { error: true, message: data.error };

      return result.data.data;
    } catch (error) {
      response = { error: true, message: 'Request failed' };
    }
    commit('setLoading', false);
    return response;
  },

  async restartApplication({ commit }, payload) {
    let response = null;
    try {
      const result = await ServersService.restartApplication(payload);
      const { data } = result;

      const isSuccess = data.error === '';
      if (!isSuccess) return { error: true, message: data.error };
      return result.data.data;
    } catch (error) {
      response = { error: true, message: 'Request failed' };
    }
    commit('setLoading', false);
    return response;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
