<template>
  <div id="admin-client-features">
      <div class="data-header">
        <div class="data-header-tabs">
          <span class="data-header-tabs-tab active">Users</span>
          <!--
            <span class="data-header-tabs-tab" @click="goTo('features')">Features</span>
            <span class="data-header-tabs-tab" @click="goTo('claims')">Claims</span>
          -->
        </div>
        <div class="data-header-actions">
          {{users}}
        </div>
      </div>
  </div>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify';
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';

import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

export default {
  props: ['id'],
  data() {
    return {
      queries,
      mutations,
      error: null,
      isBtnLoading: false,
      user: null,
      clientId: this.$route.params.id,
    };
  },

  computed: {
    ...mapState({
      clients: (state) => state.client.clients,
      users: (state) => state.client.users,
    }),

    client() {
      return this.clients[this.clientId];
    },
  },

  watch: {
  },

  mounted() {
    this.fetchClientUsers();
  },

  created() {
  },

  methods: {
    ...mapActions({
      updateClient: 'client/updateClient',
      deleteClient: 'client/deleteClient',
      fetchClientUsers: 'client/fetchClientUsers',
    }),
    goTo(name) {
      this.$router.push({ name: `client-${name}`, params: { id: this.clientId } });
    },
    async getUserProfile() {
      const result = await this.$Amplify.Auth.currentAuthenticatedUser();
      Vue.set(this, 'user', result);
      const userData = await API.graphql(
        graphqlOperation(this.queries.getUser, { id: this.user.username }),
      );
    },
  },
};
</script>

<style lang="scss">
@import "../assets/var.scss";
$bg: #1b242e;

  #admin-client {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    padding-top: 0;
  }
</style>
