import AuthenticationService from '@/services/Authentication';
import { permissions } from '../config';

export default (to, from, next) => {
  let { name } = to;
  name = name.toUpperCase();
  const { userFeatures } = AuthenticationService;
  const { userPermissions } = AuthenticationService;
  const isHome = name === 'HOME';
  const body = document.querySelector('#app');
  body.className = name;
  if (isHome) {
    next();
    return;
  }
  const hasPermission = !!userFeatures && userFeatures.indexOf(permissions[name].code) !== -1;
  const hasAccess = hasPermission || userPermissions.isSysAdmin || userPermissions.devAdmin;
  if (hasAccess) {
    next();
    return;
  }
  const redirectRoute = {
    path: '/401',
    query: {
      redirect: to.fullPath,
    },
  };
  next(redirectRoute);
};
