/* eslint-disable */
// this is an auto generated file. This will be overwritten
export const routeCreateRoute = `mutation RouteCreateRoute($input: RouteCreateRouteInput!) {
  routeCreateRoute(input: $input) {
    success
    message
  }
}
`;
export const createClient = `mutation CreateClient($input: CreateClientInput!) {
  createClient(input: $input) {
    id
    code
    name
    description
    logo
    website
    email
    address
    phoneno
    accounts {
      items {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      nextToken
    }
    features {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    users {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    claimsCanAccess
    claimsCanEdit
    version
  }
}
`;
export const updateClient = `mutation UpdateClient($input: UpdateClientInput!) {
  updateClient(input: $input) {
    id
    code
    name
    description
    logo
    website
    email
    address
    phoneno
    accounts {
      items {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      nextToken
    }
    features {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    users {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    claimsCanAccess
    claimsCanEdit
    
    version
  }
}
`;
export const deleteClient = `mutation DeleteClient($input: DeleteClientInput!) {
  deleteClient(input: $input) {
    id
    code
    name
    description
    logo
    website
    email
    address
    phoneno
    accounts {
      items {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      nextToken
    }
    features {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    users {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    claimsCanAccess
    claimsCanEdit
    
    version
  }
}
`;
export const createClientFeatureMap = `mutation CreateClientFeatureMap($input: CreateClientFeatureMapInput!) {
  createClientFeatureMap(input: $input) {
    id
    client {
      id
      code
      name
      description
      logo
      website
      email
      address
      phoneno
      accounts {
        nextToken
      }
      features {
        nextToken
      }
      users {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    feature {
      id
      code
      name
      description
      clients {
        nextToken
      }
      accounts {
        nextToken
      }
      claims {
        nextToken
      }
    }
    claimsCanAccess
    claimsCanEdit
    
  }
}
`;
export const updateClientFeatureMap = `mutation UpdateClientFeatureMap($input: UpdateClientFeatureMapInput!) {
  updateClientFeatureMap(input: $input) {
    id
    client {
      id
      code
      name
      description
      logo
      website
      email
      address
      phoneno
      accounts {
        nextToken
      }
      features {
        nextToken
      }
      users {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    feature {
      id
      code
      name
      description
      clients {
        nextToken
      }
      accounts {
        nextToken
      }
      claims {
        nextToken
      }
    }
    claimsCanAccess
    claimsCanEdit
    
  }
}
`;
export const deleteClientFeatureMap = `mutation DeleteClientFeatureMap($input: DeleteClientFeatureMapInput!) {
  deleteClientFeatureMap(input: $input) {
    id
    client {
      id
      code
      name
      description
      logo
      website
      email
      address
      phoneno
      accounts {
        nextToken
      }
      features {
        nextToken
      }
      users {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    feature {
      id
      code
      name
      description
      clients {
        nextToken
      }
      accounts {
        nextToken
      }
      claims {
        nextToken
      }
    }
    claimsCanAccess
    claimsCanEdit
    
  }
}
`;
export const createAccount = `mutation CreateAccount($input: CreateAccountInput!) {
  createAccount(input: $input) {
    id
    code
    name
    description
    client {
      id
      code
      name
      description
      claimsCanAccess
      claimsCanEdit
      version
    }
    logo
    website
    email
    address
    phoneno
    claims {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    features {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    users {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    claimsCanAccess
    claimsCanEdit
    version
  }
}
`;
export const updateAccount = `mutation UpdateAccount($input: UpdateAccountInput!) {
  updateAccount(input: $input) {
    id
    code
    name
    description
    client {
      id
      code
      name
      description
      logo
      website
      email
      address
      phoneno
      accounts {
        nextToken
      }
     
      users {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    logo
    website
    email
    address
    phoneno
    claims {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    features {
      items {
        id
        feature {
          id
          name
          code
        }
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    users {
      items {
        id
        user {
          name
          id
          email
          claimsCanAccess
          claimsCanEdit
          isApproved
          claims {
            items {
              id
              claim {
                id
                code
                name
                description
                feature {
                  id
                  code
                }
              }
            }
          }
          version
        }
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    claimsCanAccess
    claimsCanEdit
    version
  }
}
`;
export const deleteAccount = `mutation DeleteAccount($input: DeleteAccountInput!) {
  deleteAccount(input: $input) {
    id
    code
    name
    description
    client {
      id
      code
      name
      description
      logo
      website
      email
      address
      phoneno
      accounts {
        nextToken
      }
      features {
        nextToken
      }
      users {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    logo
    website
    email
    address
    phoneno
    claims {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    features {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    users {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    claimsCanAccess
    claimsCanEdit
    version
  }
}
`;
export const createAccountClaimMap = `mutation CreateAccountClaimMap($input: CreateAccountClaimMapInput!) {
  createAccountClaimMap(input: $input) {
    id
    account {
      id
      code
      name
      description
      client {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      logo
      website
      email
      address
      phoneno
      claims {
        nextToken
      }
      features {
        nextToken
      }
      users {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    claim {
      id
      code
      name
      description
      feature {
        id
        code
        name
        description
      }
      accounts {
        nextToken
      }
      users {
        nextToken
      }
    }
    claimsCanAccess
    claimsCanEdit
  }
}
`;
export const updateAccountClaimMap = `mutation UpdateAccountClaimMap($input: UpdateAccountClaimMapInput!) {
  updateAccountClaimMap(input: $input) {
    id
    account {
      id
      code
      name
      description
      client {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      logo
      website
      email
      address
      phoneno
      claims {
        nextToken
      }
      features {
        nextToken
      }
      users {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    claim {
      id
      code
      name
      description
      feature {
        id
        code
        name
        description
      }
      accounts {
        nextToken
      }
      users {
        nextToken
      }
    }
    claimsCanAccess
    claimsCanEdit
  }
}
`;
export const deleteAccountClaimMap = `mutation DeleteAccountClaimMap($input: DeleteAccountClaimMapInput!) {
  deleteAccountClaimMap(input: $input) {
    id
    account {
      id
      code
      name
      description
      client {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      logo
      website
      email
      address
      phoneno
      claims {
        nextToken
      }
      features {
        nextToken
      }
      users {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    claim {
      id
      code
      name
      description
      feature {
        id
        code
        name
        description
      }
      accounts {
        nextToken
      }
      users {
        nextToken
      }
    }
    claimsCanAccess
    claimsCanEdit
    
  }
}
`;
export const createAccountFeatureMap = `mutation CreateAccountFeatureMap($input: CreateAccountFeatureMapInput!) {
  createAccountFeatureMap(input: $input) {
    id
    account {
      id
      code
      name
      description
      client {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      logo
      website
      email
      address
      phoneno
      claims {
        nextToken
      }
      features {
        nextToken
      }
      users {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    feature {
      id
      code
      name
      description
      clients {
        nextToken
      }
      accounts {
        nextToken
      }
      claims {
        nextToken
      }
    }
    claimsCanAccess
    claimsCanEdit
    
  }
}
`;
export const updateAccountFeatureMap = `mutation UpdateAccountFeatureMap($input: UpdateAccountFeatureMapInput!) {
  updateAccountFeatureMap(input: $input) {
    id
    account {
      id
      code
      name
      description
      client {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      logo
      website
      email
      address
      phoneno
      claims {
        nextToken
      }
      features {
        nextToken
      }
      users {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    feature {
      id
      code
      name
      description
      clients {
        nextToken
      }
      accounts {
        nextToken
      }
      claims {
        nextToken
      }
    }
    claimsCanAccess
    claimsCanEdit
    
  }
}
`;
export const deleteAccountFeatureMap = `mutation DeleteAccountFeatureMap($input: DeleteAccountFeatureMapInput!) {
  deleteAccountFeatureMap(input: $input) {
    id
    account {
      id
      code
      name
      description
      client {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      logo
      website
      email
      address
      phoneno
      claims {
        nextToken
      }
      features {
        nextToken
      }
      users {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    feature {
      id
      code
      name
      description
      clients {
        nextToken
      }
      accounts {
        nextToken
      }
      claims {
        nextToken
      }
    }
    claimsCanAccess
    claimsCanEdit
    
  }
}
`;
export const createUser = `mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    email
    name
    clients {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    accounts {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    appSettings {
      items {
        key
        value
      }
      nextToken
    }
    isApproved
    claims {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    claimsCanAccess
    claimsCanEdit
    
    version
  }
}
`;
export const updateUser = `mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
    email
    name
    clients {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    accounts {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    appSettings {
      items {
        key
        value
      }
      nextToken
    }
    isApproved
    claims {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    claimsCanAccess
    claimsCanEdit
    
    version
  }
}
`;
export const deleteUser = `mutation DeleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    id
    email
    name
    clients {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    accounts {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    appSettings {
      items {
        key
        value
      }
      nextToken
    }
    isApproved
    claims {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    claimsCanAccess
    claimsCanEdit
    
    version
  }
}
`;
export const createUserClientMap = `mutation CreateUserClientMap($input: CreateUserClientMapInput!) {
  createUserClientMap(input: $input) {
    id
    user {
      id
      email
      name
      clients {
        nextToken
      }
      accounts {
        nextToken
      }
      appSettings {
        nextToken
      }
      isApproved
      claims {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    client {
      id
      code
      name
      description
      logo
      website
      email
      address
      phoneno
      accounts {
        nextToken
      }
      features {
        nextToken
      }
      users {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    claimsCanAccess
    claimsCanEdit
    
  }
}
`;
export const updateUserClientMap = `mutation UpdateUserClientMap($input: UpdateUserClientMapInput!) {
  updateUserClientMap(input: $input) {
    id
    user {
      id
      email
      name
      clients {
        nextToken
      }
      accounts {
        nextToken
      }
      appSettings {
        nextToken
      }
      isApproved
      claims {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    client {
      id
      code
      name
      description
      logo
      website
      email
      address
      phoneno
      accounts {
        nextToken
      }
      features {
        nextToken
      }
      users {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    claimsCanAccess
    claimsCanEdit
    
  }
}
`;
export const deleteUserClientMap = `mutation DeleteUserClientMap($input: DeleteUserClientMapInput!) {
  deleteUserClientMap(input: $input) {
    id
    user {
      id
      email
      name
      clients {
        nextToken
      }
      accounts {
        nextToken
      }
      appSettings {
        nextToken
      }
      isApproved
      claims {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    client {
      id
      code
      name
      description
      logo
      website
      email
      address
      phoneno
      accounts {
        nextToken
      }
      features {
        nextToken
      }
      users {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    claimsCanAccess
    claimsCanEdit
    
  }
}
`;
export const createUserAccountMap = `mutation CreateUserAccountMap($input: CreateUserAccountMapInput!) {
  createUserAccountMap(input: $input) {
    id
    user {
      id
      email
      name
      clients {
        nextToken
      }
      accounts {
        nextToken
      }
      appSettings {
        nextToken
      }
      isApproved
      claims {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    accounts {
      id
      code
      name
      description
      client {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      logo
      website
      email
      address
      phoneno
      claims {
        nextToken
      }
      features {
        nextToken
      }
      users {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    claimsCanAccess
    claimsCanEdit
    
  }
}
`;
export const updateUserAccountMap = `mutation UpdateUserAccountMap($input: UpdateUserAccountMapInput!) {
  updateUserAccountMap(input: $input) {
    id
    user {
      id
      email
      name
      clients {
        nextToken
      }
      accounts {
        nextToken
      }
      appSettings {
        nextToken
      }
      isApproved
      claims {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    accounts {
      id
      code
      name
      description
      client {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      logo
      website
      email
      address
      phoneno
      claims {
        nextToken
      }
      features {
        nextToken
      }
      users {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    claimsCanAccess
    claimsCanEdit
    
  }
}
`;
export const deleteUserAccountMap = `mutation DeleteUserAccountMap($input: DeleteUserAccountMapInput!) {
  deleteUserAccountMap(input: $input) {
    id
    user {
      id
      email
      name
      clients {
        nextToken
      }
      accounts {
        nextToken
      }
      appSettings {
        nextToken
      }
      isApproved
      claims {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    accounts {
      id
      code
      name
      description
      client {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      logo
      website
      email
      address
      phoneno
      claims {
        nextToken
      }
      features {
        nextToken
      }
      users {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    claimsCanAccess
    claimsCanEdit
    
  }
}
`;
export const createUserClaimMap = `mutation CreateUserClaimMap($input: CreateUserClaimMapInput!) {
  createUserClaimMap(input: $input) {
    id
    user {
      id
      email
      name
      clients {
        nextToken
      }
      accounts {
        nextToken
      }
      appSettings {
        nextToken
      }
      isApproved
      claims {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    claim {
      id
      code
      name
      description
      feature {
        id
        code
        name
        description
      }
      accounts {
        nextToken
      }
      users {
        nextToken
      }
    }
    claimsCanAccess
    claimsCanEdit
    
  }
}
`;
export const updateUserClaimMap = `mutation UpdateUserClaimMap($input: UpdateUserClaimMapInput!) {
  updateUserClaimMap(input: $input) {
    id
    user {
      id
      email
      name
      clients {
        nextToken
      }
      accounts {
        nextToken
      }
      appSettings {
        nextToken
      }
      isApproved
      claims {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    claim {
      id
      code
      name
      description
      feature {
        id
        code
        name
        description
      }
      accounts {
        nextToken
      }
      users {
        nextToken
      }
    }
    claimsCanAccess
    claimsCanEdit
    
  }
}
`;
export const deleteUserClaimMap = `mutation DeleteUserClaimMap($input: DeleteUserClaimMapInput!) {
  deleteUserClaimMap(input: $input) {
    id
    user {
      id
      email
      name
      clients {
        nextToken
      }
      accounts {
        nextToken
      }
      appSettings {
        nextToken
      }
      isApproved
      claims {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    claim {
      id
      code
      name
      description
      feature {
        id
        code
        name
        description
      }
      accounts {
        nextToken
      }
      users {
        nextToken
      }
    }
    claimsCanAccess
    claimsCanEdit
    
  }
}
`;
export const createAppSetting = `mutation CreateAppSetting($input: CreateAppSettingInput!) {
  createAppSetting(input: $input) {
    key
    value
  }
}
`;
export const updateAppSetting = `mutation UpdateAppSetting($input: UpdateAppSettingInput!) {
  updateAppSetting(input: $input) {
    key
    value
  }
}
`;
export const deleteAppSetting = `mutation DeleteAppSetting($input: DeleteAppSettingInput!) {
  deleteAppSetting(input: $input) {
    key
    value
  }
}
`;
export const createEvent = `mutation CreateEvent($input: CreateEventInput!) {
  createEvent(input: $input) {
    id
    groupId
    customer {
      id
      code
      name
      description
      logo
      website
      email
      address
      phoneno
      accounts {
        nextToken
      }
      features {
        nextToken
      }
      users {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    name
    description
    type
    sport
    property
    fixture
    bitrateMbps
    bugRequired
    outputs {
      items {
        id
        outputOttApplicationOverride
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    input {
      id
      name
      description
      claimsCanAccess
      claimsCanEdit
      version
    }
    actions {
      items {
        id
        setupOffsetMs
        actionType
        claimsCanAccess
        claimsCanEdit
        version
      }
      nextToken
    }
    lineupTime
    startTime
    endTime
    serviceStartTime
    serviceEndTime
    status
    headTimeMin
    tailTimeMin
    audioMapping {
      items {
        id
        channelNo
        nameFieldL
        nameFieldR
        active
        stereo
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    claimsCanAccess
    claimsCanEdit
    
    version
  }
}
`;
export const updateEvent = `mutation UpdateEvent($input: UpdateEventInput!) {
  updateEvent(input: $input) {
    id
    groupId
    customer {
      id
      code
      name
      description
      logo
      website
      email
      address
      phoneno
      accounts {
        nextToken
      }
      features {
        nextToken
      }
      users {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    name
    description
    type
    sport
    property
    fixture
    attachments
    account {
      id
      code
      name
      description
      client {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      logo
      website
      email
      address
      phoneno
      claims {
        nextToken
      }
      features {
        nextToken
      }
      users {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    bitrateMbps
    bugRequired
    actions (limit: 1500) {
      items {
        actionType
        configWowza {
          id
          targets {
            items {
              application
              id
              streamKey
              target
              type
              version
            }
          }
          server
          type
          autostart
          autostartOffsetMin
        }
        assignedOutput {
          id
          name
          ottPlatform
          outputType
        }
      }
      nextToken
    }
    outputs (limit: 1500) {
      items {
        id
        output {
          id
          name
          description
          outputType
          ottPlatform
        }
      }
    }
    input {
      id
      name
      description
      claimsCanAccess
      claimsCanEdit
      version
    }
    lineupTime
    startTime
    endTime
    status
    timeExtensionMin
    audioMapping {
      items {
        id
        channelNo
        nameFieldL
        nameFieldR
        active
        stereo
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    claimsCanAccess
    claimsCanEdit
    
    version
  }
}
`;
export const deleteEvent = `mutation DeleteEvent($input: DeleteEventInput!) {
  deleteEvent(input: $input) {
    id
    customer {
      id
      code
      name
      description
      logo
      website
      email
      address
      phoneno
      accounts {
        nextToken
      }
      features {
        nextToken
      }
      users {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    name
    description
    type
    sport
    property
    fixture
    account {
      id
      code
      name
      description
      client {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      logo
      website
      email
      address
      phoneno
      claims {
        nextToken
      }
      features {
        nextToken
      }
      users {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    bitrateMbps
    bugRequired
    outputs {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    input {
      id
      name
      description
      claimsCanAccess
      claimsCanEdit
      version
    }
    actions {
      items {
        id
        setupOffsetMs
        actionType
        claimsCanAccess
        claimsCanEdit
        version
      }
      nextToken
    }
    lineupTime
    startTime
    endTime
    status
    timeExtensionMin
    audioMapping {
      items {
        id
        channelNo
        nameFieldL
        nameFieldR
        active
        stereo
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    claimsCanAccess
    claimsCanEdit
    
    version
  }
}
`;
export const startStreamTarget = `mutation StartStreamTarget($eventId: ID, $streamTargetId: ID) {
  startStreamTarget(eventId: $eventId, streamTargetId: $streamTargetId) {
    success
    message
  }
}
`;
export const stopStreamTarget = `mutation StopStreamTarget($eventId: ID, $streamTargetId: ID) {
  stopStreamTarget(eventId: $eventId, streamTargetId: $streamTargetId) {
    success
    message
  }
}
`;
export const restartEvent = `mutation RestartEvent($eventId: ID) {
  restartEvent(eventId: $eventId) {
    success
    message
  }
}
`;
export const restartIncomingStream = `mutation RestartIncomingStream($eventId: ID) {
  restartIncomingStream(eventId: $eventId) {
    success
    message
  }
}
`;
export const createEventOutputMap = `mutation CreateEventOutputMap($input: CreateEventOutputMapInput!) {
  createEventOutputMap(input: $input) {
    id
    outputOttApplicationOverride
    event {
      id
      customer {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      name
      description
      type
      sport
      property
      fixture
      account {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      bitrateMbps
      bugRequired
      outputs {
        nextToken
      }
      input {
        id
        name
        description
        claimsCanAccess
        claimsCanEdit
        version
      }
      actions {
        nextToken
      }
      lineupTime
      startTime
      endTime
      status
      timeExtensionMin
      audioMapping {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    output {
      id
      name
      description
      events {
        nextToken
      }
      outputType
      ottPlatform
      claimsCanAccess
      claimsCanEdit
      version
    }
    claimsCanAccess
    claimsCanEdit
  }
}
`;

export const updateEventOutputMap = `mutation UpdateEventOutputMap($input: UpdateEventOutputMapInput!) {
  updateEventOutputMap(input: $input) {
    id
    outputOttApplicationOverride
    claimsCanAccess
    claimsCanEdit
  }
}
`;
export const deleteEventOutputMap = `mutation DeleteEventOutputMap($input: DeleteEventOutputMapInput!) {
  deleteEventOutputMap(input: $input) {
    id
    outputOttApplicationOverride
    event {
      id
      customer {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      name
      description
      type
      sport
      property
      fixture
      account {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      bitrateMbps
      bugRequired
      outputs {
        nextToken
      }
      input {
        id
        name
        description
        claimsCanAccess
        claimsCanEdit
        version
      }
      actions {
        nextToken
      }
      lineupTime
      startTime
      endTime
      status
      timeExtensionMin
      audioMapping {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    output {
      id
      name
      description
      events {
        nextToken
      }
      outputType
      ottPlatform
      claimsCanAccess
      claimsCanEdit
      version
    }
    claimsCanAccess
    claimsCanEdit
  }
}
`;
export const createEventAudioChannel = `mutation CreateEventAudioChannel($input: CreateEventAudioChannelInput!) {
  createEventAudioChannel(input: $input) {
    id
    channelNo
    nameFieldL
    nameFieldR
    languageL {
      id
      name
      isoCode1
      isoCode2t
      isoCode2b
      isoCode3
    }
    languageR {
      id
      name
      isoCode1
      isoCode2t
      isoCode2b
      isoCode3
    }
    active
    claimsCanAccess
    claimsCanEdit
    
  }
}
`;
export const updateEventAudioChannel = `mutation UpdateEventAudioChannel($input: UpdateEventAudioChannelInput!) {
  updateEventAudioChannel(input: $input) {
    id
    channelNo
    nameFieldL
    nameFieldR
    languageL {
      id
      name
      isoCode1
      isoCode2t
      isoCode2b
      isoCode3
    }
    languageR {
      id
      name
      isoCode1
      isoCode2t
      isoCode2b
      isoCode3
    }
    active
    claimsCanAccess
    claimsCanEdit
    
  }
}
`;
export const deleteEventAudioChannel = `mutation DeleteEventAudioChannel($input: DeleteEventAudioChannelInput!) {
  deleteEventAudioChannel(input: $input) {
    id
    channelNo
    nameFieldL
    nameFieldR
    languageL {
      id
      name
      isoCode1
      isoCode2t
      isoCode2b
      isoCode3
    }
    languageR {
      id
      name
      isoCode1
      isoCode2t
      isoCode2b
      isoCode3
    }
    active
    claimsCanAccess
    claimsCanEdit
    
  }
}
`;
export const createEventAction = `mutation CreateEventAction($input: CreateEventActionInput!) {
  createEventAction(input: $input) {
    id
    setupOffsetMs
    actionType
    configWowza {
      id
      type
      incomingTieline
      server
      targets {
        nextToken
      }
      version
    }
    configRoute {
      id
      source {
        id
        name
        description
        originRouterGroup
        originRouterSourceId
        claimsCanAccess
        claimsCanEdit
        version
      }
      destinations {
        nextToken
      }
      version
    }
    configPlayout {
      id
      version
    }
    configRecording {
      id
      version
    }
    configDownlink {
      id
      version
    }
    configMaintenance {
      id
      serversToRestart
      version
    }
    claimsCanAccess
    claimsCanEdit
    
    version
  }
}
`;
export const updateEventAction = `mutation UpdateEventAction($input: UpdateEventActionInput!) {
  updateEventAction(input: $input) {
    id
    setupOffsetMs
    actionType
    configWowza {
      id
      type
      incomingTieline
      server
      targets {
        nextToken
      }
      autostart
      autostartOffsetMin
      version
    }
    configRoute {
      id
      source {
        id
        name
        description
        originRouterGroup
        originRouterSourceId
        claimsCanAccess
        claimsCanEdit
        version
      }
      destinations {
        nextToken
      }
      version
    }
    configPlayout {
      id
      version
    }
    configRecording {
      id
      version
    }
    configDownlink {
      id
      version
    }
    configMaintenance {
      id
      serversToRestart
      version
    }
    claimsCanAccess
    claimsCanEdit
    
    version
  }
}
`;
export const deleteEventAction = `mutation DeleteEventAction($input: DeleteEventActionInput!) {
  deleteEventAction(input: $input) {
    id
    setupOffsetMs
    actionType
    configWowza {
      id
      type
      incomingTieline
      server
      targets {
        nextToken
      }
      version
    }
    configRoute {
      id
      source {
        id
        name
        description
        originRouterGroup
        originRouterSourceId
        claimsCanAccess
        claimsCanEdit
        version
      }
      destinations {
        nextToken
      }
      version
    }
    configPlayout {
      id
      version
    }
    configRecording {
      id
      version
    }
    configDownlink {
      id
      version
    }
    configMaintenance {
      id
      serversToRestart
      version
    }
    claimsCanAccess
    claimsCanEdit
    
    version
  }
}
`;
export const createEventActionConfigWowza = `mutation CreateEventActionConfigWowza(
  $input: CreateEventActionConfigWowzaInput!
) {
  createEventActionConfigWowza(input: $input) {
    id
    type
    incomingTieline
    server
    targets {
      items {
        id
        type
        application
        target
        streamKey
        version
      }
      nextToken
    }
    autostart
    autostartOffsetMin
    version
  }
}
`;
export const updateEventActionConfigWowza = `mutation UpdateEventActionConfigWowza(
  $input: UpdateEventActionConfigWowzaInput!
) {
  updateEventActionConfigWowza(input: $input) {
    id
    type
    incomingTieline
    server
    targets {
      items {
        id
        type
        application
        target
        streamKey
        version
      }
      nextToken
    }
    autostart
    autostartOffsetMin
    version
  }
}
`;
export const deleteEventActionConfigWowza = `mutation DeleteEventActionConfigWowza(
  $input: DeleteEventActionConfigWowzaInput!
) {
  deleteEventActionConfigWowza(input: $input) {
    id
    type
    incomingTieline
    server
    targets {
      items {
        id
        type
        application
        target
        streamKey
        version
      }
      nextToken
    }
    
    version
  }
}
`;
export const createWowzaTarget = `mutation CreateWowzaTarget($input: CreateWowzaTargetInput!) {
  createWowzaTarget(input: $input) {
    id
    type
    application
    target
    streamKey
    version
  }
}
`;
export const updateWowzaTarget = `mutation UpdateWowzaTarget($input: UpdateWowzaTargetInput!) {
  updateWowzaTarget(input: $input) {
    id
    type
    application
    target
    streamKey
    transcoderProfile
    version
  }
}
`;
export const deleteWowzaTarget = `mutation DeleteWowzaTarget($input: DeleteWowzaTargetInput!) {
  deleteWowzaTarget(input: $input) {
    id
    type
    application
    target
    streamKey
    version
  }
}
`;
export const createEventActionConfigRoute = `mutation CreateEventActionConfigRoute(
  $input: CreateEventActionConfigRouteInput!
) {
  createEventActionConfigRoute(input: $input) {
    id
    source {
      id
      name
      description
      router {
        id
        name
        description
        claimsCanAccess
        claimsCanEdit
        version
      }
      originRouterGroup
      originRouterSourceId
      claimsCanAccess
      claimsCanEdit
      version
    }
    destinations {
      items {
        id
        name
        description
        originRouterGroup
        originRouterDestinationId
        claimsCanAccess
        claimsCanEdit
        version
      }
      nextToken
    }
    
    version
  }
}
`;
export const updateEventActionConfigRoute = `mutation UpdateEventActionConfigRoute(
  $input: UpdateEventActionConfigRouteInput!
) {
  updateEventActionConfigRoute(input: $input) {
    id
    source {
      id
      name
      description
      router {
        id
        name
        description
        claimsCanAccess
        claimsCanEdit
        version
      }
      originRouterGroup
      originRouterSourceId
      claimsCanAccess
      claimsCanEdit
      version
    }
    destinations {
      items {
        id
        name
        description
        originRouterGroup
        originRouterDestinationId
        claimsCanAccess
        claimsCanEdit
        version
      }
      nextToken
    }
    
    version
  }
}
`;
export const deleteEventActionConfigRoute = `mutation DeleteEventActionConfigRoute(
  $input: DeleteEventActionConfigRouteInput!
) {
  deleteEventActionConfigRoute(input: $input) {
    id
    source {
      id
      name
      description
      router {
        id
        name
        description
        claimsCanAccess
        claimsCanEdit
        version
      }
      originRouterGroup
      originRouterSourceId
      claimsCanAccess
      claimsCanEdit
      version
    }
    destinations {
      items {
        id
        name
        description
        originRouterGroup
        originRouterDestinationId
        claimsCanAccess
        claimsCanEdit
        version
      }
      nextToken
    }
    
    version
  }
}
`;
export const createEventActionConfigPlayout = `mutation CreateEventActionConfigPlayout(
  $input: CreateEventActionConfigPlayoutInput!
) {
  createEventActionConfigPlayout(input: $input) {
    id
    
    version
  }
}
`;
export const updateEventActionConfigPlayout = `mutation UpdateEventActionConfigPlayout(
  $input: UpdateEventActionConfigPlayoutInput!
) {
  updateEventActionConfigPlayout(input: $input) {
    id
    
    version
  }
}
`;
export const deleteEventActionConfigPlayout = `mutation DeleteEventActionConfigPlayout(
  $input: DeleteEventActionConfigPlayoutInput!
) {
  deleteEventActionConfigPlayout(input: $input) {
    id
    
    version
  }
}
`;
export const createEventActionConfigRecording = `mutation CreateEventActionConfigRecording(
  $input: CreateEventActionConfigRecordingInput!
) {
  createEventActionConfigRecording(input: $input) {
    id
    
    version
  }
}
`;
export const updateEventActionConfigRecording = `mutation UpdateEventActionConfigRecording(
  $input: UpdateEventActionConfigRecordingInput!
) {
  updateEventActionConfigRecording(input: $input) {
    id
    
    version
  }
}
`;
export const deleteEventActionConfigRecording = `mutation DeleteEventActionConfigRecording(
  $input: DeleteEventActionConfigRecordingInput!
) {
  deleteEventActionConfigRecording(input: $input) {
    id
    
    version
  }
}
`;
export const createEventActionConfigDownlink = `mutation CreateEventActionConfigDownlink(
  $input: CreateEventActionConfigDownlinkInput!
) {
  createEventActionConfigDownlink(input: $input) {
    id
    
    version
  }
}
`;
export const updateEventActionConfigDownlink = `mutation UpdateEventActionConfigDownlink(
  $input: UpdateEventActionConfigDownlinkInput!
) {
  updateEventActionConfigDownlink(input: $input) {
    id
    
    version
  }
}
`;
export const deleteEventActionConfigDownlink = `mutation DeleteEventActionConfigDownlink(
  $input: DeleteEventActionConfigDownlinkInput!
) {
  deleteEventActionConfigDownlink(input: $input) {
    id
    
    version
  }
}
`;
export const createEventActionConfigMaintenance = `mutation CreateEventActionConfigMaintenance(
  $input: CreateEventActionConfigMaintenanceInput!
) {
  createEventActionConfigMaintenance(input: $input) {
    id
    serversToRestart
    
    version
  }
}
`;
export const updateEventActionConfigMaintenance = `mutation UpdateEventActionConfigMaintenance(
  $input: UpdateEventActionConfigMaintenanceInput!
) {
  updateEventActionConfigMaintenance(input: $input) {
    id
    serversToRestart
    
    version
  }
}
`;
export const deleteEventActionConfigMaintenance = `mutation DeleteEventActionConfigMaintenance(
  $input: DeleteEventActionConfigMaintenanceInput!
) {
  deleteEventActionConfigMaintenance(input: $input) {
    id
    serversToRestart
    
    version
  }
}
`;
export const createInput = `mutation CreateInput($input: CreateInputInput!) {
  createInput(input: $input) {
    id
  }
}
`;
export const updateInput = `mutation UpdateInput($input: UpdateInputInput!) {
  updateInput(input: $input) {
    id
    url
    name
    description
    claimsCanAccess
    claimsCanEdit
    version
  }
}
`;
export const deleteInput = `mutation DeleteInput($input: DeleteInputInput!) {
  deleteInput(input: $input) {
    id
    name
    url
    description
    claimsCanAccess
    claimsCanEdit
    
    version
  }
}
`;
export const createOutput = `mutation CreateOutput($input: CreateOutputInput!) {
  createOutput(input: $input) {
    id
  }
}
`;
export const updateOutput = `mutation UpdateOutput($input: UpdateOutputInput!) {
  updateOutput(input: $input) {
    id
    name
    url
    description
    displayName
    ottNoStremKeyRequired
    events {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    outputType
    ottPlatform
    ottServer
    ottApplication
    claimsCanAccess
    claimsCanEdit
    
    version
  }
}
`;
export const deleteOutput = `mutation DeleteOutput($input: DeleteOutputInput!) {
  deleteOutput(input: $input) {
    id
  }
}
`;
export const updateOutputProfile = `mutation UpdateOutputProfile(
  $input: UpdateOutputProfileInput!
  $condition: ModelOutputProfileConditionInput
) {
  updateOutputProfile(input: $input, condition: $condition) {
    id
    name
    description
    claimsCanAccess
    claimsCanEdit
    lastUpdatedBy {
      id
      name
      isApproved
      version
      email
      claimsCanAccess
      claimsCanEdit
      lastUpdatedBy {
        id
        name
        isApproved
        version
        email
        claimsCanAccess
        claimsCanEdit
      }
      clients {
        nextToken
      }
      accounts {
        nextToken
      }
      claims {
        nextToken
      }
      appSettings {
        nextToken
      }
    }
    outputs {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
  }
}
`;
export const createOutputProfile = `mutation CreateOutputProfile(
  $input: CreateOutputProfileInput!
  $condition: ModelOutputProfileConditionInput
) {
  createOutputProfile(input: $input, condition: $condition) {
    id
  }
}
`;

export const createOutputProfileOutputMap = `mutation CreateOutputProfileOutputMap(
  $input: CreateOutputProfileOutputMapInput!
  $condition: ModelOutputProfileOutputMapConditionInput
) {
  createOutputProfileOutputMap(input: $input, condition: $condition) {
    id
  }
}
`;
export const deleteOutputProfileOutputMap = `mutation DeleteOutputProfileOutputMap(
  $input: DeleteOutputProfileOutputMapInput!
  $condition: ModelOutputProfileOutputMapConditionInput
) {
  deleteOutputProfileOutputMap(input: $input, condition: $condition) {
    id
  }
}
`;
export const deleteOutputProfile = `mutation DeleteOutputProfile(
  $input: DeleteOutputProfileInput!
  $condition: ModelOutputProfileConditionInput
) {
  deleteOutputProfile(input: $input, condition: $condition) {
    id
  }
}
`;


export const createRouter = `mutation CreateRouter($input: CreateRouterInput!) {
  createRouter(input: $input) {
    id
    name
    description
    claimsCanAccess
    claimsCanEdit
    version
  }
}
`;
export const updateRouter = `mutation UpdateRouter($input: UpdateRouterInput!) {
  updateRouter(input: $input) {
    id
    name
  }
}
`;
export const deleteRouter = `mutation DeleteRouter($input: DeleteRouterInput!) {
  deleteRouter(input: $input) {
    id
  }
}
`;
export const createRouterGroup = `mutation CreateRouterGroup($input: CreateRouterGroupInput!) {
  createRouterGroup(input: $input) {
    id
  }
}
`;
export const updateRouterGroup = `mutation UpdateRouterGroup($input: UpdateRouterGroupInput!) {
  updateRouterGroup(input: $input) {
    id
  }
}
`;
export const deleteRouterGroup = `mutation DeleteRouterGroup($input: DeleteRouterGroupInput!) {
  deleteRouterGroup(input: $input) {
    id
  }
}
`;

export const createRouterDestinationGroupMap = `mutation CreateRouterDestinationGroupMap(
  $input: CreateRouterDestinationGroupMapInput!
  $condition: ModelRouterDestinationGroupMapConditionInput
) {
  createRouterDestinationGroupMap(input: $input, condition: $condition) {
    id
  }
}
`;
export const deleteRouterDestinationGroupMap = `mutation DeleteRouterDestinationGroupMap(
  $input: DeleteRouterDestinationGroupMapInput!
  $condition: ModelRouterDestinationGroupMapConditionInput
) {
  deleteRouterDestinationGroupMap(input: $input, condition: $condition) {
    id
  }
}
`;

export const createRouterSourceGroupMap = `mutation CreateRouterSourceGroupMap(
  $input: CreateRouterSourceGroupMapInput!
  $condition: ModelRouterSourceGroupMapConditionInput
) {
  createRouterSourceGroupMap(input: $input, condition: $condition) {
    id
  }
}
`;
export const deleteRouterSourceGroupMap = `mutation DeleteRouterSourceGroupMap(
  $input: DeleteRouterSourceGroupMapInput!
  $condition: ModelRouterSourceGroupMapConditionInput
) {
  deleteRouterSourceGroupMap(input: $input, condition: $condition) {
    id
  }
}
`;

export const createOutputRouterDestinationMap = `mutation CreateOutputRouterDestinationMap(
  $input: CreateOutputRouterDestinationMapInput!
  $condition: ModelOutputRouterDestinationMapConditionInput
) {
  createOutputRouterDestinationMap(input: $input, condition: $condition) {
    id
  }
}
`;
export const deleteOutputRouterDestinationMap = `mutation DeleteOutputRouterDestinationMap(
  $input: DeleteOutputRouterDestinationMapInput!
  $condition: ModelOutputRouterDestinationMapConditionInput
) {
  deleteOutputRouterDestinationMap(input: $input, condition: $condition) {
    id
  }
}
`;

export const createInputRouterSourceMap = `mutation CreateInputRouterSourceMap(
  $input: CreateInputRouterSourceMapInput!
  $condition: ModelInputRouterSourceMapConditionInput
) {
  createInputRouterSourceMap(input: $input, condition: $condition) {
    id
  }
}
`;
export const deleteInputRouterSourceMap = `mutation DeleteInputRouterSourceMap(
  $input: DeleteInputRouterSourceMapInput!
  $condition: ModelInputRouterSourceMapConditionInput
) {
  deleteInputRouterSourceMap(input: $input, condition: $condition) {
    id
  }
}
`;

export const createRouterSource = `mutation CreateRouterSource($input: CreateRouterSourceInput!) {
  createRouterSource(input: $input) {
    id
    name
  }
}
`;
export const updateRouterSource = `mutation UpdateRouterSource($input: UpdateRouterSourceInput!) {
  updateRouterSource(input: $input) {
    id
    name
    description
  }
}
`;
export const deleteRouterSource = `mutation DeleteRouterSource($input: DeleteRouterSourceInput!) {
  deleteRouterSource(input: $input) {
    id
  }
}
`;
export const createRouterDestination = `mutation CreateRouterDestination($input: CreateRouterDestinationInput!) {
  createRouterDestination(input: $input) {
    id
    name
  }
}
`;
export const updateRouterDestination = `mutation UpdateRouterDestination($input: UpdateRouterDestinationInput!) {
  updateRouterDestination(input: $input) {
    id
  }
}
`;
export const deleteRouterDestination = `mutation DeleteRouterDestination($input: DeleteRouterDestinationInput!) {
  deleteRouterDestination(input: $input) {
    id
  }
}
`;



export const createClaim = `mutation CreateClaim($input: CreateClaimInput!) {
  createClaim(input: $input) {
    id
    code
    name
    description
    feature {
      id
      code
      name
      description
      clients {
        nextToken
      }
      accounts {
        nextToken
      }
      claims {
        nextToken
      }
    }
    accounts {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    users {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
  }
}
`;
export const updateClaim = `mutation UpdateClaim($input: UpdateClaimInput!) {
  updateClaim(input: $input) {
    id
    code
    name
    description
    feature {
      id
      code
      name
      description
      clients {
        nextToken
      }
      accounts {
        nextToken
      }
      claims {
        nextToken
      }
    }
    accounts {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    users {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
  }
}
`;
export const deleteClaim = `mutation DeleteClaim($input: DeleteClaimInput!) {
  deleteClaim(input: $input) {
    id
    code
    name
    description
    feature {
      id
      code
      name
      description
      clients {
        nextToken
      }
      accounts {
        nextToken
      }
      claims {
        nextToken
      }
    }
    accounts {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    users {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
  }
}
`;
export const createFeature = `mutation CreateFeature($input: CreateFeatureInput!) {
  createFeature(input: $input) {
    id
    code
    name
    description
    clients {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    accounts {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    claims {
      items {
        id
        code
        name
        description
      }
      nextToken
    }
  }
}
`;
export const updateFeature = `mutation UpdateFeature($input: UpdateFeatureInput!) {
  updateFeature(input: $input) {
    id
    code
    name
    description
    clients {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    accounts {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    claims {
      items {
        id
        code
        name
        description
      }
      nextToken
    }
  }
}
`;
export const deleteFeature = `mutation DeleteFeature($input: DeleteFeatureInput!) {
  deleteFeature(input: $input) {
    id
    code
    name
    description
    clients {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    accounts {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    claims {
      items {
        id
        code
        name
        description
      }
      nextToken
    }
  }
}
`;
export const createLanguage = `mutation CreateLanguage($input: CreateLanguageInput!) {
  createLanguage(input: $input) {
    id
    name
    isoCode1
    isoCode2t
    isoCode2b
    isoCode3
  }
}
`;
export const updateLanguage = `mutation UpdateLanguage($input: UpdateLanguageInput!) {
  updateLanguage(input: $input) {
    id
    name
    isoCode1
    isoCode2t
    isoCode2b
    isoCode3
  }
}
`;
export const deleteLanguage = `mutation DeleteLanguage($input: DeleteLanguageInput!) {
  deleteLanguage(input: $input) {
    id
    name
    isoCode1
    isoCode2t
    isoCode2b
    isoCode3
  }
}
`;

export const createOutputAccountMap = `mutation CreateOutputAccountMap(
  $input: CreateOutputAccountMapInput!
  $condition: ModelOutputAccountMapConditionInput
) {
  createOutputAccountMap(input: $input, condition: $condition) {
    id
  }
}
`;
export const createInputAccountMap = `mutation CreateInputAccountMap(
  $input: CreateInputAccountMapInput!
  $condition: ModelInputAccountMapConditionInput
) {
  createInputAccountMap(input: $input, condition: $condition) {
    id
  }
}
`;


export const createOutputEquipmentMap = `mutation CreateOutputEquipmentMap(
  $input: CreateOutputEquipmentMapInput!
  $condition: ModelOutputEquipmentMapConditionInput
) {
  createOutputEquipmentMap(input: $input, condition: $condition) {
    id
  }
}
`;
export const updateOutputEquipmentMap = `mutation UpdateOutputEquipmentMap(
  $input: UpdateOutputEquipmentMapInput!
  $condition: ModelOutputEquipmentMapConditionInput
) {
  updateOutputEquipmentMap(input: $input, condition: $condition) {
    id
  }
}
`;
export const deleteOutputEquipmentMap = `mutation DeleteOutputEquipmentMap(
  $input: DeleteOutputEquipmentMapInput!
  $condition: ModelOutputEquipmentMapConditionInput
) {
  deleteOutputEquipmentMap(input: $input, condition: $condition) {
    id
  }
}
`;

export const deleteOutputAccountMap = `mutation DeleteOutputAccountMap(
  $input: DeleteOutputAccountMapInput!
  $condition: ModelOutputAccountMapConditionInput
) {
  deleteOutputAccountMap(input: $input, condition: $condition) {
    id
  }
}
`;

export const deleteInputAccountMap = `mutation DeleteInputAccountMap(
  $input: DeleteInputAccountMapInput!
  $condition: ModelInputAccountMapConditionInput
) {
  deleteInputAccountMap(input: $input, condition: $condition) {
    id
  }
}
`;

export const deleteInputEquipmentMap = `mutation DeleteInputEquipmentMap(
  $input: DeleteInputEquipmentMapInput!
  $condition: ModelInputEquipmentMapConditionInput
) {
  deleteInputEquipmentMap(input: $input, condition: $condition) {
    id
  }
}
`;


export const createStreamDestination = `mutation CreateStreamDestination($input: CreateStreamDestinationInput!) {
  createStreamDestination(input: $input) {
    id
  }
}
`;
export const createStreamSource = `mutation CreateStreamSource($input: CreateStreamSourceInput!) {
  createStreamSource(input: $input) {
    id
  }
}
`;

export const updateStreamDestination = `mutation UpdateStreamDestination($input: UpdateStreamDestinationInput!) {
  updateStreamDestination(input: $input) {
    id
  }
}
`;
export const updateStreamSource = `mutation UpdateStreamSource($input: UpdateStreamSourceInput!) {
  updateStreamSource(input: $input) {
    id
  }
}
`;

export const deleteStreamSource = `mutation DeleteStreamSource($input: DeleteStreamSourceInput!) {
  deleteStreamSource(input: $input) {
    id
  }
}
`;

export const deleteStreamDestination = `mutation DeleteStreamDestination($input: DeleteStreamDestinationInput!) {
  deleteStreamDestination(input: $input) {
    id
  }
}
`;


export const createTranscoderProfile = `mutation CreateTranscoderProfile($input: CreateTranscoderProfileInput!) {
  createTranscoderProfile(input: $input) {
    id
  }
}
`;

export const deleteTranscoderProfile = `mutation DeleteTranscoderProfile($input: DeleteTranscoderProfileInput!) {
  deleteTranscoderProfile(input: $input) {
    id
  }
}
`;
export const updateTranscoderProfile = `mutation UpdateTranscoderProfile($input: UpdateTranscoderProfileInput!) {
  updateTranscoderProfile(input: $input) {
    id
    name
    description
    transcoderProfile
    claimsCanAccess
    claimsCanEdit
    version
    createdAt
    updatedAt
  }
}
`;
export const startMGWInterface = `mutation StartMGWInterface($interfaceId: ID!) {
  startMGWInterface(interfaceId: $interfaceId) {
    success
    message
  }
}
`;
export const stopMGWInterface = `mutation StopMGWInterface($interfaceId: ID!) {
  stopMGWInterface(interfaceId: $interfaceId) {
    success
    message
  }
}
`;

export const createMultiviewer = `mutation CreateMultiviewer($input: CreateMultiviewerInput!) {
  createMultiviewer(input: $input) {
    id
  }
}
`;

export const deleteMultiviewer = `mutation DeleteMultiviewer($input: DeleteMultiviewerInput!) {
  deleteMultiviewer(input: $input) {
    id
  }
}
`;
export const updateMultiviewer = `mutation UpdateMultiviewer($input: UpdateMultiviewerInput!) {
  updateMultiviewer(input: $input) {
    id
  }
}
`;
export const createStreamSourceMultiviewerMap = `mutation CreateStreamSourceMultiviewerMap($input: CreateStreamSourceMultiviewerMapInput!) {
  createStreamSourceMultiviewerMap(input: $input) {
    id
  }
}
`;

export const deleteStreamSourceMultiviewerMap = `mutation DeleteStreamSourceMultiviewerMap($input: DeleteStreamSourceMultiviewerMapInput!) {
  deleteStreamSourceMultiviewerMap(input: $input) {
    id
  }
}
`;
export const updateStreamSourceMultiviewerMap = `mutation UpdateStreamSourceMultiviewerMap($input: UpdateStreamSourceMultiviewerMapInput!) {
  updateStreamSourceMultiviewerMap(input: $input) {
    id
  }
}
`;
