a<template>
  <div id="audit">
    <!-- Password Change Form -->
      <div>
        <div class="title-card">
          <div>
            <!--
            <el-date-picker
              v-model="filter.dateRange"
              type="daterange"
              align="right"
              start-placeholder="Start Date"
              end-placeholder="End Date">
            </el-date-picker>
            -->
            <el-date-picker
              v-model="filter.invokedDate"
              type="date"
              clearable
              placeholder="Pick a day"
              :picker-options="pickerOptions">
            </el-date-picker>
          </div>
          <div>
            <el-select v-model="filter.linkedType" placeholder="Select linked type" clearable>
              <el-option
                v-for="item in linkedTypes"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </div>
          <div>
            <el-select v-model="filter.actionType" placeholder="Select action type" clearable>
              <el-option
                v-for="item in actionTypes"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </div>

          <div>
            <el-select v-model="filter.account" placeholder="Account (not implemented)" clearable>
              <el-option
                v-for="item in accounts"
                :key="item.account.id"
                :label="item.account.name"
                :value="item.account.name">
              </el-option>
            </el-select>
          </div>
          <div>
            <el-select v-model="filter.user" placeholder="Invoked by (not implemented)" clearable>
              <el-option
                v-for="(item, key) in users"
                :key="key"
                :label="key"
                :value="key">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="content-wrapper"
          v-loading="loading"
          element-loading-text="Loading..."
          element-loading-background="#13191d">
          <div class="item-list infinite-list" v-infinite-scroll="load">
            <div
              v-for="(group, key) in groupCurrentLogs"
              :key="key">
              <el-divider content-position="left">
                <strong>{{key | date('DD/MM/YYYY')}}</strong>
              </el-divider>
              <div class="panel item-list--item"
                v-for="item in group" :key="item.id"
                >
                <div class="item-list--item-date">
                  <div>{{item.invokedDate | date('HH:mm')}}</div>
                </div>
                <div>
                  <span class="name">{{item.actionTaken}}</span>
                  <div class="log-tags">
                    <el-tag size="small" :style="{background: typeColor(item.linkedType)}">
                      {{item.linkedType}}
                    </el-tag>
                    <el-tag size="small" :style="{background: typeColor(item.actionType)}">
                      {{item.actionType}}
                    </el-tag>
                  </div>
                </div>
                <div class="item-list--item-right">
                  <div>{{item.linkedId}}</div>
                  <div class="item-list--item-right-account">
                    <span v-if="item.accountId">{{item.accountId.name}}</span>
                    <span v-if="!item.accountId" class="warning">not set</span>
                  </div>
                  <div class="item-list--item-right-user">
                    <span v-if="item.invokedBy">{{item.invokedBy.name}}</span>
                    <span v-if="!item.invokedBy" class="warning">not set</span>
                  </div>
                </div>
              </div>
            </div>

            <p v-if="loading">Loading...</p>
            <p v-if="noMore">No more entries</p>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import ColorGenerator from '@/services/ColorGenerator';

export default {
  data() {
    return {
      filter: {
        invokedDate: '',
        actionType: '',
        actionTaken: '',
        linkedId: '',
        linkedType: '',
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },

  computed: {
    ...mapState({
      logs: (state) => state.audit.logs,
      loading: (state) => state.audit.loading,
      listClients: (state) => state.userAccount.listClients,
    }),

    avaiableAccounts() {
      const accounts = [];
      if (!this.listClients.items) return [];
      this.listClients.items.forEach((client) => {
        client.accounts.items.forEach((account) => {
          const isDefault = account.code === client.code;
          accounts.push({
            account,
            client,
          });
        });
      });

      return accounts;
    },

    sortedLogs() {
      if (!this.logs.items) return [];
      return this.logs.items
        .sort((left, right) => moment.utc(right.invokedDate).diff(moment.utc(left.invokedDate)));
    },

    groupCurrentLogs() {
      console.log('groupCurrentLogs => ', _.groupBy(this.sortedLogs, (date) => moment(date.invokedDate).startOf('day')));
      return _.groupBy(this.sortedLogs, (date) => moment(date.invokedDate).startOf('day'));
    },

    noMore() {
      return !this.logs.nextToken;
    },

    disabled() {
      return this.loading || this.noMore;
    },

    linkedTypes() {
      if (!this.logs.items) return [];
      return ['Event']; // _.groupBy(this.logs.items,'linkedType');
    },

    actionTypes() {
      if (!this.logs.items) return [];
      return [
        'Application restart',
        'Application stop',
        'StreamKey Change',
        'StreamTarget Stop',
        'StreamTarget Start',
        'Application autostart',
      ]; // _.groupBy(this.logs.items,'actionType');
    },

    accounts() {
      if (!this.logs.items) return [];
      return this.avaiableAccounts; // _.groupBy(this.logs.items,'accountId.name');
    },

    users() {
      if (!this.logs.items) return [];
      return _.groupBy(this.logs.items, 'invokedBy.name');
    },

    typeColor() {
      return (type) => ColorGenerator.stringToColor(type);
    },

    logFilter() {
      const cleanFilter = {};
      const modelStringInput = {
        attributeExists: true,
      };

      if (this.filter.invokedDate && this.filter.invokedDate !== '') {
        cleanFilter.invokedDate = {
          ...modelStringInput,
          eq: this.filter.invokedDate,
        };
      }

      if (this.filter.actionTaken && this.filter.actionTaken !== '') {
        cleanFilter.actionTaken = {
          ...modelStringInput,
          eq: this.filter.actionTaken,
        };
      }

      if (this.filter.actionType && this.filter.actionType !== '') {
        cleanFilter.actionType = {
          ...modelStringInput,
          eq: this.filter.actionType,
        };
      }

      if (this.filter.linkedType && this.filter.linkedType !== '') {
        cleanFilter.linkedType = {
          ...modelStringInput,
          eq: this.filter.linkedType,
        };
      }
      if (_.isEmpty(cleanFilter)) return null;

      return cleanFilter;
    },
  },

  watch: {
    async logFilter(newValue, old) {
      await this.filterAutomationAudits(newValue);
    },
  },

  created() {
    this.listAutomationAudits();
  },

  methods: {
    ...mapActions({
      listAutomationAudits: 'audit/listAutomationAudits',
      filterAutomationAudits: 'audit/filterAutomationAudits',
    }),

    load() {
      if (this.noMore) return;
      this.loading = true;
      console.log('loading more', !this.logs.nextToken);
      setTimeout(() => {
        this.count += 2;
        this.loading = false;
      }, 2000);
    },
  },
};
</script>

<style lang="scss">
@import "../assets/var.scss";
.title-card {
  background: #343c49;
  border-radius: 4px;
  grid-template-columns: 1fr auto auto auto 1fr 1fr 1fr;
  grid-gap: 16px;

  .el-input {
    .el-input__inner {
      color: #fff;
      background: $dark;
      border-color:$dark;
    }
    input {
      background: transparent;
      color: $color;
    }
  }
}
#audit {
    display: grid;
    grid-template-columns: 1fr;

    grid-gap: 20px;
    margin: 20px;
    padding-top: 0;
    .content-wrapper {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 16px;
    }

    .el-divider {
      background-color: transparent;
      &__text {
        background-color: #000;
        padding: 0 20px;
        color: $icon;
        font-weight: 400;
        font-size: 16px;
        width: 140px;
        line-height: 30px;
      }
    }

    .item-list {
      &--item {
        display: grid;
        grid-template-columns: 50px 1fr 1fr;
        margin-bottom: 6px;
        grid-gap: 16px;
        align-items: center;
        border-radius: 4px;
        &:hover {
          outline: 2px solid $color-primary;
          color:#fff !important;
        }
        .name {
          color:#fff;
          margin-right: 16px;
        }
        .log-tags {
          display: inline-block;
        }
        &-right {
          text-align: right;
          display: grid;
          grid-template-columns: 1fr 200px 140px;
          div {
            display: inline-block;
            margin-left: 16px;
          }
          &-account {
            text-align: right;
            color: $color;
          }
        }

        .el-tag {
          color:#fff;
          border: 1px solid transparent;
        }
      }
    }
    .panel {
      position:relative;
      background: $item-bg;
      padding: 8px 16px;
      .el-tag {
        margin-right: 16px;
      }
      &.center {
        text-align:center;
      }
      .title-description {
        margin: 0px;
        color: #73809c;
        padding-bottom: 15px;
      }
      .title {
        font-size: 24px;
        margin: 0;
      }
      svg {
        margin: 42px auto 64px;
        display: block;
      }
      .qr {
        margin: 32px auto 44px;
      }
    }
    h1 {
      color:#fff;
    }
  }
  @media screen and (max-width: 1000px), (orientation: portrait) {
    #audit {
      .title-card {
        grid-template-columns: auto;
        padding-right: 16px;
        .el-select {
          width: 100%;
        }
      }
      .item-list {
        &--item {
          display: grid;
          grid-template-columns: auto;
          &-date {
            position: absolute;
            top: 14px;
            left: 14px;

            font-size: 12px;
          }
          .name {
            margin-top: 10px;
            padding-top: 24px;
            display: inline-block;
          }
          .log-tags {
            position: absolute;
            top: 10px;
            right: 8px;

            .el-tag {
              margin-right: 0px;
              margin-left: 8px;
              font-size: 11px;
              border-radius: 3px;
            }
          }
          &-right {
            text-align: left;
            grid-template-columns: 1fr;
            div {
              margin-left: 0px;
            }
            &-account {
              text-align: left;
            }
          }

          .el-tag {
            color:#fff;
            border: 1px solid transparent;
          }
        }
      }
    }
  }
</style>
