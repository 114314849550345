import axios from 'axios';

import config from '../config';

class ScheduleService {
  constructor(options) {
    this.config = config;
    this.options = options;
    this.url = config.url;
    this.apiUrl = config.apiUrl;
  }

  async getSchedule() {
    return axios.get(`${this.url}SchedulerEventsWowza`);
  }

  async getStatus() {
    return axios.get(`${this.url}wowzastatus`);
  }

  async saveSchedule() {
    return axios.put(`${this.url}SchedulerEventsWowza`);
  }

  async uploadSchedule() {
    return axios.put(`${this.url}SchedulerImport`);
  }

  async startTarget(eventItem, index) {
    const command = {
      id: 'unset',
      command: 'start_target',
      application: eventItem.application,
      serverId: eventItem.server,
      streamKey: '',
      streamFile: '',
      target: eventItem.targets[index],
    };

    return axios.post(`${this.url}WowzaStreamTarget`, command);
  }

  async stopTarget(eventItem, index) {
    const command = {
      id: 'unset',
      command: 'stop_target',
      application: eventItem.application,
      serverId: eventItem.server,
      streamKey: '',
      streamFile: '',
      target: eventItem.targets[index],
    };

    return axios.post(`${this.url}WowzaStreamTarget`, command);
  }

  async restartApplication(eventItem) {
    const command = {
      id: 'unset',
      command: 'restart_application',
      application: eventItem.application,
      serverId: eventItem.server,
      streamKey: '',
      streamFile: eventItem.application,
      target: 'dummy',
    };
    return axios.post(`${this.url}WowzaStreamTarget`, command);
  }

  async updateStreamKey(eventItem, index) {
    const command = {
      id: 'unset',
      command: 'set_streamkey',
      application: eventItem.application,
      serverId: eventItem.server,
      streamKey: eventItem.streamKeys[index],
      streamFile: '',
      itemId: eventItem.id,
      target: eventItem.targets[index],
    };
    return axios.post(`${this.url}WowzaStreamTarget`, command);
  }

  async extendTime(eventItem, alwaysZero, time) {
    const command = {
      id: 'unset',
      command: 'set_tail',
      application: eventItem.application,
      serverId: eventItem.server,
      streamKey: eventItem.streamKeys[0],
      streamFile: '',
      target: eventItem.targets[0],
      itemId: eventItem.id,
      value: time,
    };
    return axios.post(`${this.url}WowzaStreamTarget`, command);
  }
}

export default new ScheduleService();
