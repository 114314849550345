a<template>
  <div id="user-profile">
    <!-- Password Change Form -->
      <div>
        <div class="title-card">
          <div class="title-card-top">
            <div class="title-card-top-icon">
              <el-avatar>CN</el-avatar>
            </div>
            <div class="title-card-top-name">
              {{user.attributes.email}}
            </div>
            <div class="title-card-top-actions">
            </div>
          </div>
          <div class="title-card-bottom"></div>
        </div>
        <div class="content-wrapper">
          <div class="panel">
            <el-form
              :model="passwordChangeForm"
              :rules="passwordChangeRules"
              @submit.native.prevent
              ref="passwordChangeForm"
              class="form"
              label-width="120px"
              label-position="top">

              <h1 class="title">Change password</h1>
              <p class="title-description">Enter old and new password</p>

              <el-form-item v-if="error">
                <el-alert :title="error" v-if="error" type="error" :closable="false"></el-alert>
              </el-form-item>

              <el-form-item prop="oldPassword" label="Enter old password">
                <el-input
                  :autofocus="true"
                  placeholder="Old Password"
                  type="password"
                  v-model="passwordChangeForm.oldPassword"
                  @keyup.enter.native="changePassword('passwordChangeForm')">
                </el-input>
              </el-form-item>

              <el-form-item prop="newPassword" label="Enter new password">
                <el-input
                  placeholder="New Password"
                  type="password"
                  v-model="passwordChangeForm.newPassword"
                  @keyup.enter.native="changePassword('passwordChangeForm')">
                </el-input>
              </el-form-item>

              <el-form-item prop="confirmPassword" label="Confirm new password">
                <el-input
                  placeholder="Confirm Password"
                  type="password"
                  v-model="passwordChangeForm.confirmPassword"
                  @keyup.enter.native="changePassword('passwordChangeForm')">
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  @click="changePassword('passwordChangeForm')"
                  :loading="isBtnLoading">{{btnText('Change Password')}}
                </el-button>
              </el-form-item>
            </el-form>
          </div>

          <div class="panel"
            :class="faClass"
            >
            <div class="s1" v-show="faStep === 1">
              <h1 class="title">Enable 2 factor authentication</h1>
              <p class="title-description">
                Set up one-time password (use a QR code and MFA app to save
                a token on your mobile device)
              </p>
              <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 64 64"><g class="nc-icon-wrapper" fill="#ff9f31"><circle cx="32" cy="38" r="2" fill="#ff9f31"/><path d="M56.242,8.031l-24-6a.968.968,0,0,0-.484,0l-24,6A1,1,0,0,0,7,9V37a25,25,0,0,0,50,0V9A1,1,0,0,0,56.242,8.031ZM25,23a7,7,0,0,1,14,0v5H37V23a5,5,0,0,0-10,0v5H25ZM43,45a1,1,0,0,1-1,1H22a1,1,0,0,1-1-1V31a1,1,0,0,1,1-1H42a1,1,0,0,1,1,1Z" fill="#ff9f31"/></g></svg>
              <!--<amplify-set-mfa v-bind:mfaConfig="mfaConfig"></amplify-set-mfa>-->
              <el-button
                type="primary"
                @click="startFaSetup"
                :loading="isBtnLoading">{{btnText('Enable two factor authentication')}}
              </el-button>
            </div>
            <div class="s2" v-show="faStep === 2">
              <h1 class="title">Enable 2 factor authentication</h1>
              <p class="title-description">
                Scan QR below to download the app and then enter the verifictaion code.
                We recommend using Google Authenticator app on your mobile phone
              </p>
              <div class="qr">
                <qrcode-vue :value="qrCode"
                  size="128"
                  background="#1b242e"
                  foreground="#fff">
                </qrcode-vue>
              </div>
              <p class="title-description"></p>
              <el-form>
                <el-form-item>
                  <el-input
                    :autofocus="true"
                    placeholder="Verification code"
                    type="text"
                    v-model="faVerificationCode"
                    @keyup.enter.native="verifyFa(faVerificationCode)">
                  </el-input>
                  <div class="el-form-item__error">
                    {{faError}}
                  </div>
                </el-form-item>
                <br>
                <el-form-item>
                  <el-button
                    type="text"
                    @click="faStep = 1"
                    :loading="isBtnLoading">{{btnText('Cancel')}}
                  </el-button>
                  <el-button
                    type="primary"
                    @click="verifyFa(faVerificationCode)"
                    :loading="isBtnLoading">{{btnText('Verify')}}
                  </el-button>
                </el-form-item>
              </el-form>
            </div>
            <div class="s3" v-show="faStep === 3">
              <h1 class="title">Two factor authentication enabled</h1>
              <br><br><br>
              <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 48 48"><g class="nc-icon-wrapper" fill="#8bc34a"><path data-color="color-2" d="M36,29a9,9,0,1,0,9,9A9.01,9.01,0,0,0,36,29Zm5.707,6.707-7,7a1,1,0,0,1-1.414,0l-3-3a1,1,0,0,1,1.414-1.414L34,40.586l6.293-6.293a1,1,0,0,1,1.414,1.414Z"/><path d="M25.191,40H8a1,1,0,0,1-1-1V7A1,1,0,0,1,8,6H34a1,1,0,0,1,1,1V27.051c.33-.03.662-.051,1-.051s.67.021,1,.051V6a5.006,5.006,0,0,0-5-5H10A5.006,5.006,0,0,0,5,6V42a5.006,5.006,0,0,0,5,5H29.7A11.013,11.013,0,0,1,25.191,40Z" fill="#8bc34a"/></g></svg>
              <el-button
                type="primary"
                v-loading="isBtnLoading"
                @click="disableFA()">
                Turn off two factor authentication
              </el-button>
            </div>
          </div>

        </div>
      </div>
  </div>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify';
import Vue from 'vue';
import QrcodeVue from 'qrcode.vue';
import * as queries from '../graphql/queries';

export default {
  components: {
    QrcodeVue,
  },
  data() {
    return {
      phoneNumber: null,
      passwordChangeForm: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      queries,
      passwordChangeRules: {
        oldPassword: [
          { required: true, message: 'Please enter old passwrod', trigger: 'blur' },
          { min: 8, message: 'Length should be atleast 8', trigger: 'blur' },
        ],
        newPassword: [
          { required: true, message: 'Please enter new passwrod', trigger: 'blur' },
          { min: 8, message: 'Length should be atleast 8', trigger: 'blur' },
        ],
        confirmPassword: [
          { required: true, message: 'Please confirm new password', trigger: 'blur' },
          { min: 8, message: 'Length should be atleast 8', trigger: 'blur' },
        ],

      },
      mfaConfig: {
        mfaDescription: 'lorem ipsum sit dolor',
        mfaTypes: ['SMS', 'TOTP'],
      },
      faClass: 'center',
      faStep: 0,
      faVerificationCode: null,
      faError: null,
      qrCode: 'x',
      // UI Stuff
      error: null,
      isBtnLoading: false,
      user: null,
      userAttributes: null,
    };
  },

  computed: {
  },

  created() {
    this.getUserProfile();
  },

  methods: {
    btnText(text) {
      if (this.isBtnLoading) return 'Loading...';
      return text;
    },

    async startFaSetup() {
      const code = await this.$Amplify.Auth.setupTOTP(this.user);
      const { username } = this.user;
      const issuer = 'ElasTech Console';
      this.faStep = 2;
      this.qrCode = `otpauth://totp/AWSCognito:${username}?secret=${code}&issuer=${issuer}`;
    },

    async verifyFa(code) {
      try {
        const result = await this.$Amplify.Auth.verifyTotpToken(this.user, code);
        const isSuccess = result.Status === 'SUCCESS';
        if (!isSuccess) return;
        await this.$Amplify.Auth.setPreferredMFA(this.user, 'TOTP');
        this.faStep = 3;
      } catch (error) {
        this.faError = 'Code mismatch and fail enable Software Token MFA';
      }
    },

    async disableFA() {
      this.isBtnLoading = true;
      try {
        await this.$Amplify.Auth.setPreferredMFA(this.user, 'NOMFA');
        this.faStep = 1;
        this.isBtnLoading = false;
      } catch (error) {
        this.faError = 'Something went wrong!';
        this.isBtnLoading = false;
      }
    },

    async setPhoneNumber(phoneNumber) {
      await this.$Amplify.Auth.updateUserAttributes(this.user, {
        phone_number: phoneNumber,
      });
      await this.$Amplify.Auth.currentAuthenticatedUser();
      await this.$Amplify.Auth.verifyCurrentUserAttribute('phone_number');
    },

    async getUserProfile() {
      const result = await this.$Amplify.Auth.currentAuthenticatedUser();

      const { attributes } = result;
      this.userAttributes = attributes;
      Vue.set(this, 'user', result);
      await API.graphql(
        graphqlOperation(this.queries.getUser, { id: this.user.username }),
      );

      const is2FAEnabled = result.preferredMFA === 'SOFTWARE_TOKEN_MFA';
      if (is2FAEnabled) this.faStep = 3;
      if (!is2FAEnabled) this.faStep = 1;
    },
    async changePassword(formName) {
      const form = this.$refs[formName];
      const isFormValid = await form.validate();
      const arePasswordMatch = this.passwordChangeForm.confirmPassword
        === this.passwordChangeForm.newPassword;

      if (!isFormValid || !arePasswordMatch) {
        this.error = 'Form is invalid.';
        return;
      }

      try {
        this.isBtnLoading = true;

        await this.$Amplify.Auth.changePassword(
          this.user,
          this.passwordChangeForm.oldPassword,
          this.passwordChangeForm.newPassword,
        );
        this.isBtnLoading = false;
        this.$message('Password has been changed.');
        this.passwordChangeForm = {
          oldPassword: '',
          newPassword: '',
          confirmPassword: '',
        };
      } catch (error) {
        this.error = error.message;
        this.isBtnLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../assets/var.scss";
$bg: #1b242e;

  #user-profile {
    display: grid;
    grid-template-columns: 1fr;

    grid-gap: 20px;
    margin: 20px;
    padding-top: 0;
    .content-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 16px;
    }
    .panel {
      position:relative;
      background: $bg;
      padding: 30px;
      &.center {
        text-align:center;
      }
      .title-description {
        margin: 0px;
        color: #73809c;
        padding-bottom: 15px;
      }
      .title {
        font-size: 24px;
        margin: 0;
      }
      svg {
        margin: 42px auto 64px;
        display: block;
      }
      .qr {
        margin: 32px auto 44px;
      }
    }
    h1 {
      color:#fff;
    }
  }
  @media screen and (max-width: 1300px) and (orientation: landscape) {
    #user-profile {
      padding: 0px;
      .content-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 16px;
      }
    }
  }
  @media screen and (max-width: 1200px) and (orientation: portrait) {
    #user-profile {
      padding: 0px;
      .title-card {
        grid-template-columns: 1fr auto auto auto;
      }
      .content-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 16px;
      }
    }
  }
</style>
