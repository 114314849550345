<template>
    <div id="reports">
      <div class="content-wrapper">
        <div class="data-header">
          <div class="data-header-tabs">
            <span
              class="data-header-tabs-tab"
              :class="tabClass('booking')"
              @click="changeContext('booking')">Bookings Report</span>
          </div>
          <div class="data-header-actions">
            <span class="action-info"><i class="bi bly-info"></i> Click to select booking</span>
          </div>
        </div>
        <div class="data-content">
          <div>
            <!-- TOOLBAR -->
            <!-- TOOLBAR -->
            <!-- TOOLBAR -->
            <div class="title-card">
              <div class="title-card-filter">
                <div>
                  <el-date-picker
                    v-model="bookingFilter.startDate"
                    type="date"
                    placeholder="Pick a day"
                    :picker-options="pickerOptions">
                  </el-date-picker>
                </div>
                <div>
                  <el-date-picker
                    v-model="bookingFilter.endDate"
                    type="date"
                    placeholder="Pick a day"
                    :picker-options="pickerOptions">
                  </el-date-picker>
                </div>
                <div>
                  <el-switch
                    v-model="showAllEvents"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    active-text="See all bookings">
                  </el-switch>
                </div>
              </div>
              <div class="title-card-top-actions">
                <el-tooltip content="Create table from selected events">
                  <bly-link-button
                    v-show="hasSelectedEvents"
                    @click.native="createTable()"
                    :type="'clear'"><i class="bi bly-transcript"></i>
                  </bly-link-button>
                </el-tooltip>
                <el-tooltip content="download csv file">
                  <bly-link-button
                    @click.native="generateReport(currentEvents)"
                    :type="'clear'"><i class="bi bly-download"></i>
                  </bly-link-button>
                </el-tooltip>
              </div>
            </div>

            <!-- CONTENT -->
            <!-- CONTENT -->
            <!-- CONTENT -->
            <div class="content-wrapper"
              v-loading="loadings.calendar"
              element-loading-text="Loading..."
              element-loading-background="#13191d">
              <div class="item-list infinite-list" v-infinite-scroll="load">
                <div class="item-list-header">
                  <div class="event-serviceStart">Service <br>start</div>
                  <div class="event-startTime">Line-up</div>
                  <div class="event-startTime">Start</div>
                  <div class="event-endTime">End</div>
                  <div class="event-serviceEnd">Service <br>end</div>
                  <div class="event-headTimeMin">Head Min</div>
                  <div class="event-tailTimeMin">Tail Min</div>
                  <div class="event-duration">Duration</div>
                  <div class="event-duration">Service  <br>duration</div>
                  <div class="event-duration">Property</div>
                  <div class="event-duration">Sport</div>
                  <div class="event-duration">Input</div>
                  <div class="event-duration">Outputs</div>
                </div>
                <div
                  v-for="(group, key) in groupCurrentEvents"
                  :key="key">
                  <el-divider content-position="left">
                    <strong>{{key | date('DD/MM/YYYY')}}</strong>
                  </el-divider>
                  <bly-event-item
                    :item="item"
                    :txreport="txReports[item.id]"
                    v-for="item in group"
                    :key="item.id"
                    :class="{active: selectedEvents[item.id], xxxx: !selectedEvents[item.id] }"
                    @click.native="selectedEvents[item.id] = !selectedEvents[item.id]">
                  </bly-event-item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-html="statusStyle"></div>
      <!-- -------------------------------------------------------------------------- -->
    <!-- ADD OUTPUTS DIALOG ------------------------------------------------------- -->
    <!-- -------------------------------------------------------------------------- -->
    <el-dialog
      title="Selected bookings"
      :visible.sync="showSelectedEventsTable"
      :close-on-click-modal="false"
      width="100%">
      <div class="dialog-form">
          <table id="generatedTable" class="bordered" cellspacing="0" cellpadding="0">
            <thead>
              <tr>
                <th><strong>Date<br>(KO)</strong></th>
                <th><strong>Date<br>(line up)</strong></th>
                <th><strong>Line up<br>(GMT)</strong></th>
                <th><strong>Kick Off<br>(GMT)</strong></th>
                <th><strong>End Time<br>(GMT)</strong></th>
                <th><strong>Client</strong></th>
                <th><strong>Sport</strong></th>
                <th><strong>Property</strong></th>
                <th><strong>Fixture</strong></th>
                <th><strong>Outgoing Audio</strong></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="event in currentSelectedEvents"
                :key="event.id">
                <td width="80px">
                  {{event.startTime | dateUtc('DD/mm/YYYY')}}
                </td>
                <td width="80px">
                  {{event.lineupTime | dateUtc('DD/mm/YYYY')}}
                </td>
                <td width="80px">
                  {{event.lineupTime | dateUtc('HH:mm:ss')}}
                </td>
                <td width="80px">
                  {{event.startTime | dateUtc('HH:mm:ss')}}
                </td>
                <td width="80px">
                  {{event.endTime | dateUtc('HH:mm:ss')}}
                </td>
                <td width="100px">{{event.customer.name}}</td>
                <td width="100px">{{event.sport}}</td>
                <td width="200px">{{event.property}}</td>
                <td width="200px">{{event.fixture}}</td>
                <td>
                    <div class="audio-table" v-for="audio in event.audioMapping.items"
                      :key="audio.id">
                        {{audio.channelNo}} {{audio.description}}
                    </div>
                </td>
              </tr>
            </tbody>
          </table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="copyToClipboard('generatedTable')">Copy table to clipboard</el-button>
        <el-button type="primary" @click="showSelectedEventsTable = false" v-loading="loading">
          OK
        </el-button>
      </span>
    </el-dialog>
    </div>
</template>
<script>
import moment from 'moment';
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';

export default {
  data() {
    return {
      activeContext: 'booking',
      showSelectedEventsTable: false,
      showAllEvents: false,
      bookingFilter: {
        startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
        endDate: new Date(),
      },
      load: false,
      pickerOptions: {
        disabledDate(time) {
          return false;
        },
      },
      selectedEvents: {},
      currentSelectedEvents: [],
      txReports: null,
    };
  },

  computed: {
    ...mapState({
      userId: (state) => state.userAccount.userId,
      aclEx: (state) => state.userAccount.userPermissions,
      isAdmin: (state) => state.userAccount.isAdmin,
      isSysAdmin: (state) => state.userAccount.isSysAdmin,
      acl: (state) => state.userAccount.aclCurrent,
      showDataFromAllAccounts: (state) => state.userAccount.showDataFromAllAccounts,
      account: (state) => state.userAccount.account,

      loading: (state) => state.schedule.loading,
      loadings: (state) => state.schedule.loadings,
      listEvents: (state) => state.schedule.listEvents,
      eventStatuses: (state) => state.schedule.eventStatuses,
      eventTypes: (state) => state.schedule.eventTypes,
      eventReports: (state) => state.schedule.eventReports,
      scheduleUIConfig: (state) => state.schedule.scheduleUIConfig,
    }),
    tabClass() {
      return (tab) => {
        const isCurrent = tab === this.activeContext;
        return isCurrent ? 'active' : '';
      };
    },
    lastWeek() {
      return new Date(new Date().setDate(new Date().getDate() - 7));
    },

    hasSelectedEvents() {
      return this.currentEvents.filter((event) => this.selectedEvents[event.id]).length > 0;
    },

    currentEvents() {
      if (!this.listEvents && !this.listEvents.items) return [];
      const events = this.listEvents.items ? this.listEvents.items : [];
      const mappedEvents = events.filter((event) => {
        const isAssignToCurrentAccount = this.account.id === event.account.id;
        if (this.showAllEvents) return true;
        return isAssignToCurrentAccount;
      })
        .map((item) => {
          const nowUtc = moment().utc();
          const newItem = {
            ...item,
          };

          newItem.inputName = item.input ? item.input.name : '';
          newItem.audio = '';
          item.audioMapping.items.forEach((channel) => {
            newItem.audio
              += `${channel.channelNo} ${channel.description}
            `;
          });
          newItem.output = '';
          item.outputs.items.forEach((el) => {
            newItem.output += `
              <div>
                ${el.output.name} 
                <span class="output-type-tag ${el.output.outputType}">${el.output.outputType}</span>
              </div>
            `;

            if (el.outputOttApplicationOverride) {
              console.log('%creport outpur', 'color:green', el);
              newItem.output += `<span class="muted" >(OTT Override: ${el.outputOttApplicationOverride})</span>
              `;
            }
          });
          if (newItem.output === '') newItem.output = 'not set';
          if (newItem.inputName === '') newItem.inputName = 'not set';
          console.log('===> ', newItem);
          newItem.overrideInputName = item.inputOverride ? item.inputOverride.name : '';

          if (newItem.overrideInputName !== '') {
            newItem.inputName += ` --> ${newItem.overrideInputName} (OVERRIDE) `;
          }
          return newItem;
        });
      return mappedEvents;
    },

    groupCurrentEvents() {
      return _.groupBy(this.currentEvents, (date) => moment(date.startTime).startOf('day'));
    },
    statusStyle() {
      let styles = '<style>';
      Object.keys(this.scheduleUIConfig.eventColors).forEach((key) => {
        const value = this.scheduleUIConfig.eventColors[key];
        // agenda view header color
        styles += `.agenda-day-event-name.${key} {
          background: ${value} !important;
          border-color: ${value} !important;
        }`;
        styles += `.theme-mini .bly-event-item-name.${key}:before {
          background: ${value} !important;
          border-color: ${value} !important;
        }`;
        styles += `.theme-mini .bly-event-item-name.${key} {
          color: ${value} !important;
        }`;
      });
      styles += '</style>';
      return styles;
    },
  },

  watch: {
    currentEvents(newValue) {
      newValue.forEach((event) => {
        Vue.set(this.selectedEvents, event.id, !!this.selectedEvents[event.id]);
      });
    },
    bookingFilter: {
      handler(newVal) {
        const start = moment.utc(newVal.startDate).toISOString();
        const end = moment(newVal.endDate).add('days', 1).format();
        this.getBookings(start, end);
      },
      deep: true,
    },
  },

  mounted() {
    this.getBookings(this.lastWeek, new Date());
  },

  methods: {
    ...mapActions({
      fetchEvents: 'schedule/fetchEvents',
      fetchEventsByDate: 'schedule/fetchEventsByDate',
      listEventReports: 'schedule/listEventReports',
    }),
    changeContext(context) {
      this.activeContext = context;
    },

    async getBookings(startDate, endDate) {
      const dateRange = {
        start: startDate,
        end: endDate,
      };
      const result = await this.fetchEventsByDate(dateRange);
      const resultTXReports = await this.listEventReports();
      console.log('result => ', result, resultTXReports);
      this.txReports = {};
      resultTXReports.forEach((report) => {
        if (!report.event) return;
        console.log('report', report);
        if (!this.txReports[report.event.id]) this.txReports[report.event.id] = [];
        this.txReports[report.event.id].push(report);
      });
    },
    createTable() {
      this.currentSelectedEvents = this.currentEvents.filter((event) => this.selectedEvents[event.id]);

      this.showSelectedEventsTable = true;
    },
    generateReport(reportItems) {
      const rowHeader = [
        'Status',
        'Fixture',
        'Description',
        'Service start time',
        'Line-up',
        'Start Time',
        'End Time',
        'Service end time',
        'Tail Time Min',
        'Head Time Min',
        'Duration',
        'Service Duration',
        'Sport',
        'Property',
        'Client',
        'Input',
        'Outputs',
        'TX Report',
        'Other',
      ];
      const rows = [];
      rows.push(rowHeader);
      reportItems.forEach((row) => {
        const rowArray = [];

        // duration
        const start = moment(row.lineupTime);
        const end = moment(row.endTime);
        const ms = end.diff(start);
        const d = moment.duration(ms);
        const duration = Math.floor(d.asHours()) + moment.utc(ms).format(':mm');

        const serviceStart = moment.utc(row.serviceStartTime);
        const serviceEnd = moment.utc(row.serviceEndTime);
        const serviceMs = serviceEnd.diff(serviceStart);
        const serviceD = moment.duration(serviceMs);
        const serviceDuration = Math.floor(serviceD.asHours()) + moment.utc(serviceMs).format(':mm');

        let outputs = '';
        row.outputs.items.forEach((el) => {
          const override = `(OTT Override: ${el.outputOttApplicationOverride})`;
          outputs += `${el.output.name} ${el.outputOttApplicationOverride ? override : ''} | `;
        });

        const format = 'YYYY-MM-DD HH:mm';
        const ntrDesc = this.txReports[row.id] && this.txReports[row.id].ntr ? 'NTR' : '';
        const ntr = this.txReports[row.id] ? ntrDesc : '--- nothing reported ----';
        const report = this.buildReportString(this.txReports[row.id]);
        const desc = row.description ? row.description.split('\n').join(' | ') : '';
        const cleanNTR = ntr ? ntr.split('\n').join(' | ').split(',').join('--') : ' - ';
        let input = row.inputName;
        if (row.inputFrcEnabled) input += ' [FRC Enabled]';

        let other = '';

        if (row.commentaryLanguage) other += `[Commentary Language: ${row.commentaryLanguage}]\r`;
        rowArray.push(row.status);
        rowArray.push(`"${row.fixture}"`);
        rowArray.push(`"${desc}"`);
        rowArray.push(moment.utc(row.serviceStartTime).format(format));
        rowArray.push(moment.utc(row.lineupTime).format(format));
        rowArray.push(moment.utc(row.startTime).format(format));
        rowArray.push(moment.utc(row.endTime).format(format));
        rowArray.push(moment.utc(row.serviceEndTime).format(format));
        rowArray.push(row.tailTimeMin);
        rowArray.push(row.headTimeMin);
        rowArray.push(duration);
        rowArray.push(serviceDuration);
        rowArray.push(row.sport);
        rowArray.push(`"${row.property}"`);
        rowArray.push(`${row.customer.name} - ${row.account.name}`);
        rowArray.push(input);
        rowArray.push(outputs);
        rowArray.push(report);
        rowArray.push(other);
        rows.push(rowArray);
      });

      console.log('rows', rows);
      const csvContent = rows.map((e) => e.join(',')).join('\n');
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const filename = `report-${new Date().toISOString()}.csv`;
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }

      this.generateReportDialog = false;
    },

    buildReportString(reports) {
      let reportString = '"';
      if (!reports) return '--- nothing reported ----';
      reports.forEach((report) => {
        reportString += report.ntr ? 'NTR' : report.description;
      });
      reportString += '"';
      return reportString;
    },

    copyToClipboard(containerid) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(document.getElementById(containerid));
      selection.removeAllRanges(); // Remove all ranges from the selection.
      selection.addRange(range);
      document.execCommand('Copy');
      this.$message.success('Copied to clipboard');
    },
  },
};
</script>
<style lang="scss">
@import '../assets/var.scss';
#reports {
  margin: 10px 20px;
  .action-info {
    i {
      color: $blue-light;
    }
  }
  table.bordered {
    border:1px solid #000;
    td, th {
      border: 1px solid #000;
      padding: 3px;
      word-break: break-word;
      text-align: center;
      font-size: 8.0pt;
    }
    .audio-table {
      border-bottom: 1px solid #000;
      margin: -3px;
      padding: 15px 10px;
      text-align: left;
      &:last-child {
        border-bottom: 0px;
      }
    }
  }
  .title-card {
    grid-template-columns: auto 200px;
    align-items: center;
    .title-card-filter {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
      gap: 20px;
      align-items: center;
      align-content: center;
    }
    .el-switch__label {
      color: $color;
    }
  }
  .el-divider {
    background-color: transparent;
    &__text {
      background-color: #000;
      padding: 0 20px;
      color: $icon;
      font-weight: 400;
      font-size: 16px;
      width: 140px;
      line-height: 30px;
    }
  }
  .panel {
    position:relative;
    background: $item-bg;
    padding: 8px 16px;
    .el-tag {
      margin-right: 16px;
    }
    &.center {
      text-align:center;
    }
    .title-description {
      margin: 0px;
      color: #73809c;
      padding-bottom: 15px;
    }
    .title {
      font-size: 24px;
      margin: 0;
    }
    svg {
      margin: 42px auto 64px;
      display: block;
    }
    .qr {
      margin: 32px auto 44px;
    }
  }
  h1 {
    color:#fff;
  }
  .item-list {
    .item-list-header {
      display: grid;
      grid-template-columns: 65px 65px 65px 65px 65px 65px 65px 80px 80px 180px 100px 120px auto;
      align-items: center;
      padding: 8px 16px;
      background: $item-bg;
      color: $color;
    }
    &--item {
      display: grid;
      grid-template-columns: 50px 1fr 1fr;
      margin-bottom: 6px;
      grid-gap: 16px;
      align-items: center;
      border-radius: 4px;
      &-event {
        grid-template-columns: 5px 1fr 1fr 1fr 1fr 1fr 1fr;
        .event {
          &-fixture {
            grid-column: 5 span / 1;
          }
        }
      }
      &:hover {
        outline: 2px solid $color-primary;
        color:#fff !important;
      }
      .name {
        color:#fff;
        margin-right: 16px;
      }
      .log-tags {
        display: inline-block;
      }
      &-right {
        text-align: right;
        display: grid;
        grid-template-columns: 1fr 200px 140px;
        div {
          display: inline-block;
          margin-left: 16px;
        }
        &-account {
          text-align: right;
          color: $color;
        }
      }

      .el-tag {
        color:#fff;
        border: 1px solid transparent;
      }
    }
  }
  @media screen and (max-width: 1174px) {
  .item-list {
    .item-list-header {
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    }
  }
}
}
</style>
