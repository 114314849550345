<template>
  <div id="router" class="router">
    <div></div>

    <div class="container-with-sidebar">
      <div class="sidebar"></div>
      <div class="p-3">
        <div class="server" v-for="server in servers" :key="server.id">
          <div class="text-lg mb-2 cursor-pointer" @click="toggleServer(server.id)">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline-block"
              :class="{'transform rotate-90': opened[server.id]}"
            fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
            </svg>
            <span class="text-white ml-2">{{server.name}}</span> ( {{server.interfaces.items.length}} interfaces )
          </div>
          <div v-show="opened[server.id]" class="flex-wrap flex">
            <div class="interface py-2 px-4 bg-gray-800 mb-3 rounded-md ml-4 flex loading w-80"
              v-for="mgwInterface in server.interfaces.items" :key="mgwInterface.id">
              <span class="rounded-full h-3 w-3 bg-red-500 inline-block mr-2 relative top-1"
                :class="{'bg-red-500': !mgwInterface.active, 'bg-green-500': mgwInterface.active}"></span>
              <div class="flex-grow">
                <span class="text-white">{{mgwInterface.id}}</span> ({{mgwInterface.name}})
              </div>

              <div class="loading" v-show="loadings[mgwInterface.id]">
                <svg class="animate-spin h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
              <div class="action cursor-pointer"  v-show="!loadings[mgwInterface.id]">
                <svg xmlns="http://www.w3.org/2000/svg"
                  @click="start(mgwInterface.id)"
                  class="h-6 w-6 text-green-500" viewBox="0 0 20 20" fill="currentColor" v-show="!mgwInterface.active">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg"
                  @click="stop(mgwInterface.id)"
                  class="h-6 w-6 text-red-500" viewBox="0 0 20 20" fill="currentColor" v-show="mgwInterface.active">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8 7a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1V8a1 1 0 00-1-1H8z" clip-rule="evenodd" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <!-- <pre>
          {{servers}}
        </pre> -->
      </div>
    </div>
    <!-- -------------------------------------------------------------------------------------- -->
    <!-- DILOGS      -------------------------------------------------------------------------- -->
    <!-- -------------------------------------------------------------------------------------- -->

    <!-- -------------------------------------------------------------------------------------- -->
    <!-- NAV OPTIONS -------------------------------------------------------------------------- -->
    <!-- -------------------------------------------------------------------------------------- -->
    <div class="config mv-config">
      <!--<el-checkbox v-model="useTake"
        border
        label="Use take">
      </el-checkbox>
      -->
      <!-- <el-tooltip content="Help" placement="right">
        <el-popover
          placement="top-start"
          width="300"
          trigger="hover">
          <el-alert :closable="false">
            Current state refreshes every 15 seconds
          </el-alert>
          <h3><strong>Keybindings</strong></h3>
          <div>
            <div>
              Hold <el-tag size="small">SHIFT</el-tag> to disable scrolling.
            </div>
          </div>
          <i slot="reference" class="bi bly-question icon-btn"></i>
        </el-popover>
      </el-tooltip> -->
      <!--
      <el-select v-model="seletedRouterGroup" value-key="id" v-if="!isMobileOnly">
        <el-option
          v-for="group in routerGroups.items"
          :key="group.id"
          :label="group.name"
          :value="group">
        </el-option>
      </el-select>
      -->
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapActions, mapMutations } from 'vuex';
import { isMobileOnly } from 'mobile-device-detect';
import _ from 'lodash';
import { Splitpanes, Pane } from 'splitpanes';
import ColorGenerator from '@/services/ColorGenerator';
import 'splitpanes/dist/splitpanes.css';

export default {
  name: 'router',
  components: { Splitpanes, Pane },
  data() {
    return {
      isMobileOnly,
      keys: {},
      opened: {},
      loadings: {},
    };
  },

  created() {
    this.init();
    this.initInterval();
    window.addEventListener('keyup', this.keyUp);
    window.addEventListener('keydown', this.keyDown);
  },

  beforeDestroy() {
    window.clearInterval(this.interval);
    this.interval = null;
    window.removeEventListener('keyup', this.keyUp);
    window.removeEventListener('keydown', this.keyDown);
  },

  computed: {
    ...mapState({
      aclEx: (state) => state.userAccount.userPermissions,
      acl: (state) => state.userAccount.aclCurrent,
      isAdmin: (state) => state.userAccount.isAdmin,
      client: (state) => state.userAccount.client,
      account: (state) => state.userAccount.account,
      clients: (state) => state.userAccount.clients,
      servers: (state) => state.mgw.servers,
      interfaces: (state) => state.mgw.interfaces,
    }),

    accountId() {
      return this.account.code;
    },

    clientId() {
      return this.client.code;
    },

    claimsCanEdit() {
      return [
        `ro.${this.accountId}.${this.clientId}`,
      ];
    },

    claimsCanAccess() {
      return [
        `rv.${this.accountId}.${this.clientId}`,
        `ro.${this.accountId}.${this.clientId}`,
      ];
    },

    originRouterColor() {
      return (id) => ColorGenerator.generateNewColor(id);
    },
  },

  watch: {
  },

  methods: {
    ...mapActions({
      listMGWServers: 'mgw/listMGWServers',
      listMGWInterfaces: 'mgw/listMGWInterfaces',
      startMGWInterface: 'mgw/startMGWInterface',
      stopMGWInterface: 'mgw/stopMGWInterface',

    }),
    ...mapMutations({
      setSelectedSite: 'mgw/setSelectedSite',
    }),

    toggleServer(id) {
      Vue.set(this.opened, id, !this.opened[id]);
    },

    keyDown(e) {
      this.keys[e.key] = true;
    },

    keyUp(e) {
      this.keys[e.key] = false;
    },

    initInterval() {
      this.interval = setInterval(async () => {
        await this.listMGWServers();
        await this.listMGWInterfaces();
        this.$forceUpdate();
      }, 15000);
    },

    async init() {
      await this.listMGWServers();
      this.resetServersOpened();
      await this.listMGWInterfaces();      
    },

    resetServersOpened() {
      this.servers.forEach((server) => {
        Vue.set(this.opened, server.id, true);
      });
    },
    collapseAll() {
      this.servers.forEach((server) => {
        Vue.set(this.opened, server.id, false);
      });
    },

    async start(id) {
      Vue.set(this.loadings, id, true);
      const result = await this.startMGWInterface(id);
      await this.listMGWServers();
      Vue.set(this.loadings, id, false);
    },
    async stop(id) {
      Vue.set(this.loadings, id, true);
      await this.stopMGWInterface(id);
      await this.listMGWServers();
      Vue.set(this.loadings, id, false);
    },

    async showPrompt(confirmationMessage) {
      const confirmation = await this.$confirm(confirmationMessage, 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
        center: true,
        dangerouslyUseHTMLString: true,
      });
      return confirmation === 'confirm';
    },
  },
};
</script>

<style lang="scss">
@import '../assets/var.scss';
.green {
  color: $green;
}
.green-dark {
  color: $green-dark;
}
.blue-light {
  color: $blue-light;
}
#router {
  &.router-vh {
    .container-with-sidebar {
      .item-wrapper {
        .item {
          grid-template-columns: 1fr;
          grid-template-rows: 91px auto;
          height: 137px;
          width: 150px;
          text-align: center;
          align-items: start;
          grid-gap: 0;
          .item-name {
            margin: 0 10px;
            font-size: 13px;
          }
          .item-avatar {
            padding: 0px;
            .el-avatar {
              margin: 0 auto;
              width: 120px;
              height: 70px;
              line-height: 75px;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  .el-divider {
    // background-color: transparent;
    .el-divider__text {
      position: absolute;
      background-color: $dark;
    }
  }
  .container-with-sidebar {
    display: grid;
    grid-template-columns: auto;
    .pane-wrapper {
      height: 100%;
      .vb {
        height: 100%;
      }
    }
    .pane-bar-wrapper {
      // height: calc(100vh - 75px) !important;
      padding-left: 2px;
      padding-top: 2px;
      padding-bottom: 50px;
    }
    .pane-title {
      font-size: 14px;
      text-transform: uppercase;
      margin: 10px 0px;
      font-weight: 500;
      letter-spacing: 0.3px;
      position: relative;
      .bi {
        position: relative;
        top: 1px;
        margin-right: 8px;
      }
      .title-actions {
        position: absolute;
        right: 20px;
        top: -7px;
        input {
          background: transparent;
          border-color: transparent;
          color: $color;
        }
        .search {
          display: inline-block;
        }
      }
    }
    .item-wrapper {
      display: inline-block;
      margin-bottom: 12px;
      margin-right:12px;
      .item-actions {
        position: absolute;
        right: 0;
        top: 0;
      }
      .item {
        display: grid;
        grid-template-columns: 44px auto;
        grid-gap: 8px;
        width: 252px;
        height: 52px;
        align-items: center;
        overflow: hidden;
        background: $dark-light;
        border-radius: 2px;
        position: relative;
        &.active, &:hover {
          outline: 2px solid $blue-light;
        }
        &.active {
          outline: 2px solid $color-primary !important;
          .item-name {
            color: $color-primary;
          }
          .el-avatar--square {
            background: $color-primary;
          }
        }
        .take-action, .changing-route {
          position: absolute;
          background: $red;
          top: 5px;
          bottom: 5px;
          grid-column: 1 / span 2;
          right: 5px;
          left: 5px;
          max-width: 300px !important;
          color: #fff;
          line-height: 45px;
          text-align: center;
          display: none;
        }
        .changing-route {
          background: rgba(36, 42, 51, 0.78);
        }
        &.show-take {
          .take-action {
            display: block;
          }
        }
        &.show-loading {
          .changing-route {
            display: block;
            z-index: 999;
          }
        }
        &.destination {
          &.active, &:hover {
            outline: 2px solid $green;
          }
          &.connected {
            outline: 2px solid $blue-light;
            .item-name {
              color: $color-primary;
            }
            .el-avatar--square {
              background: $color-primary;
            }
          }
        }
        &.connected-dest {
          outline: 2px solid $color-primary;
        }
        &.connected {
          outline: 2px solid $color-primary;
          .item-name {
            color: $color-primary;
          }
          .el-avatar--square {
            background: $color-primary;
          }
        }
        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
        .el-avatar--square {
          border-radius: 2px;
        }
        div {
          max-width: 200px;
          text-overflow: ellipsis;
          overflow: hidden;
          cursor: pointer;
        }
        &-avatar {
          align-content: center;
          display: grid;
          padding-left: 8px;
        }
        &-name {
          color: $color;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &-desc {
          font-size: 12px;
          margin-bottom: -2px;
          line-height: 17px;
          .routed {
            display: inline-block;
            margin-right: 10px;
            color: $blue-light;
          }
          .not-connected {
            color: $color-warning;
            opacity: 0;
            display: none;
          }
        }
        .item-actions {
          position: absolute;
          right: 0;
          top: 0;
          i {
            background: rgba(36, 42, 51, 0.71);
          }
        }
      }
    }
  }
}
.mobile {
  #router {
      overflow-x: scroll;
      margin: 10px 0px;
      .wrapper {
        padding: 10px 0;
      }
      .item {
        width: 150px !important;
        grid-template-columns: 0 auto !important;
        &-avatar {
          visibility: hidden !important;
        }
      }
      .default-theme.splitpanes .splitpanes__pane {
        padding: 0 0 0 20px;
      }
      .container-with-sidebar {
        .pane-title {
          .title-actions {
            right: 0;
            top: 0;
            position: relative;
            left: -16px;
          }
        }
      }
  }
}

.default-theme {
  &.splitpanes {
    height: calc(100vh - 60px);
    .splitpanes__pane {
      background: transparent;
      padding: 0 0 0 20px;
      &.pane-dest {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }
    .splitpanes__splitter {
      background-color: rgba(0, 0, 0, 0.3);
      border-left: 1px solid transparent;
      border-top: 1px solid transparent;
      &:before {
        margin-left: 0px;
        background-color: $icon;
      }
      &:after {
        background-color: $icon;
      }
    }
  }
}
</style>
