import Vue from 'vue';
import kebabCase from 'lodash/kebabCase';

const COMPONENT_PREFIX = 'bly-';

const requireComponent = require.context('./', true, /\.vue$/);

requireComponent.keys().forEach((filePath) => {
  const component = requireComponent(filePath);
  const fileName = filePath.split('/').reverse()[0].replace(/\.vue$/, '');
  const fileNameKebabCase = kebabCase(fileName);
  const componentName = `${COMPONENT_PREFIX}${fileNameKebabCase}`;
  Vue.component(componentName, component.default);
});
