<template>
    <div id="permissions" class="content-wrapper">
      <div>
        <!--
        <div class="sidebar-group">
          <div class="sidebar-group-name">Inputs</div>
        </div>
        -->
        <div class="sidebar-item">
          <div class="sidebar-item-name" @click="select(inputs, 'input')">
            <i class="bi bly-input"></i>
            Inputs
          </div>
        </div>
        <div class="sidebar-item">
          <div class="sidebar-item-name" @click="select(outputs, 'output')">
            <i class="bi bly-output"></i>
            Output
          </div>
        </div>
        <div class="sidebar-item">
          <div class="sidebar-item-name" @click="select(outputProfiles, 'outputProfile')">
            <i class="bi bly-folder"></i>
            Output profiles
          </div>
        </div>
        <div class="sidebar-item">
          <div class="sidebar-item-name" @click="select(inputs, 'router')">
            <i class="bi bly-input"></i>
            Routers
          </div>
        </div>
        <div class="sidebar-item">
          <div class="sidebar-item-name" @click="select(outputs, 'routerGroups')">
            <i class="bi bly-output"></i>
            Router Groups
          </div>
        </div>
        <div class="sidebar-item">
          <div class="sidebar-item-name" @click="select(outputProfiles, 'sources')">
            <i class="bi bly-folder"></i>
            Router sources
          </div>
        </div>
        <div class="sidebar-item">
          <div class="sidebar-item-name" @click="select(outputProfiles, 'destinations')">
            <i class="bi bly-folder"></i>
            Router destinations
          </div>
        </div>
      </div>
      <div class="data-content" v-loading="loading"
        element-loading-text="Loading data"
        element-loading-background="rgba(19, 25, 29, 1)">
        <div  class="items">
          <div>
            <div class="data-list-item"
              :class="itemClasses(item.id)"
              v-for="item in activeList"
              :key="item.id"
              @click="selectItem(item)">
                <div class="data-list-item-name">
                  {{item.name}}
                </div>
                <div class="data-list-item-actions">
                  <el-tooltip>
                    <div slot="content">
                      <div><strong>Access:</strong></div>
                      <div v-for="claim in item.claimsCanAccess" :key="claim">
                        {{claim}}
                      </div>
                    </div>
                    <el-tag size="mini" effect="dark" type="info">
                      {{item.claimsCanAccess.length}}
                    </el-tag>
                  </el-tooltip>
                  <el-tooltip>
                    <div slot="content">
                      <div><strong>Edit:</strong></div>
                      <div v-for="claim in item.claimsCanEdit" :key="claim">
                        {{claim}}
                      </div>
                    </div>
                    <el-tag size="mini" effect="dark"
                      :type="item.claimsCanEdit.length === 0 ? 'danger' : 'success'">
                      {{item.claimsCanEdit.length}}
                    </el-tag>
                  </el-tooltip>
                </div>
            </div>
          </div>
        </div>
        <div class="item-clients" v-if="activeItem">
          <div class="active-item-details">
            <div>
              {{activeItem.name}}
            </div>
            <div class="active-item-actions">
              <el-button
                @click.native="save()"
                size="mini" type="primary">Save</el-button>
            </div>
          </div>
          <div
            class="client"
            v-for="client in activeItemPermissions"
            :key="'c'+ client.code">
            <div class="client-info">
              <div class="client-name">
                {{client.name}} [{{client.code}}]
              </div>
              <div class="client-claims">
                <span class="btn-group">
                  ca.{{client.code}}.{{client.code}}
                  <span
                    class="icon-btn"
                    :class="isClaimAssign(
                      'ca'+ client.code+'.'+ client.code, activeItem.claimsCanAccess
                    )"
                    @click="togglePermission(
                      'ca'+ client.code +'.'+ client.code, activeItem.claimsCanAccess
                    )">
                    <i class="bi bly-permissions"></i>
                  </span>
                  <span
                    class="icon-btn"
                    :class="isClaimAssign(
                      'ca'+ client.code +'.'+ client.code, activeItem.claimsCanEdit
                    )"
                    @click="togglePermission(
                      'ca'+ client.code +'.'+ client.code, activeItem.claimsCanEdit
                    )">
                    <i class="bi bly-edit"></i>
                  </span>
                </span>
                <el-divider direction="vertical"></el-divider>
                <span class="btn-group">
                  {{client.claim}}
                  <span
                    class="icon-btn"
                    :class="isClaimAssign(client.claim, activeItem.claimsCanAccess)"
                    @click="togglePermission(client.claim, activeItem.claimsCanAccess)">
                    <i class="bi bly-permissions"></i>
                  </span>
                  <span
                    class="icon-btn"
                    :class="isClaimAssign(client.claim, activeItem.claimsCanEdit)"
                    @click="togglePermission(client.claim, activeItem.claimsCanEdit)">
                    <i class="bi bly-edit"></i>
                  </span>
                </span>
              </div>
            </div>
            <div
              class="account"
              v-for="account in client.accounts"
              :key="'aa' + account.code">
              <div class="account-info">
                <div class="account-name">{{account.name}}</div>
                <div class="account-claims">
                  <span class="btn-group">
                    {{account.claim}}
                    <span
                      class="icon-btn"
                      :class="isClaimAssign(account.claim, activeItem.claimsCanAccess) +
                        isClaimDisabled(client.claim, activeItem.claimsCanAccess)"
                      @click="togglePermission(account.claim, activeItem.claimsCanAccess)">
                      <i class="bi bly-permissions"></i>
                    </span>
                    <span
                      class="icon-btn"
                      :class="isClaimAssign(account.claim, activeItem.claimsCanEdit)"
                      @click="togglePermission(account.claim, activeItem.claimsCanEdit)">
                      <i class="bi bly-edit"></i>
                    </span>
                  </span>
                </div>
              </div>
              <div class="account-permissions">
                <div
                  class="claim"
                  v-for="(claim, code) in account.claims"
                  :key="code">
                  <div class="claim-name">
                    <div>{{claimsByCode[code].name}}</div>
                    <div>
                      <span>{{claim}}</span>
                      <small v-if="false">{{claimsByCode[code].description}}</small>
                    </div>
                    <div class="claim-actions">
                      <div
                        class="icon-btn"
                        :class="isClaimAssign(claim, activeItem.claimsCanAccess) +
                          isClaimDisabled(client.claim, activeItem.claimsCanAccess) +
                          isClaimDisabled(account.claim, activeItem.claimsCanAccess)"
                        @click="togglePermission(claim, activeItem.claimsCanAccess)">
                        <i class="bi bly-permissions"></i>
                      </div>
                      <div
                        class="icon-btn"
                        :class="isClaimAssign(claim, activeItem.claimsCanEdit)"
                        @click="togglePermission(claim, activeItem.claimsCanEdit)">
                        <i class="bi bly-edit"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--
          <div
            v-for="claim in claims"
            :key="claim.id">
            <strong>{{claim.name}}</strong>
            {{claim.name}} - {{claim.description}}
          </div>
          <div>------------------------</div>
          {{activeItemPermissions}}
          <div>------------------------</div>
          {{claims}}
          -->
        </div>
      </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      activeList: null,
      activeItem: null,
      listType: null,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.permissions.loading,
      clients: (state) => state.permissions.clients,
      claims: (state) => state.permissions.claims,
      inputs: (state) => state.permissions.inputs,
      outputs: (state) => state.permissions.outputs,
      outputProfiles: (state) => state.permissions.outputProfiles,
    }),
    claimsByCode() {
      const claims = {};
      this.claims.forEach((claim) => {
        claims[claim.code] = claim;
      });
      return claims;
    },
    activeItemPermissions() {
      // const permissions = {};
      const permissions = this.clients.map((client) => {
        const newClientObject = {
          name: client.name,
          code: client.code,
          description: client.description,
          accounts: {},
          claim: `usr.${client.code}`,
        };

        newClientObject.accounts = client.accounts.items.map((account) => {
          const newAccountObject = {
            name: account.name,
            code: account.code,
            description: account.description,
            claim: `usr.${account.code}.${client.code}`,
            claims: {},
          };
          // console.log('newAccountObject', newAccountObject);
          this.claims.forEach((claim) => {
            if (claim.code === 'ca') return;
            newAccountObject.claims[claim.code] = `${claim.code}.${account.code}.${client.code}`;
          });
          return newAccountObject;
        });

        return newClientObject;
      });
      return permissions;
    },
    itemClasses() {
      return (id) => {
        const isActvie = id === this.activeItem.id;
        return isActvie ? 'active' : '';
      };
    },
    isClaimAssign() {
      return (claim, object) => {
        const isActvie = object.indexOf(claim) > -1;
        return isActvie ? 'active' : '';
      };
    },

    isClaimDisabled() {
      return (claim, object) => {
        const isActvie = object.indexOf(claim) > -1;
        return isActvie ? ' disabled' : '';
      };
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions({
      listInputs: 'permissions/listInputs',
      listOutputs: 'permissions/listOutputs',
      listClients: 'permissions/listClients',
      listClaims: 'permissions/listClaims',
      listOutputProfiles: 'permissions/listOutputProfiles',

      updateInput: 'permissions/updateInput',
      updateOutput: 'permissions/updateOutput',
      updateOutputProfile: 'permissions/updateOutputProfile',
    }),
    async init() {
      await this.listInputs();
      await this.listOutputs();
      await this.listOutputProfiles();
      await this.listClients();
      await this.listClaims();
      this.activeList = this.inputs;
      [this.activeItem] = this.activeList;
    },
    select(data, type) {
      this.activeList = data;
      this.listType = type;
      [this.activeItem] = this.activeList;
    },
    selectItem(data) {
      this.activeItem = data;
    },
    togglePermission(claim, object) {
      const hasClaim = object.indexOf(claim) > -1;
      if (hasClaim) object.splice(object.indexOf(claim), 1);
      if (!hasClaim) object.push(claim);
    },

    async save() {
      const newItem = {
        id: this.activeItem.id,
        expectedVersion: this.activeItem.version,
        claimsCanAccess: this.activeItem.claimsCanAccess,
        claimsCanEdit: this.activeItem.claimsCanEdit,
      };
      if (this.listType === 'input') {
        await this.updateInput(newItem);
        await this.listInputs();
      }
      if (this.listType === 'output') {
        await this.updateOutput(newItem);
        await this.listInputs();
      }
      if (this.listType === 'outputProfile') {
        await this.updateOutputProfile(newItem);
        await this.listOutputProfiles();
      }
    },
  },
};
</script>
<style lang="scss">
  @import '../assets/var.scss';
  #permissions {
    display: grid;
    grid-template-columns: 200px auto;
    padding: 10px 20px;
    .data-content {
      display: grid;
      grid-template-columns: 300px auto;
      gap: 16px;
      .btn-group {
        margin-left: 12px;
        display: inline-block;
        // border: 1px solid $dark;
        border-radius: 2px;
        line-height: 32px;
        padding-left: 8px;
        .icon-btn {
          line-height: 32px !important;
        }
      }
      .active-item-details {
        display: grid;
        grid-template-columns: 400px auto;
        .active-item-actions {
          text-align: right;
        }
      }
      .items {
        display: grid;
        gap: 8px;
        height: calc(100vh - 70px);
        .vb-content {
          padding: 2px 20px 4px 4px;
        }
        .data-list-item {
          cursor: pointer;
          .data-list-item-name {
            width: 205px;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .data-list-item-actions {
            margin-right: 8px;
            .el-tag {
              margin-left:10px;
            }
          }
          @extend %link-item;
          &.active {
            outline: 2px solid $color-primary;
          }
        }
      }
      .disabled {
        pointer-events: none;
        opacity: 0.3;
      }
      .active-item-details {
        font-size: 18px;
        color:#fff;
        margin-bottom: 24px;
      }
      .icon-btn {
        color: $color;
      }
      .client {
        padding: 16px;
        border: 1px solid $dark-light;
        border-radius: 2px;
        margin-bottom: 24px;
        .active {
          color: #fff;
          background: $red;
        }
        &-info {
          display: grid;
          grid-template-columns: 200px auto;
          margin-top: -33px;
          background: #242a33;
          padding: 0 16px;
          line-height: 40px;
          margin: -33px -17px 10px -17px;
          border-radius: 2px 4px 0px 0px;
          .client-name {
            color: $color;
          }
          .client-claims {
            text-align: right;
          }
        }
        .account {
          .account-info {
            display: grid;
            grid-template-columns: 200px auto;
            margin-top: 20px;
            line-height: 40px;
            .account-name {
              color: $color;
            }
            .account-claims {
              text-align: right;
            }
          }
        }

      }
      .account-permissions {

        .claim {
          display: inline-block;
          width: 250px;
          margin-bottom: 6px;
          margin-right: 6px;
          background: $dark-light;
          padding: 6px;
          border-radius: 2px;
          overflow: hidden;
          text-overflow: ellipsis;
          position: relative;
          height: 40px;
          .claim-actions {
            position: absolute;
            top: 0;
            right: 0;
            background: $dark-light;
            .active {
              color: #fff;
              background: $red;
            }
          }
          .claim-name {
            color: $color;
            span {
              font-size: 12px;
              width: 100%;
              color: $icon;
              display: block;
            }
            small {
              font-size: 10px;
              color: $icon;
              white-space: nowrap;
              text-overflow: ellipsis;
              width: 100%;
              display: block;
            }
          }
        }
      }
    }
  }
</style>
