import { Auth } from 'aws-amplify';
import AuthenticationService from '@/services/Authentication';

export default (to, from, next) => {
  Auth.currentSession()
    .then((data) => {
      AuthenticationService.setUserSession(data);
      AuthenticationService.setToken(data.accessToken.jwtToken);
      const { userGroups } = AuthenticationService;
      const isActiveUser = !!userGroups && userGroups.indexOf('User') !== -1;
      const isSysAdmin = !!userGroups && userGroups.indexOf('SysAdmin') !== -1;
      const isDevAdmin = !!userGroups && userGroups.indexOf('DevAdmin') !== -1;
      if (isActiveUser || isSysAdmin || isDevAdmin) {
        next();
        return;
      }
      const redirectRoute = {
        path: '/401',
      };
      next(redirectRoute);
    })
    .catch((err) => {
      console.log(err);
      console.log('is Auth error', err);
      const redirectRoute = {
        path: '/login',
        query: {
          redirect: to.fullPath,
        },
      };
      next(redirectRoute);
    });
};
