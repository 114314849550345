<template>
  <div class="file">
    <div class="name">
      {{file.id}}
    </div>
    <div class="actions">
      <div class="status">
        {{file.uptime}}
      </div>
      <i class="bi bly-more icon-btn" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    file: {
      type: Object,
      default: () => {
        console.log('');
        return {
          id: 0,
          application: ' ',
          streamfile: '',
          uptime: 0,
          bytesIn: 0,
          bytesOut: 0,
          bytesInRate: 0,
          bytesOutRate: 0,
          totalConnections: 0,
        };
      },
    },
  },

  data() {
    return {

    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$green: #00FF80;
$red: #FC487F;
$hl: #66718D;

.file {
  background: #1B2127;
  margin-bottom: 8px;
  line-height: 40px;
  display: grid;
  grid-template-columns: 1fr 150px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  margin-left: 32px;

  .progress {
    height: 100%;
    position: absolute;
    top: 0;
    left: -1px;
    border-right: 1px solid rgba(#CFDFFF, 0.1);

    background: -moz-linear-gradient(left, rgba($hl,0) 0%, rgba($hl,0.15) 100%);
    background: -webkit-linear-gradient(left, rgba($hl,0) 0%,rgba($hl,0.15) 100%);
    background: linear-gradient(to right, rgba($hl,0) 0%,rgba($hl,0.15) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(
      startColorstr='#00ffffff', endColorstr='#26ffffff',GradientType=1
    );
  }

  &.uploading {
    .actions {
      .status {
        color: $green;
      }
    }
  }

  &.completed {
    background: rgba($green, 0.1);
    .name {
      i {
        color:$green;
      }
    }
    .actions {
      .status {
        color: $green;
      }
    }
  }

  &.failed {
    background: rgba($red, 0.2);
    .name {
      color: $red;
      i {
        color:$red;
      }
    }
    .actions {
      .status {
        color: $red;
      }
    }
  }

  .name {
    margin-left: 16px;
    i {
      margin-right: 8px;
      vertical-align: middle;
      position: relative;
      top: -2px;
      color: #CFDFFF;
      font-size: 16px;
    }
  }
  .actions {
    text-align: right;
    padding-right: 16px;

    .status {
      font-weight: bold;
      display: inline-block;
    }
  }
}
</style>
