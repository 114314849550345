import { API, graphqlOperation } from 'aws-amplify';
import Vue from 'vue';
import * as qlqueries from '../graphql/queries';
import * as qlmutations from '../graphql/mutations';

const state = {
  name: 'Router',
  selectedSite: null,
  siteList: [],
  loading: false,
  streamInformation: {},

  routerGroups: null,
  routers: [],
  routerSources: [],
  routerDestinations: [],
  loadings: {
    sources: false,
    destinations: false,
    groups: false,
    source: null,
  },
};

const getters = {
};

function getValue(name, block, raw) {
  let ret = '';

  let currentBlock = '';
  if (!raw) return '';
  for (let i = 0; i < raw.length; i += 1) {
    if (raw[i].indexOf(':') !== -1) {
      if (block.toLowerCase() === currentBlock.toLowerCase()) {
        const values = raw[i].split(':');
        if (values.length > 1 && values[0].trim().toLowerCase() === name.toLowerCase()) {
          ret = values[1].trim();
        }
      }
    } else {
      currentBlock = raw[i].trim();
    }
  }
  return ret;
}

const mutations = {
  setSites2: (state, payload) => {
    Vue.set(state, 'siteList', payload);
    Vue.set(state, 'selectedSite', payload[0]);
  },
  setSites: (state, payload) => {
    Vue.set(state, 'siteList', payload);
    const savedRouterId = localStorage.getItem('router');
    if (savedRouterId) {
      const selectedSite = payload.filter((site) => site.id === savedRouterId)[0];

      Vue.set(state, 'selectedSite', selectedSite);
      return;
    }
    Vue.set(state, 'selectedSite', payload[0]);
  },
  setLoading: (state, payload) => {
    Vue.set(state, 'loading', payload);
  },
  setLoadings: (state, payload) => {
    Vue.set(state.loadings, payload.type, payload.value);
  },
  setSelectedSite: (state, payload) => {
    Vue.set(state, 'selectedSite', payload);
    localStorage.setItem('router', payload.id);
  },
  setStreamInfo: (state, payload) => {
    const bitRate = getValue('Overall bit rate', 'General', payload.info);
    const bitRateMode = getValue('Overall bit rate mode', 'General', payload.info);
    const formatProfile = getValue('Format profile', 'Video', payload.info);
    const videoWidth = getValue('Width', 'Video', payload.info);
    const videoHeight = getValue('Height', 'Video', payload.info);
    const isHeightSet = !!videoHeight && videoHeight !== '';
    const isWidthSet = !!videoWidth && videoWidth !== '';
    const areWHSet = isWidthSet && isHeightSet;

    const information = {
      ServiceName: getValue('Service name', 'Menu', payload.info),
      ServiceProvider: getValue('Service provider', 'Menu'),
      VideoFormat: `${getValue('Format', 'Video')} ${formatProfile}`,
      VideoPID: getValue('ID', 'Video'),
      MuxRate: `${bitRate} (${bitRateMode})`,
      Audio1Rate: getValue('Bit rate', 'Audio #1', payload.info),
      Audio1Format: getValue('Format', 'Audio #1', payload.info),
      Audio1PID: getValue('ID', 'Audio #1', payload.info),
      Audio2Rate: getValue('Bit rate', 'Audio #2', payload.info),
      Audio2Format: getValue('Format', 'Audio #2', payload.info),
      Audio2PID: getValue('ID', 'Audio #2', payload.info),
      Audio3Rate: getValue('Bit rate', 'Audio #3', payload.info),
      Audio3Format: getValue('Format', 'Audio #3', payload.info),
      Audio3PID: getValue('ID', 'Audio #3', payload.info),
      Audio4Rate: getValue('Bit rate', 'Audio #4', payload.info),
      Audio4Format: getValue('Format', 'Audio #4', payload.info),
      Audio4PID: getValue('ID', 'Audio #4', payload.info),
      VideoRaster: '1920x1080i25',
    };

    if (areWHSet) {
      const scanType = getValue('Scan type', 'Video', payload.info).toLowerCase()[0];
      const frameRate = getValue('Frame rate', 'Video', payload.info).replace(' fps', '');
      const resolution = `${videoWidth.replace(' pixels', '')}x${videoHeight.replace(' pixels', '')}`;
      information.VideoRaster = `${resolution}${scanType}${frameRate}`;
    }

    Vue.set(state.streamInformation, payload.id, information);
  },

  setRouterGroups: (state, payload) => {
    Vue.set(state, 'routerGroups', payload);
  },

  setRouterDestinations: (state, payload) => {
    Vue.set(state, 'routerDestinations', payload.items.length > 0 ? payload.items : []);
  },

  setRouterSources: (state, payload) => {
    Vue.set(state, 'routerSources', payload.items.length > 0 ? payload.items : []);
  },

  setRouters: (state, payload) => {
    Vue.set(state, 'routers', payload.items);
  },

  setRouterDestination: (state, payload) => {
    Vue.set(state, 'selectedSite', payload);
  },

  setRouterSource: (state, payload) => {
    Vue.set(state, 'selectedSite', payload);
  },

  deleteRouter: (state, payload) => {
    state.routers = state.routers.filter((value) => value.id !== payload.id);
  },

  createRouter: (state, payload) => {
    state.routers.push(payload);
  },

  createRouterGroup: (state, payload) => {
    state.routerGroups.push(payload);
  },

  createRouterSource: (state, payload) => {
    state.routerSources.push(payload);
  },

  createRouterDestination: (state, payload) => {
    state.routerDestinations.push(payload);
  },

  routeCreateRoute: (state, payload) => {
    console.log('routeCreateRoute => ', state, payload);
  },
};

const actions = {
  // GQL Queries
  async fetchRouterGroups({ commit }) {
    commit('setLoadings', { type: 'groups', value: true });
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listRouterGroups,
          {
            limit: 500,
          }),
      );
      const { data } = result;
      commit('setRouterGroups', data.listRouterGroups);
      return data.listRouterGroups;
    } catch (error) {
      const hasData = !!error.data && !!error.data.listRouterGroups;
      if (hasData) {
        const { data } = error;
        commit('setRouterGroups', data.listRouterGroups);
        this._vm.$message.error(
          { message: 'Oops something went wrong. Can\'t get some of the router groups' },
        );
        return { error: true, message: error.errors };
      }
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t get router groups' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'groups', value: false });
    }
  },

  async fetchRouterDestinations({ commit }) {
    commit('setLoadings', { type: 'destinations', value: true });
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listRouterDestinations,
          {
            limit: 500,
          }),
      );
      const { data } = result;
      commit('setRouterDestinations', data.listRouterDestinations);
      return data.listRouterDestinations;
    } catch (error) {
      const hasData = !!error.data && !!error.data.listRouterDestinations;
      if (hasData) {
        const { data } = error;
        commit('setRouterDestinations', data.listRouterDestinations);
        this._vm.$message.error(
          { message: 'Oops something went wrong. Can\'t get some of the destinations' },
        );
        return { error: true, message: error.errors };
      }
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t get destinations' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'destinations', value: false });
    }
  },

  async fetchRouterSources({ commit }) {
    commit('setLoadings', { type: 'sources', value: true });
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listRouterSources,
          {
            limit: 500,
          }),
      );
      const { data } = result;
      commit('setRouterSources', data.listRouterSources);
      return data.listRouterSources;
    } catch (error) {
      console.log('error', error.data);
      const hasData = !!error.data && !!error.data.listRouterSources;
      if (hasData) {
        const { data } = error;
        commit('setRouterSources', data.listRouterSources);
        this._vm.$message.error(
          { message: 'Oops something went wrong. Can\'t get some of the sources' },
        );
        return { error: true, message: error.errors };
      }
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t get sources' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'sources', value: false });
    }
  },

  async fetchRouters({ commit }) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listRouters),
      );
      const { data } = result;
      commit('setRouters', data.listRouters);
      return data.listRouters;
    } catch (error) {
      this._vm.$message.error({ message: 'Oops, can\'t get routers.' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async fetchRouter({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.getRouter, {
          id: payload,
        }),
      );
      const { data } = result;
      commit('setRouter', data.getRouter);
      return data.getRouter;
    } catch (error) {
      this._vm.$message.error({ message: 'Oops, can\'t get router details.' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async fetchRouterDestination({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.getRouterDestination, {
          id: payload,
        }),
      );
      const { data } = result;
      commit('setRouterDestination', data.getRouterDestination);
      return data.getRouterDestination;
    } catch (error) {
      this._vm.$message.error({ message: 'Oops, can\'t get router destination details.' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async fetchRouterGroup({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.getRouterGroup, {
          id: payload,
        }),
      );
      const { data } = result;
      commit('setRouterGroup', data.getRouterGroup);
      return data.getRouterGroup;
    } catch (error) {
      this._vm.$message.error({ message: 'Oops, can\'t get router destination details.' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async fetchRouterSource({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.getRouterSource, {
          id: payload,
        }),
      );
      const { data } = result;
      commit('setRouterSource', data.getRouterSource);
      return data.getRouterSource;
    } catch (error) {
      this._vm.$message.error({ message: 'Oops, can\'t get router destination details.' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  // GQL mutations
  async deleteRouter({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteRouter,
          { input: { id: payload.id, expectedVersion: payload.version } },
        ),
      );
      const { data } = result;
      commit('deleteRouter', data.deleteRouter);
      this._vm.$message({ message: 'Router deleted' });
      return data.deleteRouter;
    } catch (error) {
      this._vm.$message.error({ message: 'Can\'t delete router' });
      return { error: true, message: error.errors };
    }
  },

  async deleteRouterDestination({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteRouterDestination,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('deleteRouterDestination', data.deleteRouterDestination);
      this._vm.$message({ message: 'Router deleted' });
      return data.deleteRouterDestination;
    } catch (error) {
      this._vm.$message.error({ message: 'Can\'t delete router destination' });
      return { error: true, message: error.errors };
    }
  },

  async deleteRouterGroup({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteRouterGroup,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('deleteRouterGroup', data.deleteRouterGroup);
      this._vm.$message({ message: 'Router group deleted' });
      return data.deleteRouterGroup;
    } catch (error) {
      this._vm.$message.error({ message: 'Can\'t delete router group ' });
      return { error: true, message: error.errors };
    }
  },

  async deleteRouterSource({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteRouterSource,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('deleteRouterSource', data.deleteRouterSource);
      this._vm.$message({ message: 'Router source deleted' });
      return data.deleteRouterSource;
    } catch (error) {
      this._vm.$message.error({ message: 'Can\'t delete router source' });
      return { error: true, message: error.errors };
    }
  },

  async updateRouter({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.updateRouter,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('updateRouter', data.updateRouter);
      this._vm.$message({ message: 'Router updated' });
      return data.updateRouter;
    } catch (error) {
      this._vm.$message.error({ message: 'Can\'t delete router' });
      return { error: true, message: error.errors };
    }
  },

  async updateRouterDestination({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.updateRouterDestination,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('updateRouterDestination', data.updateRouterDestination);
      this._vm.$message({ message: 'Router updated' });
      return data.updateRouterDestination;
    } catch (error) {
      this._vm.$message.error({ message: 'Can\'t delete router destination' });
      return { error: true, message: error.errors };
    }
  },

  async updateRouterGroup({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.updateRouterGroup,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('updateRouterGroup', data.updateRouterGroup);
      this._vm.$message({ message: 'Router group updated' });
      return data.updateRouterGroup;
    } catch (error) {
      this._vm.$message.error({ message: 'Can\'t delete router group ' });
      return { error: true, message: error.errors };
    }
  },

  async updateRouterSource({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.updateRouterSource,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('updateRouterSource', data.updateRouterSource);
      this._vm.$message({ message: 'Router source updated' });
      return data.updateRouterSource;
    } catch (error) {
      this._vm.$message.error({ message: 'Can\'t delete router source' });
      return { error: true, message: error.errors };
    }
  },

  async createRouter({ commit }, payload) {
    try {
      const clientId = payload.client.id;
      const accountId = payload.account.id;
      const formData = {
        ...payload.routerForm,
        claimsCanAccess: [`rv.${accountId}.${clientId}`],
        claimsCanEdit: [`ro.${accountId}.${clientId}`],
        routerClientId: clientId,
      };
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createRouter,
          { input: formData },
        ),
      );
      const { data } = result;
      commit('createRouter', data.createRouter);
      this._vm.$message({ message: 'Router created' });
      return data.createRouter;
    } catch (error) {
      this._vm.$message.error({ message: 'Can\'t create router' });
      return { error: true, message: error.errors };
    }
  },

  async createRouterDestination({ commit }, payload) {
    try {
      const clientId = payload.client.id;
      const accountId = payload.account.id;
      const formData = {
        ...payload.routerDestinationForm,
        claimsCanAccess: [`rv.${accountId}.${clientId}`],
        claimsCanEdit: [`ro.${accountId}.${clientId}`],
      };
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createRouterDestination,
          { input: formData },
        ),
      );
      const { data } = result;
      commit('createRouterDestination', data.createRouterDestination);
      this._vm.$message({ message: 'Router created' });
      return data.createRouterDestination;
    } catch (error) {
      this._vm.$message.error({ message: 'Can\'t delete router destination' });
      return { error: true, message: error.errors };
    }
  },

  async createRouterGroup({ commit }, payload) {
    try {
      const clientId = payload.client.id;
      const accountId = payload.account.id;
      const formData = {
        ...payload.routerGroupForm,
        claimsCanAccess: [`rv.${accountId}.${clientId}`],
        claimsCanEdit: [`ro.${accountId}.${clientId}`],
      };
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createRouterGroup,
          { input: formData },
        ),
      );
      const { data } = result;
      commit('createRouterGroup', data.createRouterGroup);
      this._vm.$message({ message: 'Router group created' });
      return data.createRouterGroup;
    } catch (error) {
      this._vm.$message.error({ message: 'Can\'t delete router group ' });
      return { error: true, message: error.errors };
    }
  },

  async createRouterSource({ commit }, payload) {
    try {
      const clientId = payload.client.id;
      const accountId = payload.account.id;
      const formData = {
        ...payload.routerSourceForm,
        claimsCanAccess: [`rv.${accountId}.${clientId}`],
        claimsCanEdit: [`ro.${accountId}.${clientId}`],
      };
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createRouterSource,
          { input: formData },
        ),
      );
      const { data } = result;
      commit('createRouterSource', data.createRouterSource);
      this._vm.$message({ message: 'Router source created' });
      return data.createRouterSource;
    } catch (error) {
      this._vm.$message.error({ message: 'Can\'t delete router source' });
      return { error: true, message: error.errors };
    }
  },

  async changeRoute({ commit }, payload) {
    commit('setLoadings', { type: 'source', value: payload.sourceId });
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.routeCreateRoute,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('routeCreateRoute', data.routeCreateRoute);
      this._vm.$message({ message: 'Router created' });
      return data.routeCreateRoute;
    } catch (error) {
      this._vm.$message.error({ message: 'Can\'t route source' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'source', value: null });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
