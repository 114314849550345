a<template>
  <div id="user-profile">
    <!-- Password Change Form -->
      <div>
        <div class="panel">
          <!--
          <el-form
            :model="passwordChangeForm"
            :rules="passwordChangeRules"
            @submit.native.prevent
            ref="passwordChangeForm"
            class="form"
            label-width="120px"
            label-position="top">

            <h1 class="title">Change password</h1>
            <p class="title-description">Enter old and new password</p>

            <el-form-item v-if="error">
              <el-alert :title="error" v-if="error" type="error" :closable="false"></el-alert>
            </el-form-item>

            <el-form-item prop="oldPassword" label="Enter old password">
              <el-input
                :autofocus="true"
                placeholder="Old Password"
                type="password"
                v-model="passwordChangeForm.oldPassword"
                @keyup.enter.native="changePassword('passwordChangeForm')">
              </el-input>
            </el-form-item>

            <el-form-item prop="newPassword" label="Enter new password">
              <el-input
                placeholder="New Password"
                type="password"
                v-model="passwordChangeForm.newPassword"
                @keyup.enter.native="changePassword('passwordChangeForm')">
              </el-input>
            </el-form-item>

            <el-form-item prop="confirmPassword" label="Confirm new password">
              <el-input
                placeholder="Confirm Password"
                type="password"
                v-model="passwordChangeForm.confirmPassword"
                @keyup.enter.native="changePassword('passwordChangeForm')">
              </el-input>
            </el-form-item>

            <el-button
              type="primary"
              @click="changePassword('passwordChangeForm')"
              :loading="isBtnLoading">{{btnText('Change Password')}}
            </el-button>
          </el-form>
          -->
          <el-input v-model="id" placeholder="id"></el-input>

          <h3>Client</h3>
          <el-button
            type="primary"
            @click="createClient()">Add
          </el-button>
          <el-button
            type="primary"
            @click="updateClient()">Update
          </el-button>
          <el-button
            type="primary"
            @click="deleteClient()">Delete
          </el-button>

          <h3>Features</h3>
          <el-button
            type="info"
            @click="createFeature()">Add
          </el-button>
          <el-button
            type="info"
            @click="updateFeature()">Update
          </el-button>
          <el-button
            type="info"
            @click="deleteFeature()">Delete
          </el-button>

          <h3>User</h3>
          <el-button
            type="warning"
            @click="createUser()">Add
          </el-button>
          <el-button
            type="warning"
            @click="updateUser()">Update
          </el-button>
          <el-button
            type="warning"
            @click="deleteUser()">Delete
          </el-button>

          <h3>Events</h3>
           <el-button
            type="success"
            @click="createEvent()">Add
          </el-button>
          <el-button
            type="success"
            @click="deleteEvent()">Delete
          </el-button>
          <el-button
            type="success"
            @click="updateEvent()">Update
          </el-button>

          <el-button
            type="success"
            @click="createEventAction()">Add action
          </el-button>

          <h3>Claims</h3>
           <el-button
            type="danger"
            @click="createClaim()">Add
          </el-button>
          <el-button
            type="danger"
            @click="deleteClaim()">Delete
          </el-button>
          <el-button
            type="danger"
            @click="updateClaim()">Update
          </el-button>

          <h3>App Settings</h3>
           <el-button
            type="danger"
            @click="createAppSetting()">Add
          </el-button>
          <el-button
            type="danger"
            @click="deleteAppSetting()">Delete
          </el-button>
          <el-button
            type="danger"
            @click="updateAppSetting()">Update
          </el-button>
<br><br>
        </div>
      </div>
      <!--
      <div>
        <div class="panel">
            <h1 class="title">User Profile</h1>
            <p class="title-description">{{user}}</p>
        </div>
      </div>
      -->
      <div>
        <div class="panel">
            <h1 class="title">User Data</h1>
            <p class="title-description">{{userData.user | defaultValue('No Data')}}</p>

        </div>
      </div>
      <div>
        <div class="panel">
            <h1 class="title">User Claims</h1>
            <p class="title-description">{{userData.claims | defaultValue('No Claims')}}</p>

        </div>
      </div>
      <div>
        <div class="panel">
            <h1 class="title">Clients</h1>
            <p class="title-description">{{userData.clients | defaultValue('No Data')}}</p>

        </div>
      </div>
      <div>
        <div class="panel">
            <h1 class="title">Users</h1>
            <p class="title-description">{{userData.users}}</p>

        </div>
      </div>
      <div>
        <div class="panel">
            <h1 class="title">Events</h1>
            <p class="title-description">{{userData.events}}</p>

        </div>
      </div>
       <div>
        <div class="panel">
            <h1 class="title">Event Actions</h1>
            <p class="title-description">{{userData.eventActions}}</p>

        </div>
      </div>
      <div>
        <div class="panel">
            <h1 class="title">Features</h1>
            <p class="title-description">{{userData.features}}</p>

        </div>
      </div>

      <div>
        <div class="panel">
            <h1 class="title">App Settings</h1>
            <p class="title-description">{{userData.settings}}</p>

        </div>
      </div>

  </div>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify';
import Vue from 'vue';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

export default {
  data() {
    return {
      passwordChangeForm: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      id: null,
      queries,
      mutations,
      userData: {},
      passwordChangeRules: {
        oldPassword: [
          { required: true, message: 'Please enter old passwrod', trigger: 'blur' },
          { min: 8, message: 'Length should be atleast 8', trigger: 'blur' },
        ],
        newPassword: [
          { required: true, message: 'Please enter new passwrod', trigger: 'blur' },
          { min: 8, message: 'Length should be atleast 8', trigger: 'blur' },
        ],
        confirmPassword: [
          { required: true, message: 'Please confirm new password', trigger: 'blur' },
          { min: 8, message: 'Length should be atleast 8', trigger: 'blur' },
        ],
      },

      // UI Stuff
      error: null,
      isBtnLoading: false,
      user: null,
    };
  },

  computed: {
  },

  created() {
    this.getUserProfile();
  },

  methods: {
    btnText(text) {
      if (this.isBtnLoading) return 'Loading...';
      return text;
    },
    async getUserProfile() {
      const result = await this.$Amplify.Auth.currentAuthenticatedUser();
      Vue.set(this, 'user', result);
      this.getUserData();
      this.getUserClaim();
      this.listClients();
      this.listUsers();
      this.listEvents();
      this.listFeatures();
      this.listAppSettings();
      this.listEventActions();
      this.listClaims();
    },
    async getUserData() {
      const result = await API.graphql(
        graphqlOperation(this.queries.getUser, { id: this.user.username }),
      );
      Vue.set(this.userData, 'user', result.data.getUser);
    },
    async getUserClaim() {
      const result = await API.graphql(
        graphqlOperation(this.queries.listClaims, { id: this.user.username }),
      );
      Vue.set(this.userData, 'claim', result.data.listClaims);
    },

    async listAppSettings() {
      const result = await API.graphql(
        graphqlOperation(this.queries.listAppSettings),
      );
      Vue.set(this.userData, 'clients', result.data);
    },

    async listClients() {
      const result = await API.graphql(
        graphqlOperation(this.queries.listClients),
      );
      Vue.set(this.userData, 'clients', result.data);
    },

    async listClaims() {
      const result = await API.graphql(
        graphqlOperation(this.queries.listClaims),
      );
      Vue.set(this.userData, 'claims', result.data.listClaims);
    },

    async listUsers() {
      const result = await API.graphql(
        graphqlOperation(this.queries.listUsers),
      );
      Vue.set(this.userData, 'users', result.data.listUsers);
    },

    async listEvents() {
      const result = await API.graphql(
        graphqlOperation(this.queries.listEvents),
      );
      Vue.set(this.userData, 'events', result.data.listEvents);
    },

    async listFeatures() {
      const result = await API.graphql(
        graphqlOperation(this.queries.listFeatures),
      );
      Vue.set(this.userData, 'features', result.data.listFeatures);
    },

    async listEventActions() {
      const result = await API.graphql(
        graphqlOperation(this.queries.listEventActions),
      );
      Vue.set(this.userData, 'eventActions', result.data.listEventActions);
    },

    /*
      CLIENTS
    */

    async createClient() {
      const client = {
        code: 'xyz',
        name: 'XYZ Client',
        description: 'lorem ipsum sit dolor',
        email: 'adrian@cloudtree.pl',
        phoneno: '555555555',
        claimsCanAccess: ['cbc2848f-d7fb-468e-b26b-fbca279cc431'],
        claimsCanEdit: ['c15e3350-8d32-400f-bcbd-d1c5f9581382'],

      };

      const result = await API.graphql(
        graphqlOperation(this.mutations.createClient, {
          input: client,
        }),
      );
      console.log('createClient', result);
      // Vue.set(this.userData, 'events', result.data.listEvents);
      this.getUserProfile();
    },

    async updateClient() {
      const client = {
        id: this.id,
        code: 'NV',
        name: 'Client Name Updated',
        description: 'lorem ipsum sit dolor',
        email: 'adrian@cloudtree.pl',
        phoneno: '555555555',
      };

      const result = await API.graphql(
        graphqlOperation(this.mutations.updateClient, {
          input: client,
        }),
      );
      console.log('updateClient', result);
      this.getUserProfile();
    },

    async deleteClient() {
      const client = {
        id: this.id,
      };

      const result = await API.graphql(
        graphqlOperation(this.mutations.deleteClient, {
          input: client,
        }),
      );
      console.log('deleteClient', result);
      this.getUserProfile();
    },

    /*
      CLAIMS
    */

    async createClaim() {
      const data = {
        code: 'xyz-ev-read',
        name: 'EVENT READ',
        description: 'xyz-ev-read',
      };

      const data2 = {
        code: 'xyz-ev-edit',
        name: 'EVENT EDIT',
        description: 'xyz-ev-edit',
      };

      const result = await API.graphql(
        graphqlOperation(this.mutations.createClaim, {
          input: data,
        }),
      );
      const result2 = await API.graphql(
        graphqlOperation(this.mutations.createClaim, {
          input: data2,
        }),
      );
      console.log('createClaim', result, result2);
      this.getUserProfile();
    },

    async updateClaim() {
      const data = {
        id: this.id,
        code: 'CODE-UPDATE',
        name: 'NVVS',
        description: 'CLAIM UPDATED',
      };

      const result = await API.graphql(
        graphqlOperation(this.mutations.updateClaim, {
          input: data,
        }),
      );
      console.log('updateClaim', result);
      this.getUserProfile();
    },

    async deleteClaim() {
      const data = {
        id: this.id,
      };

      const result = await API.graphql(
        graphqlOperation(this.mutations.deleteClaim, {
          input: data,
        }),
      );
      console.log('deleteClaim', result);
      this.getUserProfile();
    },

    /*
      FEATURES
    */
    async createFeature() {
      const feature = {
        code: 'ev',
        name: 'Events',
        description: 'lorem ipsum sit dolor',
      };

      const result = await API.graphql(
        graphqlOperation(this.mutations.createFeature, {
          input: feature,
        }),
      );
      console.log('createFeature', result);
      this.getUserProfile();
    },

    async updateFeature() {
      const data = {
        id: this.id,
        code: 'F',
        name: 'Sample feature ',
        description: `FEATURE UPDATED ${new Date()}`,
      };

      const result = await API.graphql(
        graphqlOperation(this.mutations.updateFeature, {
          input: data,
        }),
      );
      console.log('updateFeature', result);
      this.getUserProfile();
    },

    async deleteFeature() {
      const data = {
        id: this.id,
      };

      const result = await API.graphql(
        graphqlOperation(this.mutations.deleteFeature, {
          input: data,
        }),
      );
      console.log('deleteFeature', result);
      this.getUserProfile();
    },

    /*
      USERS
    */
    async createUser() {
      const user = {
        email: 'adrian@mediaatmos.com',
        name: this.id,
        isSystemAdmin: true,
        claimsCanAccess: ['xyz-ev-read'],
        claimsCanEdit: ['xyz-ev-edit'],
      };

      const result = await API.graphql(
        graphqlOperation(this.mutations.createUser, {
          input: user,
        }),
      );
      console.log('CREATE CLIENT TEST', result);
      this.getUserProfile();
    },

    async updateUser() {
      const user = {
        id: this.id,
        email: 'adrian@mediaatmos.com',
        name: 'Adrian ATMOS Vue user',
        isSystemAdmin: true,
        claimsCanAccess: ['xyz-ev-read'],
        claimsCanEdit: ['xyz-ev-edit'],
      };

      const result = await API.graphql(
        graphqlOperation(this.mutations.updateUser, {
          input: user,
        }),
      );
      console.log('CREATE CLIENT TEST', result);
      this.getUserProfile();
    },

    async deleteUser() {
      const user = {
        id: this.id,
      };

      const result = await API.graphql(
        graphqlOperation(this.mutations.deleteUser, {
          input: user,
        }),
      );
      console.log('CREATE CLIENT TEST', result);
      this.getUserProfile();
    },

    async createAppSetting() {
      const setting = {
        key: 'test',
        value: 'test value',
      };

      const result = await API.graphql(
        graphqlOperation(this.mutations.createAppSetting, {
          input: setting,
        }),
      );
      console.log('CREATE APP Setting TEST', result);
      this.getUserProfile();
    },

    async updateAppSetting() {
      const setting = {
        key: 'test',
        value: 'UPDATED',
      };

      const result = await API.graphql(
        graphqlOperation(this.mutations.updateAppSetting, {
          input: setting,
        }),
      );
      console.log('CREATE APP Setting TEST', result);
      this.getUserProfile();
    },

    async deleteAppSetting() {
      const setting = {
        key: 'test',
        value: 'test value',
      };

      const result = await API.graphql(
        graphqlOperation(this.mutations.deleteAppSetting, {
          input: setting,
        }),
      );
      console.log('CREATE APP Setting TEST', result);
      this.getUserProfile();
    },

    /*
      EVENTS
    */
    async createEvent() {
      const event = {
        groupsCanEdit: true,
        groupsCanAccess: true,
        claimsCanAccess: ['xyz-ev-read'],
        claimsCanEdit: ['xyz-ev-edit'],
        name: 'sample event 2',
        description: 'Lorem ipsum sit dolor',
        type: 'SPORT',
        lineupTime: new Date('Wed Sep 02 2019 03:03:01 GMT+0200 (Central European Summer Time)'),
        startTime: new Date('Wed Sep 02 2019 03:13:01 GMT+0200 (Central European Summer Time)'),
        endTime: new Date('Wed Sep 02 2019 03:53:01 GMT+0200 (Central European Summer Time)'),
        status: 'COMPLETED',
      };

      const result = await API.graphql(
        graphqlOperation(this.mutations.createEvent, {
          input: event,
        }),
      );
      console.log('CREATE EVENT TEST', result);
      this.getUserProfile();
    },

    async updateEvent() {
      const event = {
        id: this.id,
        groupsCanEdit: true,
        groupsCanAccess: true,
        claimsCanAccess: ['xyz-ev-read'],
        claimsCanEdit: ['xyz-ev-edit'],
        name: 'sample event 2',
        description: 'EVENT @ UPDATED ----------------',
        type: 'SPORT',
        lineupTime: new Date('Wed Sep 02 2019 03:03:01 GMT+0200 (Central European Summer Time)'),
        startTime: new Date('Wed Sep 02 2019 03:13:01 GMT+0200 (Central European Summer Time)'),
        endTime: new Date('Wed Sep 02 2019 03:53:01 GMT+0200 (Central European Summer Time)'),
        status: 'COMPLETED',
      };

      const result = await API.graphql(
        graphqlOperation(this.mutations.updateEvent, {
          input: event,
        }),
      );
      console.log('CREATE EVENT TEST', result);
      this.getUserProfile();
    },

    async deleteEvent() {
      const event = {
        id: this.id,
      };
      const result = await API.graphql(
        graphqlOperation(this.mutations.deleteEvent, {
          input: event,
        }),
      );
      console.log('CREATE EVENT TEST', result);
      this.getUserProfile();
    },

    async createEventAction() {
      const event = {
        setupOffsetMs: 2314,
      };

      const result = await API.graphql(
        graphqlOperation(this.mutations.createEventAction, {
          input: event,
        }),
      );
      console.log('CREATE EVENT TEST', result);
      this.getUserProfile();
    },

    async updateEventAction() {
      const event = {
        id: this.id,
        setupOffsetMs: 1111,
      };

      const result = await API.graphql(
        graphqlOperation(this.mutations.updateEventAction, {
          input: event,
        }),
      );
      console.log('CREATE EVENT TEST', result);
      this.getUserProfile();
    },

    async changePassword(formName) {
      const form = this.$refs[formName];
      const isFormValid = await form.validate();
      const arePasswordMatch = this.passwordChangeForm.confirmPassword
        === this.passwordChangeForm.newPassword;
      if (!isFormValid || !arePasswordMatch) {
        this.error = 'Form is invalid.';
        return;
      }

      try {
        this.isBtnLoading = true;
        await this.$Amplify.Auth.changePassword(
          this.user,
          this.passwordChangeForm.oldPassword,
          this.passwordChangeForm.newPassword,
        );
        this.isBtnLoading = false;
        this.$message('Password has been changed.');
        this.passwordChangeForm = {
          oldPassword: '',
          newPassword: '',
          confirmPassword: '',
        };
      } catch (error) {
        this.error = error.message;
        this.isBtnLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
$bg: #1b242e;
  #user-profile {
    padding: 100px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    margin: 20px;
    padding-top: 0;
    .panel {
      position:relative;
      background: $bg;
      padding: 30px;
      max-width: 100%;
    }
    h1 {
      color:#fff;
    }
  }
</style>
