import { API, graphqlOperation } from 'aws-amplify';
import Vue from 'vue';
import _ from 'lodash';
import * as qlqueries from '../graphql/queries';
import * as qlcustomMutations from '../graphql/customMutations';
import * as qlmutations from '../graphql/mutations';
// import * as qlsubscriptions from '../graphql/subscriptions';

const state = {
  name: 'Admin',
  listClients: {},
  clients: [],
  listAppSettings: {},
  listFeatures: {},
  listAccounts: {},
  listClaims: {},
  listUsers: {},
  features: {},
  allClaims: {},
  claims: {},
  appSettings: {},
  users: {},
  accounts: {},

  client: {},
  clientAccounts: [],
  claimProfiles: [],
  account: {},

  clientId: null,
  accountId: null,
  loading: false,
};

const getters = {
};

const mutations = {
  setClient: (state, payload) => {
    Vue.set(state, 'client', payload);
    Vue.set(state, 'clientAccounts', payload.accounts.items);

    if (state.accountId) {
      const account = state.client.accounts.items.filter((item) => {
        const isActive = item.id === state.accountId;
        return isActive ? item : null;
      })[0];

      Vue.set(state, 'account', account);
      return;
    }

    const [mainAccount] = payload.accounts.items;
    if (mainAccount) Vue.set(state, 'account', mainAccount);
  },

  setAccount: (state, payload) => {
    Vue.set(state, 'account', payload);
  },

  setAccountId: (state, payload) => {
    Vue.set(state, 'accountId', payload);
  },

  clearAccount: (state) => {
    Vue.set(state, 'account', {
      name: '',
    });
  },

  setAccountList: (state, payload) => {
    Vue.set(state, 'listAccounts', payload);

    const accounts = {};
    payload.items.forEach((item) => {
      accounts[item.id] = item;
    });

    Vue.set(state, 'accounts', accounts);
  },

  setClientList: (state, payload) => {
    Vue.set(state, 'listClients', payload);

    const clients = {};
    payload.items.forEach((item) => {
      clients[item.id] = item;
    });

    Vue.set(state, 'clients', clients);
  },

  setUserList: (state, payload) => {
    Vue.set(state, 'listUsers', payload.items);

    const users = {};
    payload.items.forEach((item) => {
      users[item.id] = item;
    });

    Vue.set(state, 'users', users);
  },

  setCurrentClientId: (state, payload) => {
    Vue.set(state, 'clientId', payload);
  },

  setAppSettings: (state, payload) => {
    Vue.set(state, 'listAppSettings', payload);
  },

  setFeatures: (state, payload) => {
    Vue.set(state, 'listFeatures', payload);

    const features = {};
    const allClaims = {};
    payload.items.forEach((item) => {
      const claims = {};
      item.claims.items.forEach((claim) => {
        claims[claim.id] = claim;
        claims[claim.id].defaultValue = false; // used to detect changes
        claims[claim.id].value = false;
        allClaims[`${item.id}.${claim.id}`] = {
          defaultValue: false,
          value: false,
          featureId: item.id,
          claimId: claim.id,
        };
      });
      features[item.id] = {
        feature: item,
        claims,
      };
    });
    Vue.set(state, 'allClaims', allClaims);
    Vue.set(state, 'features', features);
  },

  setClaims: (state, payload) => {
    Vue.set(state, 'listClaims', payload);

    const claims = {};
    payload.items.forEach((item) => {
      claims[item.id] = item;
    });

    Vue.set(state, 'claims', claims);
  },
  setClaimProfiles: (state, payload) => {
    Vue.set(state, 'claimProfiles', payload);
  },

  setLoading: (state, payload) => {
    Vue.set(state, 'loading', payload);
  },

  appendClient: (state, payload) => {
    state.clients[payload.id] = payload;
  },

  addUser: (state, payload) => {
    state.users[payload.id] = payload;
  },

  addClient: (state, payload) => {
    state.clients[payload.id] = payload;
  },
  updateClient: (state, payload) => {
    state.clients[payload.id] = payload;
  },
  updateAccount: (state, payload) => {
    state.accounts[payload.id] = payload;
    const isForCurrentClient = payload.client.code === state.client.code;
    const isCurrentAccount = payload.id === state.account.id;
    if (isCurrentAccount) {
      state.account = payload;
    }

    if (isForCurrentClient) {
      state.clientAccounts = state.clientAccounts.map((account) => {
        const hasChanged = account.id === payload.id;
        if (hasChanged) return payload;
        return account;
      });
    }
  },
  addAccount: (state, payload) => {
    state.accounts[payload.id] = payload;
    const isForCurrentClient = payload.client.code === state.client.code;

    if (isForCurrentClient) state.clientAccounts.push(payload);
  },

  addUserToCurrentClient: (state, payload) => {
    state.client.users.items.push(payload);
  },

  addClaim: (state, payload) => {
    state.claims[payload.id] = payload;
  },

  addFeature: (state, payload) => {
    state.features[payload.id] = payload;
  },

  updateUser: (state, payload) => {
    state.users[payload.id] = payload;
  },

  updateClaim: (state, payload) => {
    state.claims[payload.id] = payload;
  },

  updateFeature: (state, payload) => {
    state.features[payload.id] = payload;
  },

  deleteClient: (state, id) => {
    Vue.delete(state.clients, id);
  },

  deleteAccount: (state, id) => {
    Vue.delete(state.accounts, id);

    state.clientAccounts = state.clientAccounts.filter((account) => account.id !== id);
  },

  deleteUser: (state, id) => {
    Vue.delete(state.users, id);
  },

  deleteClaim: (state, id) => {
    Vue.delete(state.claims, id);
  },

  deleteFeature: (state, id) => {
    Vue.delete(state.features, id);
  },

  addFeatureToClient: (state, map) => {
    state.client.features.items.push(map);
  },

  removeFeatureFromClient: (state, map) => {
    // Vue.push(state.client, 'features', map);
    state.client.features.items = state.client.features.items.filter((feature) => {
      const isDeleted = feature.id === map.id;
      return !isDeleted;
    });
  },

  addFeatureToAccount: (state, map) => {
    // Vue.push(state.client, 'features', map);
    state.account.features.items.push(map);
  },
  removeFeatureFromAccount: (state, map) => {
    // Vue.push(state.client, 'features', map);
    state.account.features.items = state.account.features.items.filter((feature) => {
      const isDeleted = feature.id === map.id;
      return !isDeleted;
    });
  },

  // USER CLAIMS RESOLVERS
  manageUserClaims: (state, payload) => {
    const wasDeleted = !!payload.deleteUserClaimMap;
    const wasAdded = !!payload.createUserClaimMap;

    const resultData = wasDeleted ? payload.deleteUserClaimMap : payload.createUserClaimMap;

    state.client.users.items.forEach((user, index) => {
      const isRelevant = user.user.id === resultData.user.id;
      if (!isRelevant) return;

      const stateUser = state.client.users.items[index].user;
      if (wasDeleted) {
        stateUser.claims.items = stateUser.claims.items.filter((claim) => {
          const toBeRemoved = claim.id === resultData.id;
          return !toBeRemoved;
        });
      }

      if (wasAdded) stateUser.claims.items.push(resultData);
    });
  },

  manageUserAccountClaims: (state, payload) => {
    const wasDeleted = !!payload.deleteUserClaimMap;
    const wasAdded = !!payload.createUserClaimMap;

    const resultData = wasDeleted ? payload.deleteUserClaimMap : payload.createUserClaimMap;

    state.account.users.items.forEach((user, index) => {
      const isRelevant = user.user.id === resultData.user.id;
      if (!isRelevant) return;

      const stateUser = state.account.users.items[index].user;
      if (wasDeleted) {
        stateUser.claims.items = stateUser.claims.items.filter((claim) => {
          const toBeRemoved = claim.id === resultData.id;
          return !toBeRemoved;
        });
      }

      if (wasAdded) stateUser.claims.items.push(resultData);
    });
  },
};

const actions = {
  async fetchClientList({ commit }) {
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listClients, {
          limit: 1500,
        }),
      );
      const { data } = result;
      commit('setClientList', data.listClients);
      return data.listClients;
    } catch (error) {
      const hasData = !!error.data && !!error.data.listClients;
      if (hasData) {
        const { data } = error;
        commit('setClientList', data.listClients);
        console.debug('Oops something went wrong. Can\'t get some of the booking info', data.eventsByStartTime);
        const errorTypes = [...new Set(error.errors.map((el) => el.errorType))];
        const isUnauthorizedOnly = errorTypes.length === 1 && errorTypes.indexOf('Unauthorized') !== -1;
        if (!isUnauthorizedOnly) {
          // this._vm.$message.error(
          //   { message: 'Oops something went wrong.  },
          // );
        }
        return { error: true, message: error.errors };
      }
      this._vm.$message.error({ message: 'Oops, can\'t get client list.' });
      return { error: true, message: error.errors };
    }
  },

  async listClaimsProfiles({ commit }) {
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listClaimsProfiles, {
          limit: 1500,
        }),
      );
      const { data } = result;
      commit('setClaimProfiles', data.listClaimsProfiles);
      return data.listClaimsProfiles;
    } catch (error) {
      this._vm.$message.error({ message: 'Oops, can\'t get claim profiles.' });
      return { error: true, message: error.errors };
    }
  },

  async fetchClientDetails({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.getClient, {
          id: payload,
        }),
      );
      const { data } = result;
      commit('setClient', data.getClient);
      return data.getClient;
    } catch (error) {
      this._vm.$message.error({ message: 'Oops, can\'t get client details.' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async fetchAccountDetails({ commit }, payload) {
    commit('setLoading', true);
    try {
      commit('setAccount', { name: 'loading...' });
      const result = await API.graphql(
        graphqlOperation(qlqueries.getAccount, {
          id: payload,
        }),
      );
      const { data } = result;
      commit('setAccount', data.getAccount);
      return data.getAccount;
    } catch (error) {
      this._vm.$message.error({ message: 'Oops, can\'t get account details.' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async fetchUserList({ commit }) {
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listUsers, {
          limit: 1500,
        }),
      );
      const { data } = result;
      commit('setUserList', data.listUsers);
      return data.listUsers;
    } catch (error) {
      const hasData = !!error.data && !!error.data.listUsers;
      if (hasData) {
        const { data } = error;
        data.listUsers.items.filter((el) => el != null);

        const d = data.listUsers;
        d.items = data.listUsers.items.filter((el) => el !== null);

        commit('setUserList', d);
        const errorTypes = [...new Set(error.errors.map((el) => el.errorType))];
        const isUnauthorizedOnly = errorTypes.length === 1 && errorTypes.indexOf('Unauthorized') !== -1;
        console.log('listUsers', d);
        if (!isUnauthorizedOnly) {
          this._vm.$message.error(
            { message: 'Oops something went wrong. Can\'t get some of the users' },
          );
        }
        return { error: true, message: error.errors };
      }
      this._vm.$message.error({ message: 'Oops, can\'t get user list.' });
      return { error: true, message: error.errors };
    }
  },

  async fetchAccountList({ commit }) {
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listAccounts, {
          limit: 1500,
        }),
      );
      const { data } = result;
      commit('setAccountList', data.listAccounts);

      return data.listAccounts.items;
    } catch (error) {
      this._vm.$message.error({ message: 'Oops, can\'t get account list.' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async fetchAppSettings({ commit }) {
    const result = await API.graphql(
      graphqlOperation(qlqueries.listAppSettings, {
        limit: 1500,
      }),
    );
    const { data } = result;
    const isFailure = !!result.errors;
    if (isFailure) return { error: true, message: result.errors };
    commit('setAppSettings', data.listAppSettings);

    return data.listAppSettings;
  },

  async fetchFeatureList({ commit }) {
    const result = await API.graphql(
      graphqlOperation(qlqueries.listFeatures, {
        limit: 1500,
      }),
    );
    const { data } = result;
    const isFailure = !!result.errors;
    if (isFailure) return { error: true, message: result.errors };
    commit('setFeatures', data.listFeatures);

    return data.listFeatures;
  },

  async fetchClaimList({ commit }) {
    const result = await API.graphql(
      graphqlOperation(qlqueries.listClaims, {
        limit: 1500,
      }),
    );
    const { data } = result;
    const isFailure = !!result.errors;
    if (isFailure) return { error: true, message: result.errors };
    commit('setClaims', data.listClaims);

    return data.listClaims;
  },

  // CLIENTS
  async createClient({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createClient,
          { input: payload },
        ),
      );

      const newAccount = {
        ...payload,
        accountClientId: result.data.createClient.id,
        accountLastUpdatedById: payload.clientLastUpdatedById,
      };
      delete newAccount.clientLastUpdatedById;
      const resultAccount = await API.graphql(
        graphqlOperation(
          qlmutations.createAccount,
          { input: newAccount },
        ),
      );

      const { data } = result;
      commit('addAccount', resultAccount.data.createAccount);
      commit('addClient', data.createClient);

      this._vm.$message({ message: 'Client added' });
      return data.createClient;
    } catch (error) {
      commit('setLoading', false);
      this._vm.$message.error({ message: 'Oops, can\'t add client.' });
      return { error: true, message: error.errors[0] };
    }
  },

  async updateClient({ commit }, payload) {
    try {
      await API.graphql(
        graphqlOperation(
          qlmutations.updateClient,
          { input: payload },
        ),
      );

      const resultClient = await API.graphql(
        graphqlOperation(qlqueries.getClient, {
          id: payload.id,
        }),
      );
      const { data } = resultClient;
      commit('setClient', data.getClient);
      commit('updateClient', data.getClient);

      this._vm.$message({ message: 'Client updated' });
      return data.getClient;
    } catch (error) {
      this._vm.$message.error({ message: 'Can\'t update client' });
      return { error: true, message: error.errors };
    }
  },

  async deleteClient({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlcustomMutations.deleteClient,
          {
            input: {
              id: payload.id,
              expectedVersion: payload.version,
            },
          },
        ),
      );
      const { data } = result;
      commit('deleteClient', payload.id);
      this._vm.$message({ message: 'Client deleted' });
      return data.deleteClient;
    } catch (error) {
      this._vm.$message.error({ message: 'Can\'t delete client' });
      return { error: true, message: error.errors };
    }
  },

  // ACCOUNTS
  async createAccount({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createAccount,
          { input: payload },
        ),
      );

      const { data } = result;
      commit('addAccount', data.createAccount);
      this._vm.$message({ message: 'Account added' });
      return data.createAccount;
    } catch (error) {
      commit('setLoading', false);
      this._vm.$message.error({ message: 'Can\'t create account' });
      return { error: true, message: error.errors[0] };
    }
  },

  async updateAccount({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.updateAccount,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('updateAccount', data.updateAccount);
      this._vm.$message({ message: 'Account updated' });
      return data.updateAccount;
    } catch (error) {
      this._vm.$message.error({ message: 'Can\'t update account' });
      return { error: true, message: error.errors };
    }
  },

  async deleteAccount({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteAccount,
          {
            input: {
              id: payload.id,
              expectedVersion: payload.version,
            },
          },
        ),
      );
      const { data } = result;
      commit('deleteAccount', payload.id);
      this._vm.$message({ message: 'Account deleted' });
      return data.deleteAccount;
    } catch (error) {
      this._vm.$message.error({ message: 'Can\'t delete account' });
      return { error: true, message: error.errors };
    }
  },

  // CLAIMS
  async createClaim({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createClaim,
          { input: payload },
        ),
      );

      const { data } = result;
      commit('addClaim', data.createClaim);
      this._vm.$message({ message: 'Claim added' });
      return data.createClaim;
    } catch (error) {
      commit('setLoading', false);
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors[0] };
    }
  },

  async deleteClaim({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteClaim,
          { input: { id: payload } },
        ),
      );
      const { data } = result;
      commit('deleteClaim', payload);
      this._vm.$message({ message: 'Claim deleted' });
      return data.deleteClaim;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    }
  },

  async updateClaim({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.updateClaim,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('updateClaim', data.updateClaim);
      this._vm.$message({ message: 'Claim updated' });
      return data.updateClaim;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    }
  },

  // FEATURES
  async createFeature({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createFeature,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('addFeature', data.createFeature);
      return data.createFeature;
    } catch (error) {
      commit('setLoading', false);
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors[0] };
    }
  },

  async deleteFeature({ commit }, payload) {
    const result = await API.graphql(
      graphqlOperation(
        qlmutations.deleteFeature,
        { input: { id: payload } },
      ),
    );
    const isFailure = !!result.errors;
    commit('setLoading', false);
    if (isFailure) return { error: true, message: result.errors };
    const { data } = result;
    commit('deleteFeature', payload);
    return data.deleteFeature;
  },

  async updateFeature({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.updateFeature,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('updateFeature', data.updateFeature);
      return data.updateFeature;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    }
  },

  // USERS
  async createUser({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createUser,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('addUser', data.createUser);
      return data.createUser;
    } catch (error) {
      commit('setLoading', false);
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors[0] };
    }
  },

  async deleteUser({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteUser,
          { input: { id: payload, expectedVersion: 1 } }, // why is expectedVersion here?
        ),
      );
      const { data } = result;
      commit('deleteUser', payload);
      this._vm.$message({ message: 'User deleted' });
      return data.deleteUser;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    }
  },

  async updateUser({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.updateUser,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('updateUser', data.updateUser);
      this._vm.$message({ message: 'User updated' });
      return data.updateUser;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    }
  },

  // -----------------------------------------
  // MAP THINGS TO THINGS
  // -----------------------------------------

  async mapFeatureToClient({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createClientFeatureMap,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('addFeatureToClient', data.createClientFeatureMap);
      return data.createClientFeatureMap;
    } catch (error) {
      return { error: true, message: error.errors };
    }
  },
  async deleteClientFeatureMap({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteClientFeatureMap,
          { input: { id: payload } },
        ),
      );
      const { data } = result;
      commit('removeFeatureFromClient', data.deleteClientFeatureMap);
      return data.deleteClientFeatureMap;
    } catch (error) {
      return { error: true, message: error.errors };
    }
  },

  async mapFeatureToAccount({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createAccountFeatureMap,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('addFeatureToAccount', data.createAccountFeatureMap);

      this._vm.$message({ message: 'Feature added' });
      return data.createAccountFeatureMap;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    }
  },
  async deleteAccountFeatureMap({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteAccountFeatureMap,
          { input: { id: payload } },
        ),
      );
      const { data } = result;
      commit('removeFeatureFromAccount', data.deleteAccountFeatureMap);
      this._vm.$message({ message: 'Feature removed' });
      return data.deleteAccountFeatureMap;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    }
  },

  // MAP CLAIMS TO USER
  async createUserClaimMap({ commit }, payload) {
    try {
      const results = [];
      await payload.forEach(async (action) => {
        const mutation = action.toRemove ? 'deleteUserClaimMap' : 'createUserClaimMap';
        const result = await API.graphql(
          graphqlOperation(
            qlmutations[mutation],
            { input: action.input },
          ),
        );

        results.push(result.data);
        commit('manageUserClaims', result.data);
      });
      this._vm.$message({ message: 'User permissions updated' });
      // const { data } = result;
      return results;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went frong. Can\'t save user permissions' },
      );
      return { error: true, message: error.errors };
    }
  },

  async createUserAccountClaimMap({ commit }, payload) {
    try {
      const results = [];
      await payload.forEach(async (action) => {
        const mutation = action.toRemove ? 'deleteUserClaimMap' : 'createUserClaimMap';
        const result = await API.graphql(
          graphqlOperation(
            qlmutations[mutation],
            { input: action.input },
          ),
        );

        results.push(result.data);
        commit('manageUserAccountClaims', result.data);
      });
      this._vm.$message({ message: 'User permissions updated' });
      // const { data } = result;
      return results;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went frong. Can\'t save user permissions' },
      );
      return { error: true, message: error.errors };
    }
  },

  async createUserClientMap({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createUserClientMap,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('addUserToCurrentClient', data.createUserClientMap);
      this._vm.$message({ message: 'User assigned' });
      return data.createUserClientMap;
    } catch (error) {
      this._vm.$message.error({ message: 'Can\'t assign user' });
      return { error: true, message: error.errors };
    }
  },

  async createUserAccountMap({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createUserAccountMap,
          { input: payload },
        ),
      );
      const { data } = result;
      this._vm.$message({ message: 'User assigned' });
      return data.createUserAccountMap;
    } catch (error) {
      this._vm.$message.error({ message: 'Can\'t assign user' });
      return { error: true, message: error.errors };
    }
  },

  async createAccountClaimMap({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createAccountClaimMap,
          { input: payload },
        ),
      );
      const { data } = result;
      this._vm.$message({ message: 'User assigned' });
      return data.createAccountClaimMap;
    } catch (error) {
      this._vm.$message.error({ message: 'Can\'t assign user' });
      return { error: true, message: error.errors };
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
