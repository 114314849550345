import { render, staticRenderFns } from "./LinkButton.vue?vue&type=template&id=1d4db521&scoped=true&"
import script from "./LinkButton.vue?vue&type=script&lang=js&"
export * from "./LinkButton.vue?vue&type=script&lang=js&"
import style0 from "./LinkButton.scss?vue&type=style&index=0&id=1d4db521&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d4db521",
  null
  
)

export default component.exports