<template>
  <div id="app">
    <transition name="el-fade-in-linear">
      <router-view />
    </transition>
  </div>
</template>
<script>
export default {
  created() {
  },

  methods: {
  },
};
</script>
<style lang="scss">
$bly-font-path: './assets/icons/fonts';
@import url("https://fonts.googleapis.com/css?family=Rubik:300,400,500");
@import "./assets/icons/css/style.css";
@import "./assets/icons/update/css/style.css";
@import "./assets/var.scss";

$__color-primary: #ff9f31;
$__color-warning: #FF5722;
$__color-info: #409eff;
$__font-path: '~element-ui/packages/theme-chalk/src/fonts';
$--input-placeholder-color: $grey-light;
$--input-border-color: $grey-light;
$--border-color-base: $grey-light;

$transition: .2s cubic-bezier(.645,.045,.355,1);
$--avatar-background-color: $icon;
$bg: #1b242e;

.primary {
  color: $color-primary;
}
.warning {
  color: $color-warning;
}
.info {
  color: $blue;
}
.text-left {
  text-align:left;
}

@import "~vue-datetime/dist/vue-datetime.css";
@import "~vant/lib/index.css";

@import "~element-ui/packages/theme-chalk/src/reset.scss";
@import "~element-ui/packages/theme-chalk/src/message.scss";
@import "~element-ui/packages/theme-chalk/src/drawer.scss";
@import "~element-ui/packages/theme-chalk/src/avatar.scss";
@import "~element-ui/packages/theme-chalk/src/notification.scss";
@import "~element-ui/packages/theme-chalk/src/message-box.scss";
@import "~element-ui/packages/theme-chalk/src/tooltip.scss";
@import "~element-ui/packages/theme-chalk/src/loading.scss";
@import "~element-ui/packages/theme-chalk/src/select.scss";
@import "~element-ui/packages/theme-chalk/src/popover.scss";
@import "~element-ui/packages/theme-chalk/src/button.scss";
@import "~element-ui/packages/theme-chalk/src/option.scss";
@import "~element-ui/packages/theme-chalk/src/cascader.scss";
@import "~element-ui/packages/theme-chalk/src/form.scss";
@import "~element-ui/packages/theme-chalk/src/alert.scss";
@import "~element-ui/packages/theme-chalk/src/icon.scss";
@import "~element-ui/packages/theme-chalk/src/dropdown.scss";
@import "~element-ui/packages/theme-chalk/src/switch.scss";
@import "~element-ui/packages/theme-chalk/src/input-number.scss";
@import "~element-ui/packages/theme-chalk/src/date-picker/picker.scss";
@import "~element-ui/packages/theme-chalk/src/date-picker/time-picker.scss";
@import "~element-ui/packages/theme-chalk/src/date-picker/time-range-picker.scss";
@import "~element-ui/packages/theme-chalk/src/date-picker/year-table.scss";
@import "~element-ui/packages/theme-chalk/src/date-picker/month-table.scss";
@import "~element-ui/packages/theme-chalk/src/date-picker/date-table.scss";

@import "~element-ui/packages/theme-chalk/src/date-picker.scss";
@import "~element-ui/packages/theme-chalk/src/date-picker/picker-panel.scss";
@import "~element-ui/packages/theme-chalk/src/checkbox.scss";
@import "~element-ui/packages/theme-chalk/src/radio-button.scss";
@import "~element-ui/packages/theme-chalk/src/radio.scss";
@import "~element-ui/packages/theme-chalk/src/dialog.scss";
@import "~element-ui/packages/theme-chalk/src/upload.scss";
@import "~element-ui/packages/theme-chalk/src/col.scss";
@import "~element-ui/packages/theme-chalk/src/row.scss";
@import "~element-ui/packages/theme-chalk/src/collapse.scss";
@import "~element-ui/packages/theme-chalk/src/divider.scss";
@import "~element-ui/packages/theme-chalk/src/card.scss";
@import "~element-ui/packages/theme-chalk/src/autocomplete.scss";
@import "~element-ui/packages/theme-chalk/src/common/transition.scss";

.clear {
  float: none;
}
.el {
  &-dialog__title {
    font-weight: bold;
  }
  &-radio {
    margin-right: 16px;
  }
  &-checkbox {
    font-weight: 400;
  }
  &-tooltip__popper.is-dark {
    background: #000000;
  }
  &-button {
    &--small {
      border-radius: 2px;
    }
    &--grey {
      background: #73809c;
      border-color: #73809c;
      color:#fff;
      &:hover {
        background: #babdc7;
        border-color: #babdc7;
        color: #fff;
      }
   }
  }
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  font-family: "Nunito", Helvetica, Arial, sans-serif;
  .theme-mini {
    font-weight: 400;
    font-family: "Rubik", Helvetica, Arial, sans-serif !important;
    .el-dialog__title {
      font-weight: 500;
    }
    .el-checkbox {
      font-weight: 400;
    }
    .el-button, .el-tag, div#event-preview .label, div#event-preview .content {
      font-weight: 400;
      border-radius: 4px;
    }
    strong {
      font-weight: 500;
    }
  }
}
* {
  box-sizing: border-box;
}
.bly-admin-tile-icon:before {
  content: "\EA53";
}
.bly-permissionssimple-tile-icon:before {
  content: "\EA53";
}
.link-color {
  @extend %link-item;
  color: $blue-light;
}
.link {
  @extend %link-item;
  @extend %no-select;
  &:hover {
    text-decoration: underline;
  }
}

.config {
  .el-checkbox {
    &__inner {
      border: 1px solid #607D8B;
      width: 16px;
      height: 16px;
      background-color: #13191d;
    }
    &__label {
      color: $color;
    }
  }
  .el-checkbox.is-bordered {
    border-color: transparent;
  }
}

.el-dropdown-menu {
  .dd-account-name {
    margin-bottom: -20px;
    font-weight: 600;
  }
}
.title-card {
  display: grid;
  padding: 20px 0px 20px 20px;
  background: $bg;
  border-radius: 3px;
  margin-bottom: 16px;
  &-top {
    display: grid;
    grid-template-columns: 40px 1fr 1fr;
    min-height: 40px;
    padding: 0;
    align-items: center;
    grid-gap: 16px;
    &-name {
      font-size: 18px;
      color: $color;
      .bly-small-up {
        top: 3px;
        position: relative;
        font-size: 16px;
        opacity: .3;
        margin: 0 6px;
      }
      &-description {
        color:$grey;
        font-size: 14px;
      }
      &-additional {
        color:$grey;
        font-size: 14px;
        .bi {
          position: relative;
          top: 2px;
        }
      }
    }
    &-actions {
      text-align: right;
    }
  }
}
.sidebar {
    &-group {
      color: $icon;
      font-size: 14px;
      display: grid;
      grid-template-columns: 1fr auto;
      line-height: 42px;
      margin-top: 24px;
      text-transform: uppercase;
      font-weight: 600;
      &:first-child {
        margin-top: 0;
      }
      &-actions {
        display: grid;
        justify-content: end;
        padding-top: 4px;
      }
    }

    &-item {
      color: $color;
      font-size: 16px;
      display: grid;
      grid-template-columns: 1fr auto;
      line-height: 42px;
      margin-left: -12px;
      padding-left: 12px;
      @extend %no-select;
      @extend %link-item;
      .code {
        text-transform: uppercase;
        color: white;
        position: relative;
        top: -1px;
        font-size: 12px;
        font-weight: bold;
        background: $icon;
        border-radius: 3px;
        padding: 0 8px;
      }
      &-actions {
        opacity: 0;
      }
      &:hover {
        color: $color-primary;
        border-left: 3px solid $default;
        .sidebar-item-actions {
          opacity: 1;
        }
      }
      &.active {
        color: $color-primary;
      }
      &-actions {
        display: grid;
        justify-content: end;
        padding-top: 4px;
      }
    }
  }
.content-wrapper {
  .data-header {
    display: grid;
    grid-template-columns: 1fr auto;
    margin-bottom: 16px;
    &-tabs {
      color: $color;
      &-tab {
        padding-right: 16px;
        padding-left: 16px;
        display:inline-block;
        line-height: 32px;
        text-align: center;
        @extend %link-item;
        &:hover, &.active {
          border-bottom: 2px solid $color-primary;
          color: $color-primary !important;
        }
      }
    }
    &-actions {
      display: grid;
      justify-content: end;
    }
  }

  .data-list-item {
    background: $item-bg;
    margin-bottom: 8px;
    line-height: 40px;
    display: grid;
    grid-template-columns: 1fr auto;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    &-name {
      color: $color;
      padding-left: 16px;
      i {
        position: relative;
        top: 2px;
        margin-right: 8px;
      }
      &-link {
        &:hover {
          color:$link;
          @extend %link-item;
        }
      }
    }
    &-actions {
      text-align: right;
      span.actions-separator {
        display: inline-block;
        width: 25px;
      }
      .icon-btn {
        display: inline-block;
        text-align: center;
        cursor: pointer;
      }
      .bly-play {
        color: $green;
      }
      .bly-stop {
        color: $red;
      }
    }
  }
}

.el-tooltip__popper {
  pointer-events: none;
}
.bi-rotate-45 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  transform: rotate(-45deg);
}

.dropdown-item {
  @extend %no-select;
  @extend %link-item;
  line-height: 35px;
  color: $dark;
  font-size: 13px;
  padding: 0 16px;
  margin: 5px -8px;
  font-weight: normal;
  font-weight: 600;

  i {
    color: $icon;
    margin-right: 16px;
    font-size: 16px;
    vertical-align: sub;
    &.bly-right-arrow {
      float: right;
    }
  }

  span {
    float: right;
  }
  &:hover {
    background: lighten($color, 10%);
  }
  &.active {
    color: $__color-primary;
  }

  &.separator {
    border-bottom: 1px solid lighten($color, 5%);
    margin: 5px 0;
  }

  &.group {
    color: lighten($dark, 55%);
    padding: 8px;
    &:hover {
      background: none;
    }
  }
}

#app {
  font-family: "Nunito", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #66718d;
  background: #13191d;
  min-height: 100vh;
  padding: 0px 20px;

  #nav {
    background: #13191d;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    line-height: 45px;
    padding: 0px 0px 0px 20px;
    height: 50px;
    #logo {
      float: left;
      img {
        margin-top: 10px;
        margin-bottom: -5px;
      }
    }
    #right-nav {
      float: right;
    }
  }
}
#nav {
  padding: 10px 30px;
  z-index: 9;
  a {
    font-weight: bold;
    text-transform: uppercase;
    line-height: 50px;
    display: inline-block;
    padding: 0 20px;
    text-decoration: none;
    color: #66718d;
    &.router-link-exact-active {
      color: #ffa233;
    }
  }
}

.noselect {
  user-select: none;
}

#auth {
  padding-top: 15vh;
  .form {
    width: 90vw;
    max-width: 380px;
    margin: 0 auto;
    background: $bg;
    padding: 30px;
  }
}
.form, .el-form {
  h1 {
    font-size: 24px;
    margin:0;
    color:#fff;
  }
  .title-description {
    margin:0px;
    color: #73809c;
    padding-bottom: 15px;
  }

  .el-form-item__label {
    padding: 0;
    line-height: 26px;
    color: #73809c;
  }
  .el-form-item__content {
    line-height: 18px;
  }
  .el-alert--error {
    background-color: transparent;
    color: #F44336;
    border: 1px solid #F44336;
  }

  &.form-dark {
    h1 {
      color:#000;
    }
    .title-description {
      color: $dark;
    }

    .el-form-item__label {
      color: $dark;
    }
  }
}
.slide-leave-active,
.slide-enter-active {
  transition: 0.2s ease-in-out;
}
.slide-enter {
  // transform: translate(40%, 0);
  opacity: 0;
}
.slide-leave-active {
  opacity: 0;
}
.slide-leave-to {
  // transform: translate(-40%, 0);
}
.transition-wrapper {
  overflow-x: hidden;
}
.el {
  &-dialog {
    margin-top: 50px !important;
    &__body {
      padding-top:16px;
    }
  }
  &-date-editor {
    width: auto !important;
  }
  &-form-item {
    margin-bottom: 16px;
  }
  &-dropdown-menu__item {
    padding: 10px 16px;
    min-width: 170px;
    line-height: 16px;
    &:before {
      margin: 0 -10px;
    }
    .bi {
      margin-right: 16px;
      position: relative;
      top: 2px;
    }

    .dropdown-user {
      display: inline-block;
      position: relative;
      top: -8px;
      .bi {
        margin-right: 16px;
        position: relative;
        top: 11px;
        vertical-align: top;
      }
      small {
        display: block;
        margin-top: -36px;
        position: relative;
        top: 16px;
      }
    }
  }
}

.audio-channel {
  display:grid;
  grid-template-columns: 114px 1fr 70px 100px 50px;
  line-height: 32px;
  margin-bottom: 8px;
  border: 1px solid #ddd;
  padding-left: 8px;
  border-radius: 4px;
  &-th {
    border-color: transparent;
    border: 0px solid #fff;
    line-height: 16px;
  }
  &-name-left {
    color: #000;
  }
  &-actions {
    .bly-button {
      color: $dark;
      &:hover {
        color: #000 !important;
        path {
          fill: transparent !important;
        }
      }
    }
  }
}

.mobile {
  .data-header {
    &-tabs {
      color: $color;
      &-tab {
        padding-right: 8px;
        padding-left: 8px;
      }
    }
  }
  #nav {
    #logo {
      width: 50px;
      overflow: hidden;
    }
  }
  .mv-config.config {
    left: 80px;
  }
}

.bly-Router::before {
  content: "\ea4c";
}
.bly-Scheduler::before {
  content: "\ea50";
}
.bly-Admin::before {
  content: "\ea2a";
}
.bly-Multiviewer::before {
  content: "\ea39";
}
.bly-Servers::before {
  content: "\ea52";
}
.bly-Probes::before {
  content: "\ea44";
}
.bly-Permissions::before {
  content: "\ea44";
}
.el-divider__text.is-left {
  left: 0;
  padding-left: 0px;
}
.el-divider__text {
  color: #babdc7;
}
.el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 24px 0 13px 0;
}
.el-divider {
  background-color: rgba(179, 189, 214, 0.48);
  position: relative;
}
.dd-popup-wrapper {
  .vb-content {
    max-height: calc(100vh - 120px);
  }
}
.h-300 {
  height: 300px;
}

</style>
